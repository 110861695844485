import React, { useState, useEffect } from 'react';
import styles from './NewOrderModal.module.scss';
import { useHistory } from 'react-router-dom';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Select, Typography, Row, Col, Alert, Input } from 'antd';
const { Option } = Select;
const { Text, Title } = Typography;

const NewOrderModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listUser, setListUser] = useState([]);
	const [listCustomer, setListCustomer] = useState([]);
	const [customer, setCustomer] = useState();
	const [noContactData, setNoContactData] = useState(false);
	const [blocked, setBlocked] = useState(false);
	const [newEmail, setNewEmail] = useState('');
	const [newPhone, setNewPhone] = useState('');

	const [form] = Form.useForm();
	const history = useHistory();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		if (customer && props.store.auth.user.userRole.key === 'sales') {
			if (customer.email === '' || customer.phone === '') {
				setNoContactData(true);
				setBlocked(true);
			} else {
				setNoContactData(false);
				setBlocked(false);
			}
		}
	}, [customer, props.store.auth.user]);

	useEffect(() => {
		getUserList();
		getCustomerList();
	}, []);

	useEffect(() => {
		if (newEmail !== '' && newPhone !== '') {
			if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newEmail) && newPhone.length >= 8) {
				setBlocked(false);
			} else {
				setBlocked(true);
			}
		}
	}, [newEmail, newPhone]);

	const getUserList = () => {
		server
			.get('/list/user/include')
			.then((response) => {
				let filteredUser = response.data.filter((item) => item.userRole.key === 'sales' || (item.userRole.key !== 'root' && item.userRole.accessLevel <= 29));
				setListUser(filteredUser);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getCustomerList = () => {
		server
			.get('/list/customer')
			.then((response) => {
				let filteredCustomer;
				if (props.store.auth.user.userRole.accessLevel <= 29) {
					filteredCustomer = response.data.filter((item) => item.status == 1);
				} else {
					filteredCustomer = response.data.filter((item) => item.userId === props.store.auth.user.id && item.status == 1);
				}
				setListCustomer(filteredCustomer);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const handleCustomerSelect = (id) => {
		const currentCustomer = listCustomer.find((customer) => customer.id === id);
		setCustomer(currentCustomer);
		setNewEmail(currentCustomer.email);
		setNewPhone(currentCustomer.phone);
	};

	const handleInputChange = (e) => {
		switch (e.target.id) {
			case 'newEmail':
				setNewEmail(e.target.value);
				break;
			case 'newPhone':
				setNewPhone(e.target.value);
				break;
		}
	};

	const onFinish = (values) => {
		setLoading(true);
		let paymentMethodId = listCustomer.find((customer) => customer.id === values.customerId).paymentMethodId;

		const postOrder = () => {
			server
				.post('/order', {
					...values,
					userId: values.userId ? parseInt(values.userId) : props.store.auth.user.id,
					ordType: paymentMethodId == 1 ? 2 : paymentMethodId == 2 ? 1 : null,
				})
				.then((response) => {
					form.resetFields();
					setLoading(false);
					props.toggle();
					history.push('/order/' + response.data.id);
					notification('success', 'Megrendelés sikeresen létrehozva.', response.data.ordNo);
				})
				.catch((error) => {
					errorHandler(error);
				});
		};

		if (noContactData) {
			server
				.patch('/customer/' + customer.id, {
					email: newEmail,
					phone: newPhone,
				})
				.then((response) => {
					postOrder();
				})
				.catch((error) => {
					errorHandler(error);
				});
		} else {
			postOrder();
		}
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewOrderModal}
			title='Új megrendelés létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okButtonProps={{ disabled: blocked }}
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				{props.store.auth.user.userRole.key === 'sales' ? null : (
					<Form.Item label='Értékesítő' name='userId'>
						<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
							{listUser.map((user) => {
								return (
									<Option key={user.id} value={user.id}>
										{user.fullName}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				)}

				<Form.Item label='Ügyfél' name='customerId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select
						showSearch
						optionFilterProp='children'
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						onSelect={handleCustomerSelect}>
						{listCustomer.map((customer) => {
							return (
								<Option key={customer.id} value={customer.id}>
									{customer.companyName}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Form>
			{customer && (
				<>
					<Row style={{ width: '100%' }}>
						<Col xs={24}>
							<Title level={5}>{`${customer.id} - ${customer.companyName}${customer.nickname && ` (${customer.nickname})`}`}</Title>
						</Col>
						<Col xs={24}>
							<Text>{`${customer.billingPostalCode} ${customer.billingCity}`}</Text>
						</Col>
						<Col xs={24}>
							<Text>{`${customer.billingAddress}`}</Text>
						</Col>
						<Col xs={24} style={{ marginTop: '16px' }}>
							<Text>{`Email: ${customer.email || '-'}`}</Text>
						</Col>
						<Col xs={24}>
							<Text>{`Telefon: ${customer.phone || '-'}`}</Text>
						</Col>
						<Col xs={24} style={{ marginTop: '16px' }}>
							<Text type='secondary'>{`Értékesítő: ${customer.user.fullName}`}</Text>
						</Col>
					</Row>
					{noContactData && (
						<>
							<Row style={{ width: '100%', marginTop: '30px' }}>
								<Col xs={24}>
									<Alert message='Hoppá! Kérlek add meg az ügyfél telefonszámát és email címét!' type='error' showIcon />
								</Col>
							</Row>
							<Row style={{ width: '100%', marginTop: '30px' }} gutter={[0, 16]}>
								<Col xs={24}>
									<Input placeholder='Email cím (formátum: valami@valami.hu' id='newEmail' value={newEmail} onChange={handleInputChange} />
								</Col>
								<Col xs={24}>
									<Input placeholder='Telefonszám (minimum 8 karakter)' id='newPhone' value={newPhone} onChange={handleInputChange} />
								</Col>
							</Row>
						</>
					)}
				</>
			)}
		</Modal>
	);
};

export default NewOrderModal;
