import React, { useState, useEffect } from 'react';
import styles from './OrderTotal.module.scss';
import server from '../../../utils/axios/api';
import notification from '../../../utils/notification/notification';
import errorHandler from '../../../utils/error-handler';

import { Statistic, Row, Col, Card, Slider, InputNumber, Typography, Button } from 'antd';
const { Text } = Typography;

const OrderTotal = (props) => {
	const [discountValue, setDiscountValue] = useState(1);
	const [loading, setLoading] = useState({
		save: false,
	});

	useEffect(() => {
		setDiscountValue(props.order.ordDiscount || 1);
	}, []);

	const onChange = (value) => {
		setDiscountValue(value);
	};

	const handleSetDiscount = () => {
		setLoading({ ...loading, save: true });
		let oldPrice = props.order.ordNetPrice;
		let newPrice = oldPrice * ((100 - discountValue) / 100);
		server
			.patch('/order/' + props.order.id, { ordDiscount: discountValue, ordNetPrice: newPrice })
			.then((response) => {
				setLoading({ ...loading, save: false });
				props.getOrder();
				notification('success', 'Kedvezmény érvényesítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleResetDiscount = () => {
		setLoading({ ...loading, save: true });
		let oldPrice = props.order.ordNetPrice;
		let newPrice = oldPrice / ((100 - discountValue) / 100);
		server
			.patch('/order/' + props.order.id, { ordDiscount: null, ordNetPrice: newPrice })
			.then((response) => {
				setLoading({ ...loading, save: false });
				props.getOrder();
				notification('success', 'Kedvezmény visszavonva');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	return (
		<>
			<Col className={styles.OrderTotal} xs={24}>
				<Row gutter={[16, 16]} justify='end'>
					<Col xs={12} lg={12}>
						{!props.order.ordIsWebshopOrder && (
							<Card style={{ height: '100%' }}>
								<Row>
									<Col span={24} className='mb-10'>
										<Text type='secondary'>Kedvezmény %</Text>
									</Col>
								</Row>
								<Row justify='space-between' gutter={[16, 16]}>
									<Col xs={24} lg={8}>
										<Slider
											min={1}
											// max={props.order.ordNetPrice > 50000 && props.order.ordNetPrice <= 150000 ? 5 : props.order.ordNetPrice > 150000 ? 10 : 0}
											max={10}
											onChange={onChange}
											value={typeof discountValue === 'number' ? discountValue : 0}
											// disabled={props.order.customer.priceListId !== null || props.order.ordNetPrice <= 50000 || props.order.ordDiscount > 0 ? true : false}
											disabled={props.order.customer.priceListId !== null || props.order.ordDiscount > 0 || props.order.orderStatus.key === 100 ? true : false}
										/>
									</Col>
									<Col xs={24} lg={3}>
										<InputNumber
											min={1}
											// max={props.order.ordNetPrice > 50000 && props.order.ordNetPrice <= 150000 ? 5 : props.order.ordNetPrice > 150000 ? 10 : 0}
											max={10}
											style={{ margin: '0 16px', width: '100%' }}
											value={discountValue}
											onChange={onChange}
											// disabled={props.order.customer.priceListId !== null || props.order.ordNetPrice <= 50000 || props.order.ordDiscount > 0 ? true : false}
											disabled={props.order.customer.priceListId !== null ? true : false}
										/>
									</Col>
									<Col xs={24} lg={12} style={{ textAlign: 'right' }}>
										<Button
											className='ml-16'
											disabled={props.order.customer.priceListId !== null || props.order.ordDiscount > 0 || props.order.orderStatus.key === 100 ? true : false}
											onClick={handleSetDiscount}
											loading={loading.save}>
											Érvényesítés
										</Button>
										<Button
											className='ml-16'
											disabled={props.order.ordDiscount === null || props.order.orderStatus.key === 100 ? true : false}
											onClick={handleResetDiscount}
											loading={loading.save}
											danger>
											Visszavonás
										</Button>
									</Col>
								</Row>
							</Card>
						)}
					</Col>
					<Col xs={12} lg={12}>
						<Card>
							<Row gutter={[50, 16]} justify='end'>
								<Col xs={24} lg={8} style={{ textAlign: 'right' }}>
									<Statistic suffix='Ft' title='Összesen nettó' value={props.order.ordNetPrice} formatter={(value) => (value ? parseFloat(value).toFixed(2) : 0)} />
								</Col>
								<Col xs={24} lg={8} style={{ textAlign: 'right' }}>
									<Statistic suffix='Ft' title='ÁFA 27%' value={props.order.ordNetPrice * 0.27} formatter={(value) => (value ? parseFloat(value).toFixed(2) : 0)} />
								</Col>
								<Col xs={24} lg={8} style={{ textAlign: 'right' }}>
									<Statistic
										valueStyle={props.order.ordDiscount === null ? { color: '#3f8600' } : { color: '#0000ff' }}
										suffix='Ft'
										title='Összesen bruttó'
										value={Math.round(props.order.ordNetPrice) + Math.round(props.order.ordNetPrice * 0.27)}
										formatter={(value) => value.toLocaleString('hu')}
									/>
									{props.order.ordDiscount > 0 ? (
										<Text type='secondary' style={{ fontSize: '12px', margin: 0 }}>
											Kedvezmény: {props.order.ordDiscount}%
										</Text>
									) : null}
								</Col>
							</Row>
						</Card>
					</Col>

					<Col xs={0} lg={12}>
						<Card>
							<Row gutter={[50, 16]} justify='end'>
								<Col xs={24} lg={8} style={{ textAlign: 'right' }}>
									<Statistic suffix='kg' title='Összes súly' value={props.order.ordSumWeight} formatter={(value) => (value ? value.toLocaleString('hu') : 0)} />
								</Col>
								<Col xs={24} lg={8} style={{ textAlign: 'right' }}>
									<Statistic
										suffix={
											<div>
												m<sup>3</sup>
											</div>
										}
										title='Összes térforat'
										value={props.order.ordSumVolume}
										formatter={(value) => (value ? value.toLocaleString('hu') : 0)}
									/>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Col>

			{/* 
			<Col className={styles.OrderTotal} xs={24}>
				<Card>
					<Row gutter={[50, 16]} justify='space-between'>
						<Col>
							<Row gutter={[50, 16]} justify='start'>
								<Col>
									<Statistic suffix='kg' title='Összes súly' value={props.order.ordSumWeight} formatter={(value) => value.toLocaleString('hu')} />
								</Col>
								<Col>
									<Statistic
										suffix={
											<div>
												m<sup>3</sup>
											</div>
										}
										title='Összes térforat'
										value={props.order.ordSumVolume}
										formatter={(value) => value.toLocaleString('hu')}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={10}>
							<Row>
								<Col span={24} className='mb-10'>
									<Text type='secondary'>Kedvezmény</Text>
								</Col>
							</Row>
							<Row>
								<Col span={6}>
									<Slider
										min={1}
										max={props.order.ordNetPrice > 50000 && props.order.ordNetPrice <= 150000 ? 5 : props.order.ordNetPrice > 150000 ? 10 : 0}
										onChange={onChange}
										value={typeof discountValue === 'number' ? discountValue : 0}
										disabled={props.order.customer.priceListId !== null || props.order.ordNetPrice <= 50000 ? true : false}
									/>
								</Col>
								<Col span={4}>
									<InputNumber
										min={0}
										max={props.order.ordNetPrice > 50000 && props.order.ordNetPrice <= 150000 ? 5 : props.order.ordNetPrice > 150000 ? 10 : 0}
										style={{ margin: '0 16px' }}
										value={discountValue}
										onChange={onChange}
										disabled={props.order.customer.priceListId !== null || props.order.ordNetPrice <= 50000 ? true : false}
									/>
								</Col>
								<Col>
									<Button
										className='ml-16'
										disabled={
											props.order.customer.priceListId !== null || props.order.ordNetPrice <= 50000 || props.order.ordDiscount > 0 ? true : false
										}
										onClick={handleSetDiscount}
										loading={loading.save}>
										Érvényesítés
									</Button>
									<Button
										className='ml-16'
										disabled={props.order.ordDiscount === null ? true : false}
										onClick={handleResetDiscount}
										loading={loading.save}
										danger>
										Visszavonás
									</Button>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row gutter={[50, 16]} justify='end'>
								<Col>
									<Statistic
										suffix='Ft'
										title='Összesen nettó'
										value={props.order.ordNetPrice}
										formatter={(value) => Math.round(value).toLocaleString('hu')}
									/>
								</Col>
								<Col>
									<Statistic
										suffix='Ft'
										title='ÁFA 27%'
										value={props.order.ordNetPrice * 0.27}
										formatter={(value) => Math.round(value).toLocaleString('hu')}
									/>
								</Col>
								<Col>
									<Statistic
										valueStyle={{ color: '#3f8600' }}
										suffix='Ft'
										title='Összesen bruttó'
										value={props.order.ordNetPrice * 1.27}
										formatter={(value) => Math.round(value).toLocaleString('hu')}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			</Col> */}
		</>
	);
};

export default OrderTotal;
