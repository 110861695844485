import React from 'react';
import styles from './Receipts.module.scss';

import { Col, Card, Table } from 'antd';

const Receipts = (props) => {
	const tableColumns = [
		{
			key: 'prdCode',
			title: 'Termékkód',
			dataIndex: 'prdCode',
			responsive: ['lg'],
		},
		{
			key: 'prdName',
			title: 'Név',
			dataIndex: 'prdName',
		},
		{
			title: 'Mennyiség',
			align: 'center',
			render: (record) => record.receipts.reduce((a, b) => a + parseFloat(b.receiptLine.prdQty), 0),
		},
		{
			title: 'Egység',
			align: 'center',
			render: (record) => record.prdQtyUnit,
		},
	];

	return (
		<>
			<Col className={styles.Receipts} xs={24}>
				<Card title='Beérkezett tételek'>
					<Table dataSource={props.receiptProducts} columns={tableColumns} pagination={{ defaultPageSize: 100 }} size='middle' />
				</Card>
			</Col>
		</>
	);
};

export default Receipts;
