import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './PurchaseOrders.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import Moment from 'react-moment';
import zeroFill from 'zero-fill';

import PageGuard from '../../components/PageGuard/PageGuard';

import NewPurchaseOrderModal from '../../components/Modal/NewPurchaseOrderModal/NewPurchaseOrderModal';
import SearchBar from '../../components/SearchBar/SearchBar';

import { PageHeader, Button, Row, Col, Card, Table, Typography } from 'antd';
const { Text } = Typography;

const pagePath = 'purchaseorders';
const defaultPageSize = 50;

const searchData = {
	searchFields: [
		{ key: 'id', name: 'Azonosító' },
		{ key: 'vendor.companyName', name: 'Beszállító' },
		{ key: 'prchordDate', name: 'Megrendelés dátuma (pl: 2020-04-21)' },
		{ key: 'prchordShipDate', name: 'Szállítás dátuma (pl: 2020-04-21)' },
		{ key: 'prchordNetPrice', name: 'Érték' },
		{ key: 'purchaseOrderStatusId', name: 'Státusz', type: 'dropdown', list: 'purchaseOrderStatus', value: 'id', text: 'name'  },
	],
	lists: ['purchaseOrderStatus'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const PurchaseOrders = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [purchaseOrders, setPurchaseOrders] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchData.searchValues },
	});
	const [loading, setLoading] = useState({
		search: false,
	});

	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getPurchaseOrders();
	}, [queryParams]);

	const getPurchaseOrders = () => {
		server
			.get(`/purchase-order?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${JSON.stringify(queryParams.search)}&fields=${JSON.stringify(searchData.searchFields)}`)
			.then((response) => {
				setPurchaseOrders(response.data.rows);
				setTotal(response.data.count);
				setLoading({ ...loading, data: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			purchaseOrders.map((purchaseOrder) => {
				if (list.find((item) => item.value == purchaseOrder[column1][column2])) {
					return null;
				} else {
					return list.push({ text: purchaseOrder[column1][column2], value: purchaseOrder[column1][column2] });
				}
			});
		} else {
			purchaseOrders.map((purchaseOrder) => {
				if (list.find((item) => item.value == purchaseOrder[column1])) {
					return null;
				} else {
					return list.push({ text: purchaseOrder[column1], value: purchaseOrder[column1] });
				}
			});
		}
		return list;
	};

	const handleClick = (id) => {
		window.open('/purchaseorder/' + id);
};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const tableColumns = [
		{
			title: 'Azonosító',
			render: (text, record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)} strong>
					#{zeroFill(6, record.id)}
				</Text>
			),
			sorter: (a, b) => {
				if (a.prchordNo < b.prchordNo) {
					return -1;
				}
				if (a.prchordNo > b.prchordNo) {
					return 1;
				}
			},
		},
		{
			title: 'Beszállító',
			render: (record) => record.vendor.companyName || '',
			sorter: (a, b) => {
				if (a.vendor.companyName < b.vendor.companyName) {
					return -1;
				}
				if (a.vendor.companyName > b.vendor.companyName) {
					return 1;
				}
			},
		},
		{
			title: 'Megrendelés dátuma',
			render: (record) =>
				(
					<Text type='secondary'>
						<Moment format='YYYY/MM/DD' local>
							{record.prchordDate}
						</Moment>
					</Text>
				) || '',
			sorter: (a, b) => {
				if (a.prchordDate < b.prchordDate) {
					return -1;
				}
				if (a.prchordDate > b.prchordDate) {
					return 1;
				}
			},
			responsive: ['lg'],
		},
		{
			title: 'Szállítási dátum',
			render: (record) =>
				(
					<Text type='secondary'>
						<Moment format='YYYY/MM/DD' local>
							{record.prchordShipDate}
						</Moment>
					</Text>
				) || '',
			sorter: (a, b) => {
				if (a.prchordShipDate < b.prchordShipDate) {
					return -1;
				}
				if (a.prchordShipDate > b.prchordShipDate) {
					return 1;
				}
			},
			responsive: ['xl'],
		},
		{
			title: 'Érték',
			render: (record) => <Text type='success'>{record.prchordNetPrice ? record.prchordNetPrice.toLocaleString('hu') : 0} Ft</Text>,
			sorter: (a, b) => {
				if (a.user.fullName < b.user.fullName) {
					return -1;
				}
				if (a.user.fullName > b.user.fullName) {
					return 1;
				}
			},
		},
		{
			title: 'Státusz',
			render: (record) => <Text>{record.purchaseOrderStatus.name}</Text>,
			sorter: (a, b) => {
				if (a.purchaseOrderStatus.name < b.purchaseOrderStatus.name) {
					return -1;
				}
				if (a.purchaseOrderStatus.name > b.purchaseOrderStatus.name) {
					return 1;
				}
			},
		},
	];

	return (
		<PageGuard path={pagePath} data={purchaseOrders}>
			<div className={styles.PurchaseOrders}>
				<NewPurchaseOrderModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getPurchaseOrders={getPurchaseOrders} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={pageDetails.title}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='button1' type='primary' onClick={() => setModalVisible(true)} disabled={props.store.auth.user.userRole.accessLevel >= 25}>
							Új beszerzési rendelés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<SearchBar
								defaultPageSize={defaultPageSize}
								queryParams={queryParams}
								action={setQueryParams}
								searchFields={searchData.searchFields}
								lists={searchData.lists}
								loading={loading}
								setLoading={setLoading}
							/>
						</Col>
						<Col span={24}>
							<Card>
								<Table
									dataSource={purchaseOrders}
									columns={tableColumns}
									size='middle'
									pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize }}
									loading={loading.data}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(PurchaseOrders, pagePath));
