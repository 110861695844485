import React, { useState, useEffect } from 'react';
import styles from './OrderLines.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Row, Col, Card, Form, Input, Button, Space, Table, Select } from 'antd';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';

const OrderLines = (props) => {
	const [listProducts, setListProducts] = useState([]);
	const [listFormProducts, setListFormProducts] = useState([]);
	const [order, setOrder] = useState(props.order);
	const [loading, setLoading] = useState({
		add: false,
		delete: [true, true, true],
	});
	const [uniquePrice, setUniquePrice] = useState(false);

	const [orderItemsForm] = Form.useForm();

	useEffect(() => {
		server
			.get('/list/product')
			.then((response) => {
				let filteredProducts = response.data.filter(
					(product) =>
						(product.prdStatus === 1 && product.customerCategories.includes(product.customerCategories.find((category) => category.id === order.customer.customerCategoryId))) ||
						product.prdExclusiveCustomerId == order.customer.id ||
						product.prdExclusiveCustomerId2 == order.customer.id ||
						product.prdExclusiveCustomerId3 == order.customer.id ||
						product.prdExclusiveCustomerId4 == order.customer.id ||
						product.prdExclusiveCustomerId5 == order.customer.id
				);
				let listProductsData = [];
				filteredProducts.map((item) => {
					listProductsData.push({ label: `${item.prdCode} - ${item.prdName}`, value: item.prdCode });
				});
				setListFormProducts(listProductsData);
				setListProducts(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		setOrder(props.order);
	}, [props.order]);

	const onProductFieldSearch = (searchText) => {};

	const onProductFieldSelect = (data) => {
		let currentProduct = listProducts.find((item) => item.prdCode === data);
		setUniquePrice(false);
		if (order.customer.priceListId) {
			server
				.get('/price-list/' + order.customer.priceListId)
				.then((response) => {
					let priceListPruduct = response.data.products.find((item) => item.id === currentProduct.id);
					let uniqueNetPrice = priceListPruduct ? priceListPruduct.price : currentProduct.prdNetPrice;
					const hasUniquePrice = () => {
						if (priceListPruduct) {
							return setUniquePrice(true);
						} else {
							return null;
						}
					};
					hasUniquePrice();
					orderItemsForm.setFieldsValue({
						productName: currentProduct.prdName,
						olnNetPrice: currentProduct.prdMinusProductPrice == 1 && order.ordType == 3 ? currentProduct.prdRawNetPrice : uniqueNetPrice,
						olnWeight: currentProduct.prdWeight,
						olnVolume: currentProduct.prdVolume,
						olnQtyUnit: currentProduct.prdQtyUnit,
						olnQty: null,
					});
				})
				.catch((error) => {
					errorHandler(error);
				});
		} else {
			setUniquePrice(false);
			orderItemsForm.setFieldsValue({
				productName: currentProduct.prdName,
				olnNetPrice: currentProduct.prdMinusProductPrice == 1 && order.ordType == 3 ? currentProduct.prdRawNetPrice : currentProduct.prdNetPrice,
				olnWeight: currentProduct.prdWeight,
				olnVolume: currentProduct.prdVolume,
				olnQtyUnit: currentProduct.prdQtyUnit,
				olnQty: null,
			});
		}
	};

	const handleFormSubmit = (values) => {
		setLoading({ ...loading, add: true });
		server
			.post('/worker/order/add-line', { values, orderId: order.id })
			.then((response) => {
				setLoading({ ...loading, add: false });
				notification('success', 'Termék sikeresen hozzáadva.');
				props.getOrder();
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, add: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleFormReset = () => {
		orderItemsForm.resetFields();
	};

	const handleDeleteItem = (item) => {
		setLoading({ ...loading, delete: item });
		server
			.post('/worker/order/delete-line', { item, orderId: order.id })
			.then((response) => {
				setLoading({ ...loading, delete: false });
				notification('success', 'Termék sikeresen törölve.');
				props.getOrder();
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, delete: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const tableColumns = [
		{
			key: 'prdCode',
			title: 'Termékkód',
			dataIndex: 'prdCode',
		},
		{
			key: 'prdName',
			title: 'Név',
			dataIndex: 'prdName',
		},
		{
			title: 'Mennyiség',
			align: 'center',
			render: (record) => record.orderLine.olnQty,
		},
		{
			title: 'Egység',
			align: 'center',
			render: (record) => record.orderLine.olnQtyUnit,
		},
		{
			title: 'N egységár (Ft)',
			align: 'center',
			render: (record) => parseFloat(record.orderLine.olnNetPrice).toFixed(2),
		},
		{
			title: 'Összsúly (kg)',
			align: 'center',
			render: (record) => record.orderLine.olnSumWeight,
			responsive: ['lg'],
		},
		{
			title: (
				<div>
					Össztérfogat (m<sup>3</sup>)
				</div>
			),
			align: 'center',
			render: (record) => record.orderLine.olnSumVolume,
			responsive: ['lg'],
		},
		{
			title: 'N összesen (Ft)',
			align: 'center',
			render: (record) => parseFloat(record.orderLine.olnSumNetPrice).toFixed(2),
		},
		{
			align: 'right',
			render: (record, index) => {
				return (
					<Button
						type='danger'
						size='small'
						onClick={() => handleDeleteItem(record.orderLine.id)}
						loading={loading.delete == record.orderLine.id ? true : false}
						disabled={order.ordDiscount > 0 || order.orderStatus.key >= 90 || props.loading ? true : false}>
						<DeleteOutlined />
					</Button>
				);
			},
		},
	];

	return (
		<Col className={styles.OrderLines} xs={24}>
			<Card title='Tételek'>
				{!order.ordIsWebshopOrder && (
					<Form form={orderItemsForm} onFinish={handleFormSubmit} key={props.order.id}>
						<Row gutter={5} justify='space-between'>
							<Col xs={24} lg={15}>
								<Form.Item name='productCode' rules={[{ required: true, message: 'Kötelező mező' }]}>
									<Select
										showSearch
										options={listFormProducts}
										placeholder='Search to Select'
										optionFilterProp='label'
										filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
										placeholder='Termékkód'
										onSelect={onProductFieldSelect}
										onSearch={onProductFieldSearch}
									/>
									{/* <AutoComplete options={listFormProducts} onSelect={onProductFieldSelect} onSearch={onProductFieldSearch} placeholder='Termékkód' /> */}
								</Form.Item>
							</Col>
							<Col xs={0} lg={0}>
								<Form.Item name='productName' hidden>
									<Input placeholder='Név' />
								</Form.Item>
							</Col>
							<Col xs={24} lg={3}>
								<Form.Item name='olnQty' rules={[{ required: true, message: 'Kötelező mező' }]}>
									<Input placeholder='Mennyiség' type='number' step='any' />
								</Form.Item>
							</Col>
							<Col xs={0} lg={0}>
								<Form.Item name='olnQtyUnit' hidden>
									<Input placeholder='Egység' />
								</Form.Item>
							</Col>
							<Col xs={24} lg={3}>
								<Form.Item name='olnNetPrice' rules={[{ required: true, message: 'Kötelező mező' }]}>
									<Input
										placeholder='Nettó egységár'
										type='number'
										step='any'
										className={uniquePrice ? styles.uniquePrice : null}
										disabled={props.store.auth.user.userRole.accessLevel > 29}
									/>
								</Form.Item>
							</Col>
							<Col xs={0} lg={0}>
								<Form.Item name='olnWeight' hidden>
									<Input placeholder='Súly' type='number' step='any' />
								</Form.Item>
							</Col>
							<Col xs={0} lg={0}>
								<Form.Item name='olnVolume' hidden>
									<Input placeholder='Térfogat' type='number' step='any' />
								</Form.Item>
							</Col>
							<Col xs={24} lg={3} style={{ textAlign: 'right' }}>
								<Form.Item>
									<Space>
										<Button htmlType='submit' size='small' onClick={handleFormReset}>
											<UndoOutlined />
										</Button>
										<Button type='primary' htmlType='submit' size='small' loading={loading.add} disabled={order.ordDiscount > 0 || order.orderStatus.key >= 90}>
											Hozzáadás
										</Button>
									</Space>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				)}

				{order.products.length > 0 ? (
					<Table
						dataSource={order.products}
						rowKey={(record) => record.id}
						columns={tableColumns}
						pagination={{ defaultPageSize: 100 }}
						size='middle'
						rowClassName={(record) => (record.prdBillingType !== 1 && order.ordType === 3 ? styles.billingConflict : null)}
					/>
				) : null}
			</Card>
		</Col>
	);
};

export default OrderLines;
