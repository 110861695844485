import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import styles from './SalesDashboard.module.scss';

import DailySummary from '../DailySummary/DailySummary';
import NewOrderModal from '../../Modal/NewOrderModal/NewOrderModal';

import { Row, Col, Card, Typography, Collapse } from 'antd';
const { Text } = Typography;
const { Panel } = Collapse;

const SalesDashboard = (props) => {
	const [modalVisible, setModalVisible] = useState(false);
	
	let history = useHistory();

	useEffect(() => {
		props.setLoading(false);
	}, []);

	return (
		<div className={styles.SalesDashboard}>
			<div className={styles.pageBody}>
			<NewOrderModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} />
				<Row gutter={[16, 16]}>
				<Col xs={24} lg={12}>
						<Card className={styles.newOrderCard} onClick={() => setModalVisible(true)}>
							<Text className={styles.title}>Új eladás</Text>
						</Card>
					</Col>
					<Col xs={24} lg={12}>
						<Card className={styles.orderCard} onClick={() => history.push('/orders')}>
							<Text className={styles.title}>Eladások listája</Text>
						</Card>
					</Col>
					<Col xs={24} lg={12}>
						<Card className={styles.customerCard} onClick={() => history.push('/customers')}>
							<Text className={styles.title}>Ügyfeleim</Text>
						</Card>
					</Col>
					<Col xs={24} lg={12}>
						<Card className={styles.stockCard} onClick={() => history.push('/warehouse/' + props.store.auth.user.warehouse.id)}>
							<Text className={styles.title}>Autó árukészlet</Text>
						</Card>
					</Col>
					<Col xs={24} lg={24}>
						<Collapse accordion>
							<Panel header='Napi zárás' key='1' showArrow={false} style={{fontSize: "24px", textAlign: "center"}}>
								<DailySummary userId={props.store.auth.user.id} accessLevel={props.store.auth.user.userRole.accessLevel} listUser={props.listUser}/> {/* ID */}
							</Panel>
						</Collapse>
					</Col>
					{/* 	<Col xs={24} lg={12}>
						<Card className={styles.loadCard} onClick={() => history.push('/producttransfer')}>
							<Text className={styles.title}>Autó töltés/kirakodás</Text>
						</Card>
					</Col> */}
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(SalesDashboard);
