import React, { useState, useEffect } from 'react';
import styles from './NewCustomerModal.module.scss';
import server from '../../../utils/axios/api';
import billingo from '../../../utils/axios/billingo';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Input, Select } from 'antd';
const { Option } = Select;

const NewCustomerModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listUser, setListUser] = useState([]);
	const [listCountry, setListCountry] = useState([]);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		server
			.get('/list/user/include')
			.then((response) => {
				let filteredUser = response.data.filter((item) => item.userRole.key === 'sales' || (item.userRole.accessLevel <= 29 && item.userRole.key !== 'root'));
				setListUser(filteredUser);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/country')
			.then((response) => {
				setListCountry(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const onFinish = (values) => {
		let email = values.email || '';
		setLoading(true);
		billingo
			.post('/partners', {
				name: values.companyName,
				address: {
					country_code: listCountry.find((country) => country.id === values.countryId).code || '',
					post_code: values.billingPostalCode || '',
					city: values.billingCity || '',
					address: values.billingAddress || '',
				},
				taxcode: values.taxID || '',
				emails: [email],
			})
			.then((response) => {
				server
					.post('/customer', {
						...values,
						status: 1,
						userId: values.userId ? values.userId : props.store.auth.user.id,
						billingoCustomerId: response.data.id,
						createdBy: `${props.store.auth.user.lastName} ${props.store.auth.user.firstName}`,
					})
					.then((response) => {
						form.resetFields();
						setLoading(false);
						props.toggle();
						window.open('/customer/' + response.data.id);
					})
					.catch((error) => {
						errorHandler(error);
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewCustomerModal}
			title='Új ügyfél létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				{props.store.auth.user.userRole.accessLevel > 29 ? null : (
					<Form.Item label='Értékesítő' name='userId' rules={[{ required: true, message: 'Kötelező mező' }]}>
						<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
							{listUser.map((user) => {
								return (
									<Option key={user.id} value={user.id}>
										{user.fullName}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				)}

				<Form.Item label='Cégnév' name='companyName' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Irányítószám (számlázási cím)' name='billingPostalCode' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Város (számlázási cím)' name='billingCity' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Utca, házszám (számlázási cím)' name='billingAddress' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Ország (számlázási cím)' name='countryId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{listCountry.map((country) => {
							return (
								<Option key={country.id} value={country.id}>
									{country.name}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label='Adószám' name='taxID' rules={[{ required: true, len: 13, message: 'Az adószám hossza 13 karakter. Pl. 11111111-1-11' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Email cím' name='email' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Telefonszám' name='phone' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NewCustomerModal;
