import React, { useEffect, useState } from 'react';
import styles from './YearlySummary.module.scss';
import moment from 'moment';

import { Row, Col, Card, DatePicker, Select, Statistic } from 'antd';
const { Option } = Select;

const YearlySummary = (props) => {
	const [activeDate, setActiveDate] = useState('');
	const [stats, setStats] = useState({});

	const dateFormat = 'YYYY';

	useEffect(() => {
		setActiveDate(moment().format(dateFormat));
	}, [props.listOrder]);

	useEffect(() => {
		let yearlyNet = props.listOrder
			.filter((order) => moment(order.ordDate).format(dateFormat) == activeDate && order.orderStatus.key == 100)
			.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
      .toFixed(0);
		let yearlyGross = (yearlyNet * 1.27).toFixed(0);

		setStats({
			yearlyNet,
			yearlyGross,
		});
	}, [activeDate, props.listOrder]);

	const handleSelectDate = (data) => {
		setActiveDate(data.format(dateFormat));
	};

	return (
		<Card title='Éves összesítés' className={styles.MonthlySummary}>
			<Row gutter={[16, 16]}>
				<Col xs={24} lg={24}>
					<DatePicker defaultValue={moment()} format={dateFormat} picker='year' onChange={handleSelectDate} allowClear={false} style={{ width: '100%' }} size='large' />
				</Col>
				<Col xs={24} lg={12}>
					<Statistic
						title='Összesen nettó'
						value={stats.yearlyNet}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={12}>
					<Statistic
						title='Összesen bruttó'
						value={stats.yearlyGross}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
			</Row>
		</Card>
	);
};

export default YearlySummary;
