import React, { useState, useEffect } from 'react';
import styles from './NewUserModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Input, Select } from 'antd';
const { Option } = Select;

const NewUserModal = (props) => {
	const [listUserRole, setListUserRole] = useState([]);
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		server
			.get('/list/user-role')
			.then((response) => {
				let filteredData = response.data.filter((item) => item.key !== 'root');
				setListUserRole(filteredData);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.post('/user', {...values, status: 1})
			.then((response) => {
				form.resetFields();
				setLoading(false);
				props.toggle();
				props.getUsers();
				notification('success', 'Felhasználó sikeresen létrehozva.', response.data.companyName);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	let passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/gm; // Minimum twelve characters, at least one uppercase letter, one lowercase letter and one number:

	return (
		<Modal
			className={styles.NewUserModal}
			title='Új felhasználó létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item label='Jogosultsági szint' name='userRoleId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{listUserRole.map((userRole) => {
							return (
								<Option key={userRole.id} value={userRole.id}>
									{`${userRole.accessLevel} - ${userRole.name}`}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label='Felhasználónév' name='username' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Email cím' name='email' rules={[{ required: true, type: 'email', message: 'Hibás formátum' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Jelszó' name='password' rules={[{ required: true, message: 'Min. 12 karakter, min. 1 nagybetű, min. 1 szám', pattern: passwordRegExp }]}>
					<Input type='password' />
				</Form.Item>
				<Form.Item
					label='Jelszó megerősítés'
					name='passwordconfirm'
					rules={[
						{ required: true, message: 'Kötelező mező' },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject('A két jelszó nem egyezik');
							},
						}),
					]}>
					<Input type='password' />
				</Form.Item>
				<Form.Item label='Vezetéknév' name='lastName' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Keresztnév' name='firstName' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NewUserModal;
