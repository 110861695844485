import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/style.scss';
import App from './containers/App/App';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import generalReducer from './store/reducers/reducer';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/hu_HU';

const rootReducer = combineReducers({
	general: generalReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
/* 	<React.StrictMode> */
		<BrowserRouter>
			<Provider store={store}>
			<ConfigProvider locale={locale}>
				<App />
				</ConfigProvider>
			</Provider>
		</BrowserRouter>
/* 	</React.StrictMode> */,
	document.getElementById('root')
);
