import React, { useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './App.module.scss';
import './App.less';
import appData from '../../../package.json';
import { LoopCircleLoading } from 'react-loadingg';

import Guard from '../Guard/Guard';
import PageLoader from '../../components/PageLoader/PageLoader';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Sidebar from '../../containers/Sidebar/Sidebar';
import Header from '../../containers/Header/Header';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Customers from '../../pages/Customers/Customers';
import Customer from '../../pages/Customers/Customer/Customer';
import Orders from '../../pages/Orders/Orders';
import Order from '../../pages/Orders/Order/Order';
import OrderPayments from '../../pages/OrderPayments/OrderPayments';
import PriceLists from '../../pages/PriceLists/PriceLists';
import PriceList from '../../pages/PriceLists/PriceList/PriceList';
import Products from '../../pages/Products/Products';
import Product from '../../pages/Products/Product/Product';
import Vendors from '../../pages/Vendors/Vendors';
import Vendor from '../../pages/Vendors/Vendor/Vendor';
import Warehouses from '../../pages/Warehouses/Warehouses';
import Warehouse from '../../pages/Warehouses/Warehouse/Warehouse';
import PurchaseOrders from '../../pages/PurchaseOrders/PurchaseOrders';
import PurchaseOrder from '../../pages/PurchaseOrders/PurchaseOrder/PurchaseOrder';
import PurchaseOrderPayments from '../../pages/PurchaseOrderPayments/PurchaseOrderPayments';
import ProductTransfer from '../../pages/ProductTransfers/ProductTransfer/ProductTransfer';
import Users from '../../pages/Users/Users';
import User from '../../pages/Users/User/User';
import Forbidden from '../../pages/Auth/Forbidden/Forbidden';

import { Layout, Typography } from 'antd';

const { Content, Footer } = Layout;
const { Text } = Typography;

const App = (props) => {
	const cssClasses = [styles.App];
	const routeProps = {
		store: props.store,
	};
	const [isLoading, setIsLoading] = useState(false);

	return (
		<div className={cssClasses.join(' ')}>
			{isLoading ? (
				<div style={{ backgroundColor: '#ffffff', width: '100%', height: '100vh' }}>
					<LoopCircleLoading color='#00a838' />
				</div>
			) : null}
			<Layout className={styles.siteContainer}>
				<Guard store={props.store}>
					<PageLoader store={props.store} />
					<Sidebar store={props.store} />
					<Layout className={props.store.sidebar.collapsed ? styles.siteLayoutSmallMargin : styles.siteLayoutLargeMargin}>
						<Header className={styles.header} store={props.store} />
						<Content className={styles.content}>
							<ErrorBoundary>
								<Switch>
									<Route exact path='/'>
										{props.store.auth.isAuthenticated ? (
											props.store.auth.user.userRole.accessLevel > 29 ? (
												<Dashboard {...routeProps} />
											) : (
												<Redirect to='/orders/nonwebshop' />
											)
										) : (
											<Redirect to='/login' />
										)}
									</Route>
									<Route exact path='/dashboard'>
										{props.store.auth.isAuthenticated ? <Dashboard {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/dashboard/users'>
										{props.store.auth.isAuthenticated ? <Dashboard {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customers'>
										{props.store.auth.isAuthenticated ? <Redirect to='/customers/1/nonwebshop' /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customers/1'>
										{props.store.auth.isAuthenticated ? <Redirect to='/customers/1/nonwebshop' /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customers/:status/:type'>
										{props.store.auth.isAuthenticated ? <Customers {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/customer/:id'>
										{props.store.auth.isAuthenticated ? <Customer {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/orders'>
										<Redirect to='/orders/nonwebshop' />
									</Route>
									<Route exact path='/orders/:type'>
										{props.store.auth.isAuthenticated ? <Orders {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/order/:id'>
										{props.store.auth.isAuthenticated ? <Order {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/pricelists'>
										{props.store.auth.isAuthenticated ? <PriceLists {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/pricelist/:id'>
										{props.store.auth.isAuthenticated ? <PriceList {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/products'>
										{props.store.auth.isAuthenticated ? <Redirect to='/products/1/nonwebshop' /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/products/1'>
										{props.store.auth.isAuthenticated ? <Redirect to='/products/1/nonwebshop' /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/products/:status/:type'>
										{props.store.auth.isAuthenticated ? <Products {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/product/:id'>
										{props.store.auth.isAuthenticated ? <Product {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/vendors'>
										{props.store.auth.isAuthenticated ? <Redirect to='/vendors/1' /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/vendors/:status'>
										{props.store.auth.isAuthenticated ? <Vendors {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/vendor/:id'>
										{props.store.auth.isAuthenticated ? <Vendor {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/warehouses'>
										{props.store.auth.isAuthenticated ? <Warehouses {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/warehouse/:id'>
										{props.store.auth.isAuthenticated ? <Warehouse {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/purchaseorders'>
										{props.store.auth.isAuthenticated ? <PurchaseOrders {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/purchaseorder/:id'>
										{props.store.auth.isAuthenticated ? <PurchaseOrder {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/producttransfer'>
										{props.store.auth.isAuthenticated ? <ProductTransfer {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/users'>
										{props.store.auth.isAuthenticated ? <Users {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/user/:id'>
										{props.store.auth.isAuthenticated ? <User {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/orderpayments'>
										{props.store.auth.isAuthenticated ? <OrderPayments {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/purchaseorderpayments'>
										{props.store.auth.isAuthenticated ? <PurchaseOrderPayments {...routeProps} /> : <Redirect to='/login' />}
									</Route>
									<Route exact path='/forbidden'>
										{props.store.auth.isAuthenticated ? <Forbidden {...routeProps} /> : <Redirect to='/login' />}
									</Route>
								</Switch>
							</ErrorBoundary>
						</Content>
						<Footer className={styles.footer} store={props.store}>
							<Text type='secondary'>
								{props.store.system.appName} - v{appData.version} - {process.env.NODE_ENV}
							</Text>
						</Footer>
					</Layout>
				</Guard>
			</Layout>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
	};
};

export default connect(mapStateToProps)(App);
