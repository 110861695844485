import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Customers.module.scss';
import errorHandler from '../../utils/error-handler';
import server from '../../utils/axios/api';
import billingo from '../../utils/axios/billingo';
import PageGuard from '../../components/PageGuard/PageGuard';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Space, Statistic, Form } from 'antd';
import NewCustomerModal from '../../components/Modal/NewCustomerModal/NewCustomerModal';
import SearchBar from '../../components/SearchBar/SearchBar';

const { Text } = Typography;

const pagePath = 'customers';
const defaultPageSize = 50;

const searchData = {
	searchFields: [
		{ key: 'companyName', name: 'Cégnév' },
		{ key: 'nickname', name: 'Fantázianév' },
		{ key: 'billingCity', name: 'Város' },
		{ key: 'contactPersonName', name: 'Kapcsolattartó' },
		{ key: 'phone', name: 'Telefon' },
		{ key: 'email', name: 'Email cím' },
		{ key: 'taxID', name: 'Adószám' },
		{ key: 'customerCategoryId', name: 'Kategória', type: 'dropdown', list: 'customerCategory', value: 'id', text: 'name' },
		{ key: 'user.firstName', name: 'Értékesítő', type: 'dropdown', list: 'user', value: 'firstName', text: 'fullName' },
		{ key: 'paymentMethodId', name: 'Fizetési mód', type: 'dropdown', list: 'paymentMethod', value: 'id', text: 'name' },
	],
	lists: ['user', 'customerCategory', 'paymentMethod'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const Customers = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [customers, setCustomers] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchData.searchValues },
	});
	const [loading, setLoading] = useState({
		search: false,
		export: false,
	});

	let history = useHistory();
	const params = useParams();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		if (pageDetails.accessLevel <= props.store.auth.user.userRole.accessLevel) {
			history.push('/forbidden');
		}
	}, [pageDetails]);

	useEffect(() => {
		getCustomers();
	}, [params.status, params.type, queryParams]);

	const getCustomers = () => {
		server
			.get(
				`/customer/all/${params.type}?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${JSON.stringify(queryParams.search)}&fields=${JSON.stringify(searchData.searchFields)}&status=${
					params.status
				}`
			)
			.then((response) => {
				const customersData = response.data.rows;
				const filteredData = customersData.filter((item) => item.userId === props.store.auth.user.id);
				setCustomers(props.store.auth.user.userRole.accessLevel > 29 ? filteredData : customersData);
				setTotal(response.data.count);
				setLoading({ ...loading, data: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			customers.map((customer) => {
				if (list.find((item) => item.value == customer[column1][column2])) {
					return null;
				} else {
					return list.push({ text: customer[column1][column2], value: customer[column1][column2] });
				}
			});
		} else {
			customers.map((customer) => {
				if (list.find((item) => item.value == customer[column1])) {
					return null;
				} else {
					return list.push({ text: customer[column1], value: customer[column1] });
				}
			});
		}
		return list;
	};

	const handleClick = (id) => {
		if (props.store.auth.user.userRole.key === 'sales') {
			history.push('/customer/' + id);
		} else {
			window.open('/customer/' + id);
		}
	};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const tableColumns = [
		{
			title: 'Cégnév',
			dataIndex: 'companyName',
			render: (text, record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)} strong>
					{`${record.companyName}${record.nickname && ` (${record.nickname})`}`}
				</Text>
			),
			sorter: (a, b) => {
				if (a.companyName < b.companyName) {
					return -1;
				}
				if (a.companyName > b.companyName) {
					return 1;
				}
			},
		},
		{
			title: 'Kapcsolattartó',
			dataIndex: 'contactPersonName',
			sorter: (a, b) => {
				if (a.contactPersonName < b.contactPersonName) {
					return -1;
				}
				if (a.contactPersonName > b.contactPersonName) {
					return 1;
				}
			},
		},
		{
			title: 'Telefon',
			dataIndex: 'phone',
			sorter: (a, b) => {
				if (a.phone < b.phone) {
					return -1;
				}
				if (a.phone > b.phone) {
					return 1;
				}
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => {
				if (a.email < b.email) {
					return -1;
				}
				if (a.email > b.email) {
					return 1;
				}
			},
		},
		{
			title: 'Kategória',
			render: (record) => record.customerCategory.name || '',
			sorter: (a, b) => {
				if (a.customerCategory.name < b.customerCategory.name) {
					return -1;
				}
				if (a.customerCategory.name > b.customerCategory.name) {
					return 1;
				}
			},
		},
		{
			title: 'Értékesítő',
			render: (record) => record.user.fullName || '',
			sorter: (a, b) => {
				if (a.user.fullName < b.user.fullName) {
					return -1;
				}
				if (a.user.fullName > b.user.fullName) {
					return 1;
				}
			},
		},
		{
			title: 'Fizetési mód',
			render: (record) => record.paymentMethod.name || '',
			sorter: (a, b) => {
				if (a.paymentMethod.name < b.paymentMethod.name) {
					return -1;
				}
				if (a.paymentMethod.name > b.paymentMethod.name) {
					return 1;
				}
			},
		},
	];

	const exportToBillingo = () => {
		customers.map((customer, idx) => {
			setTimeout(() => {
				let email = customer.email || '';

				billingo
					.post('/partners', {
						name: customer.companyName,
						address: {
							country_code: 'HU',
							post_code: customer.billingPostalCode || '',
							city: customer.billingCity || '',
							address: customer.billingAddress || '',
						},
						taxcode: customer.taxID || '',
						emails: [email],
					})
					.then((response) => {
						server
							.patch('/customer/' + customer.id, {
								billingoCustomerId: response.data.id,
							})
							.then((resp) => {
								console.log(response.data.id, customer.id, customer.companyName);
								console.count();
							})
							.catch((error) => {
								errorHandler(error);
							});
					})
					.catch((error) => {
						errorHandler(error);
					});
			}, idx * 500);
		});
	};

	const handleExportClick = (format) => {
		setLoading({ ...loading, export: true });
		switch (format) {
			default:
				server
					.get(`/worker/export/customers/${format}`)
					.then((response) => {
						console.log(response);
						fileDownload(response.data, `customers_${moment().format('YYYY-MM-DD_HH-mm-ss')}.${format}`);
						setLoading({ ...loading, export: false });
					})
					.catch((error) => {
						errorHandler(error);
					});
		}
	};

	return (
		<PageGuard path={pagePath} data={customers}>
			<div className={styles.Customers}>
				<NewCustomerModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getCustomers={getCustomers} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={`${pageDetails.title} (${params.status == 1 ? 'aktív' : 'inaktív'})`}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='3' onClick={exportToBillingo} disabled={props.store.auth.user.username != 'attilatokolics'}>
							Export Billingo
						</Button>,
						<Button key='11' onClick={() => handleExportClick('xls')} loading={loading.export} disabled={props.store.auth.user.userRole.key === 'sales'}>
							Export XLS
						</Button>,
						<Button key='12' onClick={() => handleExportClick('csv')} loading={loading.export} disabled={props.store.auth.user.userRole.key === 'sales'}>
							Export CSV
						</Button>,
						<Button key='2' type='primary' onClick={() => setModalVisible(true)}>
							Új ügyfél
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col xs={24} lg={19}>
							<SearchBar
								defaultPageSize={defaultPageSize}
								queryParams={queryParams}
								action={setQueryParams}
								searchFields={searchData.searchFields}
								lists={searchData.lists}
								loading={loading}
								setLoading={setLoading}
							/>
						</Col>
						<Col span={24} lg={19}>
							<Card>
								<Table
									dataSource={customers}
									columns={tableColumns}
									size='middle'
									pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize }}
									loading={loading.data}
								/>
							</Card>
						</Col>
						<Col xs={0} lg={5}>
							<Row>
								<Col span={24}>
									<Card title='Statisztika'>
										<Space direction='vertical' size='middle'>
											<Statistic title='Összes ügyfél' value={total} />
										</Space>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Customers, pagePath));
