import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Header.module.scss';
import serverAuth from '../../utils/axios/auth';
import errorHandler from '../../utils/error-handler';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';

import { Layout, Row, Col, Avatar, Space, Divider, Drawer, Typography } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Text } = Typography;

const CustomHeader = (props) => {
	const [fullScreen, setFullScreen] = useState(false);
	const [userData, setUserData] = useState({});
	const [systemSettings, setSystemSettings] = useState({});
	const [drawerVisible, setDrawerVisible] = useState(false);

	let history = useHistory();

	useEffect(() => {
		setUserData(props.store.auth.user ? props.store.auth.user : {});
	}, [props.store.auth.user]);

	useEffect(() => {
		setSystemSettings(props.store.system ? props.store.system : {});
	}, [props.store.system]);

	const fullscreenHandler = () => {
		setFullScreen(!fullScreen);
		if (!fullScreen) {
			document.documentElement.requestFullscreen();
		} else {
			document.exitFullscreen();
		}
	};

	const logoutHandler = () => {
		serverAuth
			.get('/logout')
			.then(() => {
				props.logout();
			})
			.catch((err) => {
				errorHandler(err);
			});
	};

	const settingsHandler = () => {
		history.push('/user/' + userData.id);
	};

	const handleDrawerClose = () => {
		setDrawerVisible(false);
	};

	const handleDrawerOpen = () => {
		setDrawerVisible(true);
	};

	const fullScreenIcon = fullScreen ? (
		<FullscreenExitOutlined className={styles.icon} onClick={fullscreenHandler} />
	) : (
		<FullscreenOutlined className={styles.icon} onClick={fullscreenHandler} />
	);

	return (
		<Header className={styles.Header}>
			<Drawer title='Felhasználói menü' placement='right' closable={true} onClose={handleDrawerClose} visible={drawerVisible} key='right'>
				<Row>
					<Col xs={24}>
						<Text className={styles.drawerItem} onClick={settingsHandler}>
							Beállítások
						</Text>
					</Col>
					<Col xs={24} className='mt-20'>
						<Text className={styles.drawerItem} onClick={logoutHandler}>
							Kijelentkezés
						</Text>
					</Col>
				</Row>
			</Drawer>
			<Row>
				<Col xs={12} className={styles.left}></Col>
				<Col xs={24} lg={12} className={styles.right}>
					<Space size='large' className={styles.iconGroup}>
						{fullScreenIcon}
						{/* 	<Badge count={2} size='small'>
							<MailOutlined className={styles.icon} />
						</Badge>
						<BellOutlined className={styles.icon} /> */}
						<Divider type='vertical' />
					</Space>
					<Space size='middle' className={styles.userDetails}>
						<div onClick={handleDrawerOpen}>
							{userData.lastName} {userData.firstName}
						</div>
						<div>
							<Avatar
								size={40}
								src={userData.photo ? systemSettings.filesPath + '/users/' + userData.photo : systemSettings.filesPath + '/users/default.png'}
								onClick={handleDrawerOpen}
							/>
						</div>
					</Space>
				</Col>
			</Row>
		</Header>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: (params) => {
			dispatch(actionCreators.logout(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(CustomHeader);
