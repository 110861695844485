import React, { useState, useEffect } from 'react';
import styles from './PurchaseOrderLines.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Row, Col, Card, Form, Input, Button, Space, Table, Select, Switch } from 'antd';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';

const PurchaseOrderLines = (props) => {
	const [listProducts, setListProducts] = useState([]);
	const [listFormProducts, setListFormProducts] = useState([]);
	const [purchaseOrder, setPurchaseOrder] = useState(props.purchaseOrder);
	const [loading, setLoading] = useState({
		add: false,
		delete: [true, true, true],
	});
	const [purchaseOrderItemsForm] = Form.useForm();

	useEffect(() => {
		server
			.get('/list/product')
			.then((response) => {
				let filteredProducts = response.data.filter((product) => product.prdStatus === 1 && product.vendorId === purchaseOrder.vendorId);
				let listProductsData = [];
				filteredProducts.map((item) => {
					listProductsData.push({ label: `${item.prdCode} - ${item.prdName}`, value: item.prdCode });
				});
				setListFormProducts(listProductsData);
				setListProducts(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		setPurchaseOrder(props.purchaseOrder);
	}, [props.purchaseOrder]);

	const onProductFieldSearch = (searchText) => { };

	const onProductFieldSelect = (data) => {
		let currentProduct = listProducts.find((item) => item.prdCode === data);
		purchaseOrderItemsForm.setFieldsValue({
			productName: currentProduct.prdName,
			prcholnNetPrice: currentProduct.prdLastPrice,
			prcholnNetAvgPrice: currentProduct.prdNetAvgPrice,
			prcholnWeight: currentProduct.prdWeight,
			prcholnVolume: currentProduct.prdVolume,
			prcholnQtyUnit: currentProduct.prdQtyUnit,
			prcholnQty: null
		});
	};

	const handleFormSubmit = (values) => {
		setLoading({ ...loading, add: true });
		server
			.post('/worker/purchase-order/add-line', { values, purchaseOrderId: purchaseOrder.id })
			.then((response) => {
				setLoading({ ...loading, add: false });
				notification('success', 'Termék sikeresen hozzáadva.');
				props.getPurchaseOrder();
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, add: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleFormReset = () => {
		purchaseOrderItemsForm.resetFields();
	};

	const handleDeleteItem = (item) => {
		setLoading({ ...loading, delete: item });
		server
			.post('/worker/purchase-order/delete-line', { item, purchaseOrderId: purchaseOrder.id })
			.then((response) => {
				setLoading({ ...loading, delete: false });
				notification('success', 'Termék sikeresen törölve.');
				props.getPurchaseOrder();
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, delete: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleNewPrice = (e, record) => {
		setLoading({ ...loading, save: false });
		const netPrice = e.target.value;
		const sumNetPrice = e.target.value * record.purchaseOrderLine.prcholnQty
		const lineId = record.purchaseOrderLine.id;
		server
			.post('/worker/purchase-order/modify-line-price', { netPrice, sumNetPrice, lineId, purchaseOrderId: purchaseOrder.id })
			.then((response) => {
				setLoading({ ...loading, add: false });
				notification('success', 'Tétel sikeresen frissítve');
				props.getPurchaseOrder();
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, add: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handlePriceUpdateSwitch = (checked, id) => {
			server
			.patch('/purchase-order-line/' + id, { enablePriceUpdate: checked ? 1 : 0 })
			.then((response) => {
				notification('success', 'Tétel sikeresen módosítva');
				props.getPurchaseOrder();
			})
			.catch((error) => {
				errorHandler(error);
			});
	}

	const tableColumns = [
		{
			key: 'prdCode',
			title: 'Termékkód',
			dataIndex: 'prdCode',
			responsive: ['lg'],
		},
		{
			key: 'prdName',
			title: 'Név',
			dataIndex: 'prdName',
		},
		{
			title: 'Mennyiség',
			align: 'center',
			render: (record) => record.purchaseOrderLine.prcholnQty,
		},
		{
			title: 'Egység',
			align: 'center',
			render: (record) => record.purchaseOrderLine.prcholnQtyUnit,
		},
		{
			title: 'N egységár (Ft)',
			align: 'center',
			render: (record) => (
				<Input
					style={{ width: 90 }}
					type="number"
					defaultValue={record.purchaseOrderLine.prcholnNetPrice}
					onPressEnter={(e) => handleNewPrice(e, record)}
					disabled={props.purchaseOrder.purchaseOrderStatus && props.purchaseOrder.purchaseOrderStatus.key === 100}
				/>
			),
		},
		{
			title: 'Összsúly (kg)',
			align: 'center',
			render: (record) => record.purchaseOrderLine.prcholnSumWeight,
			responsive: ['lg'],
		},
		{
			title: (
				<div>
					Össztérfogat (m<sup>3</sup>)
				</div>
			),
			align: 'center',
			render: (record) => record.purchaseOrderLine.prcholnSumVolume,
			responsive: ['lg'],
		},
		{
			title: 'N összesen (Ft)',
			align: 'center',
			render: (record) => Math.round(record.purchaseOrderLine.prcholnSumNetPrice).toLocaleString('hu'),
			responsive: ['lg'],
		},
		{
			title: 'Ár frissítése',
			align: 'center',
			render: (record) => {
				return (
					<Switch checkedChildren='Igen' unCheckedChildren='Nem' defaultChecked={record.purchaseOrderLine.enablePriceUpdate === 1 ? true : false} onChange={(checked, event) => handlePriceUpdateSwitch(checked, record.purchaseOrderLine.id)} />
				)
			},
			responsive: ['lg'],
		},
		{
			align: 'right',
			render: (record, index) => {
				return (
					<Button
						type='danger'
						size='small'
						onClick={() => handleDeleteItem(record.purchaseOrderLine.id)}
						loading={loading.delete == record.purchaseOrderLine.id ? true : false}
						disabled={props.purchaseOrder.purchaseOrderStatusId != 1}>
						{' '}
						{/* ID:status */}
						<DeleteOutlined />
					</Button>
				);
			},
		},
	];

	return (
		<Col className={styles.PurchaseOrderLines} xs={24}>
			<Card title='Tételek'>
				<Form form={purchaseOrderItemsForm} onFinish={handleFormSubmit} key={props.purchaseOrder.id}>
					<Row gutter={5} justify='space-between'>
						<Col xs={24} lg={15}>
							<Form.Item name='productCode' rules={[{ required: true, message: 'Kötelező mező' }]}>
								<Select
									showSearch
									options={listFormProducts}
									placeholder='Search to Select'
									optionFilterProp='label'
									filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
									placeholder='Termékkód'
									onSelect={onProductFieldSelect}
									onSearch={onProductFieldSearch}
								/>
								{/* <AutoComplete options={listFormProducts} onSelect={onProductFieldSelect} onSearch={onProductFieldSearch} placeholder='Termékkód' /> */}
							</Form.Item>
						</Col>
						<Col xs={0} lg={0}>
							<Form.Item name='productName' hidden>
								<Input placeholder='Név' />
							</Form.Item>
						</Col>
						<Col xs={24} lg={3}>
							<Form.Item name='prcholnQty' rules={[{ required: true, message: 'Kötelező mező' }]}>
								<Input placeholder='Mennyiség' type='number' step='any' />
							</Form.Item>
						</Col>
						<Col xs={0} lg={0}>
							<Form.Item name='prcholnQtyUnit' hidden>
								<Input placeholder='Egység' />
							</Form.Item>
						</Col>
						<Col xs={24} lg={3}>
							<Form.Item name='prcholnNetPrice' rules={[{ required: true, message: 'Kötelező mező' }]}>
								<Input placeholder='Nettó egységár' type='number' step='any' />
							</Form.Item>
						</Col>
						<Col xs={0} lg={0}>
							<Form.Item name='prcholnNetAvgPrice' hidden>
								<Input placeholder='Térfogat' type='number' step='any' />
							</Form.Item>
						</Col>
						<Col xs={0} lg={0}>
							<Form.Item name='prcholnWeight' hidden>
								<Input placeholder='Súly' type='number' step='any' />
							</Form.Item>
						</Col>
						<Col xs={0} lg={0}>
							<Form.Item name='prcholnVolume' hidden>
								<Input placeholder='Térfogat' type='number' step='any' />
							</Form.Item>
						</Col>
						<Col xs={24} lg={3} style={{ textAlign: 'right' }}>
							<Form.Item>
								<Space>
									<Button htmlType='submit' size='small' onClick={handleFormReset}>
										<UndoOutlined />
									</Button>
									<Button type='primary' htmlType='submit' size='small' loading={loading.add} disabled={props.purchaseOrder.purchaseOrderStatusId != 1}>
										{' '}
										{/* ID:status */}
										Hozzáadás
									</Button>
								</Space>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				{purchaseOrder.products.length > 0 ? (
					<Table dataSource={purchaseOrder.products} rowKey={(record) => record.id} columns={tableColumns} pagination={{ defaultPageSize: 100 }} size='middle' />
				) : null}
			</Card>
		</Col>
	);
};

export default PurchaseOrderLines;
