import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import serverAuth from '../../utils/axios/auth';
import { DisappearedLoading } from 'react-loadingg';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Auth from '../../pages/Auth/Auth';

const Guard = (props) => {
	const [isLoading, setIsLoading] = useState(true);

	let history = useHistory();
	let location = useLocation();

	useEffect(() => {
		serverAuth
			.get('/user')
			.then((response) => {
				props.setUser(response.data);
				setIsLoading(false);
				history.push(location.pathname);
				props.initializeApp();
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, []);

	if (props.store.auth.isAuthenticated) {
		return <>{props.children}</>;
	} else {
		return (
			<ErrorBoundary>
				{isLoading ? (
					<DisappearedLoading color='#00a838' style={{ backgroundColor: '#ffffff', width: '100%', height: '100vh' }} />
				) : (
					<>
						<Switch>
							<Route exact path='/login' render={() => <Auth />} />
							<Route exact path='/forgotpassword' render={() => <Auth />} />
							<Route exact path='/resetpassword' render={() => <Auth />} />
							<Route render={() => <Redirect to='/login' />} />
						</Switch>
					</>
				)}
			</ErrorBoundary>
		);
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		initializeApp: (params) => {
			dispatch(actionCreators.initialize_app(params));
		},
		setUser: (params) => {
			dispatch(actionCreators.set_user(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Guard);
