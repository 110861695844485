import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './PriceList.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import PageGuard from '../../../components/PageGuard/PageGuard';

import { PageHeader, Button, Row, Col, Card, Transfer, Table, Input, Modal } from 'antd';
import difference from 'lodash/difference';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const pagePath = 'pricelist';

const PriceList = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [priceList, setPriceList] = useState({ products: [] });
	const [customer, setCustomer] = useState({});

	const [productListLeft, setProductListLeft] = useState([]);
	const [productListRight, setProductListRight] = useState([]);
	const [itemPrice, setItemPrice] = useState([]);
	const [loading, setLoading] = useState({
		save: false,
	});

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getPriceList();
	}, [params.id]);

	useEffect(() => {
		let prices = [];
		priceList.products.map((item) => {
			prices.push({ key: item.key, price: item.price });
		});
		setItemPrice(prices);
	}, [priceList]);

	const getPriceList = () => {
		server
			.get('/price-list/' + params.id)
			.then((response) => {
				const priceListData = response.data;
				setPriceList(priceListData);
				server
					.get('/customer/' + priceListData.customerId)
					.then((response) => {
						setCustomer(response.data);
						server
							.get('/customerCategory/' + response.data.customerCategoryId + '/products')
							.then((response) => {
								let filteredProducts = response.data.products.filter((product) => product.prdStatus === 1);
								setProductListLeft(filteredProducts);
								let selectedProductKeys = [];
								priceListData.products.map((item) => {
									const currentProduct = filteredProducts.find(product => product.key == item.key)
									if (currentProduct) {
										selectedProductKeys.push(currentProduct.key);
									} else {
										return
									}				
								});
								setProductListRight(selectedProductKeys);
								props.setLoading(false);
							})
							.catch((error) => {
								errorHandler(error);
							});
					})
					.catch((error) => {
						errorHandler(error);
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
		<Transfer {...restProps} showSelectAll={false}>
			{({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
				const columns = direction === 'left' ? leftColumns : rightColumns;

				const rowSelection = {
					getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
					onSelectAll(selected, selectedRows) {
						const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
						const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
						onItemSelectAll(diffKeys, selected);
					},
					onSelect({ key }, selected) {
						onItemSelect(key, selected);
					},
					selectedRowKeys: listSelectedKeys,
				};

				return (
					<Table
						rowSelection={rowSelection}
						columns={columns}
						dataSource={filteredItems}
						pagination={{ pageSize: 1000 }}
						size='small'
						style={{ pointerEvents: listDisabled ? 'none' : null }}
						onRow={({ key, disabled: itemDisabled }) => ({
							onClick: () => {
								if (itemDisabled || listDisabled) return;
								onItemSelect(key, !listSelectedKeys.includes(key));
							},
						})}
					/>
				);
			}}
		</Transfer>
	);

	const leftTableColumns = [
		{
			dataIndex: 'prdCode',
			title: 'Termékkód',
		},
		{
			dataIndex: 'prdName',
			title: 'Név',
		},
		{
			dataIndex: 'prdNetPrice',
			title: 'Listaár (Ft)',
		},
	];
	const rightTableColumns = [
		{
			dataIndex: 'prdCode',
			title: 'Termékkód',
		},
		{
			dataIndex: 'prdName',
			title: 'Név',
		},
		{
			title: 'Egyéni ár (Ft)',
			render: (record) => {
				let priceListItem = itemPrice.find((item) => item.key === record.key);
				return (
					<Input
						size='small'
						style={{ width: '100px' }}
						defaultValue={
							!priceList.products.includes(priceList.products.find((product) => product.id == record.id))
								? 'Mentés'
								: typeof priceListItem === 'undefined'
								? record.prdNetPrice
								: priceListItem.price
						}
						onPressEnter={(e) => handlePriceInput(record.key, e.currentTarget.value)}
						type={!priceList.products.includes(priceList.products.find((product) => product.id == record.id)) ? 'text' : 'number'}
						step='any'
						disabled={!priceList.products.includes(priceList.products.find((product) => product.id == record.id))}
					/>
				);
			},
		},
	];

	const onChange = (nextTargetKeys) => {
		setProductListRight(nextTargetKeys);
	};

	const handlePriceInput = (key, newPrice) => {
		let currentIndex = itemPrice.findIndex((item) => item.key === key);
		let newPrices = [...itemPrice];
		newPrices[currentIndex] = { ...newPrices[currentIndex], price: parseFloat(newPrice) };
		setItemPrice(newPrices);
	};

	const handlePriceListSave = () => {
		setLoading({ save: true });
		server
			.post('/worker/price-list/process', { productListRight, itemPrice, priceListId: priceList._id, customerCategoryId: customer.customerCategoryId })
			.then((response) => {
				setLoading({ save: false });
				notification('success', 'Árlista sikeresen frissítve');
				getPriceList();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	/*
	const handlePriceListSave = () => {
		setLoading({ save: true });

		let modifiedInput = [];
		productListRight.map((key) => {
			let currentProduct = productListLeft.find((product) => product.key === key);
			let currentItem = itemPrice.find((item) => item.key === key);
			modifiedInput.push({
				id: currentProduct.id,
				key: key,
				price: typeof currentItem === 'undefined' ? currentProduct.prdNetPrice : currentItem.price,
			});
		});
		server
			.patch('/price-list/' + params.id, modifiedInput)
			.then((response) => {
				setLoading({ save: false });
				notification('success', 'Árlista sikeresen frissítve');
				getPriceList();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
			});
	};
*/

	const handlePriceListDelete = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné az árlistát?',
			icon: <ExclamationCircleOutlined />,
			content: customer.companyName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				server
					.delete('/price-list/' + params.id)
					.then((response) => {
						history.push('/pricelists');
						notification('success', 'Árlista sikeresen törölve', customer.companyName);
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	return (
		<PageGuard path={pagePath} data={priceList}>
			<div className={styles.PriceList}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={customer.companyName}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='2' type='primary' onClick={handlePriceListDelete} danger>
							Törlés
						</Button>,
						<Button key='1' type='primary' onClick={handlePriceListSave} loading={loading.save}>
							Mentés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card>
								<TableTransfer
									dataSource={productListLeft}
									targetKeys={productListRight}
									disabled={false}
									showSearch={true}
									onChange={onChange}
									filterOption={(inputValue, item) =>
										item.prdCode.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 || item.prdName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
									}
									leftColumns={leftTableColumns}
									rightColumns={rightTableColumns}
									locale={{
										itemUnit: 'termék',
										itemsUnit: 'termék',
										searchPlaceholder: 'Keresés...',
										notFoundContent: 'Nincs találat',
										selectInvert: 'Kijelölés megfordítása',
										selectAll: 'Összes kijelölése',
									}}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(PriceList, pagePath));
