import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Product.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import Moment from 'react-moment';
import zeroFill from 'zero-fill';

import PageGuard from '../../../components/PageGuard/PageGuard';

import { PageHeader, Button, Row, Col, Card, Modal, Form, Input, Switch, Typography, Upload, Select, Table, Checkbox } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;

const pagePath = 'product';

const Product = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [product, setProduct] = useState({});
	const [customerCategory, setCustomerCategory] = useState([]);
	const [listVendor, setListVendor] = useState([]);
	const [listProductCategory, setListProductCategory] = useState([]);
	const [listCustomer, setListCustomer] = useState([]);
	const [listShoprenterCategory, setListShoprenterCategory] = useState([]);
	const [webshopGallery, setWebshopGallery] = useState();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [loading, setLoading] = useState({
		save: false,
		file: false,
	});
	const [productForm] = Form.useForm();

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getProduct();
	}, [params.id]);

	useEffect(() => {
		if (product.prdIsWebshopProduct) {
			server
				.get('/worker/product/webshop-gallery/' + product.prdWebshopProductId)
				.then((response) => {
					setWebshopGallery(response.data);
				})
				.catch((error) => {
					errorHandler(error);
				});
		}
	}, [product]);

	const getProduct = () => {
		server
			.get('/product/' + params.id)
			.then((response) => {
				setProduct(response.data);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	useEffect(() => {
		server
			.get('/list/customer-category')
			.then((response) => {
				setCustomerCategory(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/vendor')
			.then((response) => {
				setListVendor(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/customer')
			.then((response) => {
				setListCustomer(response.data.filter((item) => item.status == 1));
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/shoprenter/categories')
			.then((response) => {
				setListShoprenterCategory(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/product-category')
			.then((response) => {
				setListProductCategory(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, [params.id]);

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleBeforeFileUpload = (file) => {
		const isJpgOrPng = file.type === 'image/jpeg';
		if (!isJpgOrPng) {
			notification('error', 'Csak JPG formátum feltöltése engedélyezett');
		}
		const isLt2M = file.size / 1024 / 1024 < 10;
		if (!isLt2M) {
			notification('error', 'A fájlméret nem lehet több, mint 10mb');
		}
		return isJpgOrPng && isLt2M;
	};

	const handleFileUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading({ ...loading, file: true });
			return;
		}
		if (info.file.status === 'done') {
			setLoading({ ...loading, file: false });
			getProduct();
			notification('success', 'Kép sikeresen feltöltve');
		}
	};

	const handleDeletePhoto = () => {
		setLoading({ save: true });
		server
			.patch('/product/' + params.id, { prdPhoto: null })
			.then((response) => {
				setLoading({ save: false });
				notification('success', 'Kép sikeresen törölve');
				getProduct();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
			});
	};

	const handleProductFormSubmit = (values) => {
		setLoading({ save: true });
		server
			.patch('/product/' + params.id, values)
			.then((response) => {
				setLoading({ save: false });
				notification('success', 'Termék sikeresen frissítve');
				getProduct();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
			});
	};

	const handleCustomerCategorySwitch = (checked, event) => {
		switch (checked) {
			case true:
				server
					.post('/product-customer-category/' + params.id + '/' + event.currentTarget.name)
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			case false:
				server
					.delete('/product-customer-category/' + params.id + '/' + event.currentTarget.name)
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			default:
				return null;
		}
	};

	const handleStatusSwitch = (checked, event) => {
		switch (checked) {
			case true:
				server
					.patch('/product/' + params.id, { prdStatus: 1 })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			case false:
				server
					.patch('/product/' + params.id, { prdStatus: 0 })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			default:
				return null;
		}
	};

	const handleBillingTypeSwitch = (checked, event) => {
		switch (checked) {
			case true:
				server
					.patch('/product/' + params.id, { prdBillingType: 1 })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			case false:
				server
					.patch('/product/' + params.id, { prdBillingType: 0 })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			default:
				return null;
		}
	};

	const handleMinusProductPriceTypeSwitch = (checked, event) => {
		switch (checked) {
			case true:
				server
					.patch('/product/' + params.id, { prdMinusProductPrice: 1 })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			case false:
				server
					.patch('/product/' + params.id, { prdMinusProductPrice: null })
					.then((response) => {
						getProduct();
					})
					.catch((error) => {
						errorHandler(error);
					});
				break;

			default:
				return null;
		}
	};

	const handleStockUpdate = (warehouseId, newQty) => {
		setLoading({ save: true });
		server
			.post('/worker/product/stock', { warehouseId, productId: product.id, newQty })
			.then((response) => {
				console.log(response.data);
				setLoading({ save: false });
				notification('success', 'Raktárkészlet sikeresen frissítve');
				getProduct();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const uploadButton = (
		<div>
			{loading.file ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Képfeltöltés</div>
		</div>
	);

	const handleSaveButtonClick = () => {
		productForm.submit();
	};

	const handleDeleteButtonClick = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a terméket?',
			icon: <ExclamationCircleOutlined />,
			content: product.prdName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				server
					.patch('/product/' + params.id, {
						prdStatus: 3,
					})
					.then((response) => {
						history.push('/products');
						notification('success', 'Termék sikeresen törölve', product.prdName);
					})
					.catch((error) => {
						error.customMessage = 'A termék nem törölhető, mert legalább egy megrendelés tartalmazza';
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleToggleButtonClick = () => {
		confirm({
			title: 'Biztos, hogy aktiválni/inaktiválni szeretné a terméket?',
			icon: <ExclamationCircleOutlined />,
			content: product.prdName,
			okText: 'Módosítás',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				server
					.patch('/product/' + params.id, { prdStatus: product.prdStatus == 1 ? 0 : 1 })
					.then((response) => {
						getProduct();
						notification('success', 'Termék sikeresen módosítva', product.prdName);
					})
					.catch((error) => {
						error.customMessage = 'A termék nem törölhető, mert legalább egy megrendelés tartalmazza';
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const tableColumns = [
		{
			title: 'Raktár',
			render: (record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => history.push('/warehouse/' + record.id)} strong>
					{record.wrhName}
				</Text>
			),
		},
		{
			title: `Mennyiség (${product.prdQtyUnit})`,
			render: (record) => {
				return (
					<>
						<Input
							defaultValue={record.warehouseItem.prdQty}
							onPressEnter={(e) => handleStockUpdate(record.warehouseItem.warehouseId, e.currentTarget.value)}
							disabled={props.store.auth.user.userRole.accessLevel > 10}
						/>
					</>
				);
			},
		},
		{
			title: 'Utolsó frissítés',
			render: (record) => (
				<Moment format='YYYY/MM/DD - HH:mm' local>
					{record.warehouseItem.updatedAt}
				</Moment>
			),
		},
	];

	const tableColumnsPurchaseOrders = [
		{
			title: 'Azonosító',
			key: 'id',
			render: (record) => (
				<Text onClick={() => history.push(`/purchaseorder/${record.id}`)} strong style={{ cursor: 'pointer' }}>
					#{zeroFill(6, record.id)}
				</Text>
			),
		},
		{
			title: 'Dátum',
			dataIndex: 'prchordDate',
			key: 'prchordDate',
			render: (text) => {
				return (
					<Moment format='YYYY/MM/DD' local>
						{text}
					</Moment>
				);
			},
		},
		{
			title: 'Érték',
			dataIndex: 'prchordNetPrice',
			key: 'prchordNetPrice',
			render: (text) => text.toLocaleString('hu') + ' Ft',
		},
	];

	return (
		<PageGuard path={pagePath} data={product}>
			<div className={styles.Product}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={product.prdCode + ' - ' + product.prdName}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='1' type='primary' onClick={handleDeleteButtonClick} danger>
							Törlés
						</Button>,
						<Button key='2' type='primary' onClick={handleToggleButtonClick} danger>
							{product.prdStatus == 1 ? 'Inaktiválás' : 'Aktiválás'}
						</Button>,
						<Button key='3' type='primary' onClick={handleSaveButtonClick} loading={loading.save}>
							Mentés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Form
						key={product.id}
						form={productForm}
						layout='vertical'
						initialValues={{
							requiredMark,
							...product,
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleProductFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={6}>
								<Card>
									{product.prdPhoto ? (
										<Row gutter={[16, 16]} justify='end'>
											<Col>
												<img src={props.store.system.filesPath + '/products/' + product.prdPhoto} alt='photo' style={{ width: '100%' }} />
											</Col>
											<Col style={{ textAlign: 'right' }}>
												<Button danger onClick={handleDeletePhoto}>
													Törlés
												</Button>
											</Col>
										</Row>
									) : null}
									<Upload
										name='file'
										listType='picture-card'
										className='avatar-uploader'
										showUploadList={false}
										action={process.env.REACT_APP_API_URL + '/api/upload/product/' + params.id}
										headers={{ Authorization: 'Bearer ' + localStorage.getItem('token') }}
										beforeUpload={handleBeforeFileUpload}
										onChange={handleFileUploadChange}>
										{product.prdPhoto ? null : uploadButton}
									</Upload>
								</Card>
								<Card style={{ marginTop: '16px' }}>
									<Form.Item label='Leírás' name='prdDescription'>
										<TextArea />
									</Form.Item>
								</Card>
								<Card title='Beszerzési rendelések' style={{ marginTop: 16 }}>
									<Table
										pagination={false}
										columns={tableColumnsPurchaseOrders}
										dataSource={
											product.purchaseOrders &&
											product.purchaseOrders
												.sort((a, b) => {
													if (a.createdAt > b.createdAt) {
														return 1;
													}
													if (a.createdAt < b.createdAt) {
														return -1;
													}
													return 0;
												})
												.reverse()
										}
										size='middle'
									/>
								</Card>
								{/* 								<Card title='Webshop galéria' style={{ marginTop: 16 }}>
									<Upload
										name='file'
										listType='picture-card'
										multiple={true}
										className='avatar-uploader'
										showUploadList={false}
										action={process.env.REACT_APP_API_URL + '/api/upload/product/webshop-gallery/' + product.prdWebshopProductId}
										headers={{ Authorization: 'Bearer ' + localStorage.getItem('token') }}
										beforeUpload={handleBeforeFileUpload}
										onChange={handleFileUploadChange}>
										{uploadButton}
									</Upload>
									{webshopGallery?.map(image => {
										return <img src={image} style={{width: '100%', marginBottom: '8px', border: '1px solid #666666'}}/>
									})}
								</Card> */}
							</Col>
							<Col xs={24} lg={props.store.auth.user.userRole.accessLevel > 29 ? 18 : 13}>
								<Card title='Alapadatok'>
									<Form.Item
										label='Termékkód'
										name='prdCode'
										rules={[
											{
												required: true,
												message: 'Kötelező mező',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										label='Termék név'
										name='prdName'
										rules={[
											{
												required: true,
												message: 'Kötelező mező',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										label='Beszállító'
										name='vendorId'
										rules={[
											{
												required: true,
												message: 'Kötelező mező',
											},
										]}>
										<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
											{listVendor.map((vendor) => {
												return (
													<Option key={vendor.id} value={vendor.id}>
														{vendor.companyName}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label='Másodlagos beszállító' name='prdSecondaryVendor'>
										<Input />
									</Form.Item>
									<Row gutter={16}>
										<Col xs={24} lg={12}>
											<Form.Item
												label='Elsődleges menny. egység'
												name='prdQtyUnit'
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={'db'}>db</Option>
													<Option value={'csomag'}>csomag</Option>
													<Option value={'karton'}>karton</Option>
													<Option value={'roll'}>roll</Option>
													<Option value={'kg'}>kg</Option>
													<Option value={'m2'}>m2</Option>
													<Option value={'m3'}>m3</Option>
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item label='Másodlagos menny. egység' name='prdQtyUnit2'>
												<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={'db'}>db</Option>
													<Option value={'csomag'}>csomag</Option>
													<Option value={'karton'}>karton</Option>
													<Option value={'roll'}>roll</Option>
													<Option value={'kg'}>kg</Option>
													<Option value={'m2'}>m2</Option>
													<Option value={'m3'}>m3</Option>
												</Select>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} lg={8}>
											<Form.Item label='Elsődleges csomagolás' name='prdPrimaryPackage'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Másodlagos csomagolás' name='prdSecondaryPackage'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Harmadlagos csomagolás' name='prdTertiaryPackage'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} lg={8}>
											<Form.Item label='Anyag 1' name='prdMaterial1'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Anyag 2' name='prdMaterial2'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Anyag 3' name='prdMaterial3'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} lg={6}>
											<Form.Item label='Súly (kg)' name='prdWeight'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={6}>
											<Form.Item label='Térfogat (m3)' name='prdVolume'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={6}>
											<Form.Item label='Mikron' name='prdMicron'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={6}>
											<Form.Item label='Szín' name='prdColor'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={16}>
										<Col xs={24} lg={12}>
											<Form.Item label='Nettó lista egységár (Ft) - termékdíjjal' name='prdNetPrice'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item label='Nettó lista egységár (Ft) - termékdíj nélkül' name='prdRawNetPrice'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
									</Row>
									{props.store.auth.user.userRole.accessLevel > 25 ? null : (
										<Row gutter={16}>
											<Col xs={24} lg={12}>
												<Form.Item label='Utolsó beszerzési ár (Ft)' name='prdLastPrice'>
													<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
												</Form.Item>
											</Col>
											<Col xs={24} lg={12}>
												<Form.Item label='Utolsó beszerzési ár B raktár (Ft)' name='prdLastPriceB'>
													<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
												</Form.Item>
											</Col>
											<Col xs={24} lg={12}>
												<Form.Item label='Termékdíj' name='prdNetPrice'>
													<Text strong>{(product.prdNetPrice - product.prdRawNetPrice).toFixed(2)} Ft</Text>
												</Form.Item>
											</Col>
										</Row>
									)}
								</Card>

								<Card title='Webshop' style={{ marginTop: 16 }}>
									<Row gutter={16}>
										<Col xs={24} lg={12}>
											<Form.Item
												label='Termék kód'
												name='prdWebshopCode'
												rules={[
													{
														required: product.prdIsWebshopProduct ? true : false,
														message: 'Kötelező mező',
													},
												]}>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item
												label='Név'
												name='prdWebshopName'
												rules={[
													{
														required: product.prdIsWebshopProduct ? true : false,
														message: 'Kötelező mező',
													},
												]}>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										{/* 										<Col xs={24} lg={24}>
											<Form.Item label='Rövid leírás' name='prdWebshopShortDescription'>
												<TextArea disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={24}>
											<Form.Item label='Leírás' name='prdWebshopDescription'>
												<TextArea disabled={props.store.auth.user.userRole.accessLevel > 29} />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Nettó ár (Ft)' name='prdWebshopNetPrice'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Súly (kg)' name='prdWebshopWeight'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Hossz (cm)' name='prdWebshopHeight'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Szélesség (cm)' name='prdWebshopWidth'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={8}>
											<Form.Item label='Mélység (cm)' name='prdWebshopLength'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' step='any' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item label='Legkisebb rendelhető mennyiség' name='prdWebshopMinimalOrderNumber'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={12}>
											<Form.Item label='Legnagyobb rendelhető mennyiség' name='prdWebshopMaximalOrderNumber'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='number' />
											</Form.Item>
										</Col>
										<Col xs={24} lg={24}>
											<Form.Item label='Kategória 1' name='prdWebshopCategoryId'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId}
													optionFilterProp='value'
													disabled={props.store.auth.user.userRole.accessLevel > 29}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listShoprenterCategory.map((category) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={24}>
											<Form.Item label='Kategória 2' name='prdWebshopCategoryId2'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId2}
													optionFilterProp='value'
													disabled={props.store.auth.user.userRole.accessLevel > 29}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listShoprenterCategory.map((category) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>

										<Col xs={24} lg={24}>
											<Form.Item label='Kategória 3' name='prdWebshopCategoryId3'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId3}
													optionFilterProp='value'
													disabled={props.store.auth.user.userRole.accessLevel > 29}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listShoprenterCategory.map((category) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>

										<Col xs={24} lg={24}>
											<Form.Item label='Kategória 4 ' name='prdWebshopCategoryId4'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId4}
													optionFilterProp='value'
													disabled={props.store.auth.user.userRole.accessLevel > 29}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listShoprenterCategory.map((category) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>

										<Col xs={24} lg={24}>
											<Form.Item label='Kategória 5' name='prdWebshopCategoryId5'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId5}
													optionFilterProp='value'
													disabled={props.store.auth.user.userRole.accessLevel > 29}
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listShoprenterCategory.map((category) => {
														return (
															<Option key={category.id} value={category.id}>
																{category.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col> */}
									</Row>
								</Card>

								<Card title='További beszállítók' style={{ marginTop: 16 }}>
									<Row gutter={[16, 16]}>
										<Col xs={12}>
											<Form.Item label='Beszállító' name='prdSecondaryVendorId'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listVendor.map((vendor) => {
														return (
															<Option key={vendor.id} value={vendor.id}>
																{vendor.companyName}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item label='Terméknév' name='prdSecondaryVendorName'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='text' step='any' />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col xs={12}>
											<Form.Item label='Beszállító' name='prdSecondaryVendorId2'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId2}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listVendor.map((vendor) => {
														return (
															<Option key={vendor.id} value={vendor.id}>
																{vendor.companyName}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item label='Terméknév' name='prdSecondaryVendorName2'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='text' step='any' />
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={[16, 16]}>
										<Col xs={12}>
											<Form.Item label='Beszállító' name='prdSecondaryVendorId3'>
												<Select
													showSearch
													defaultValue={product.prdSecondaryVendorId3}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													<Option value={null}>{'-'}</Option>
													{listVendor.map((vendor) => {
														return (
															<Option key={vendor.id} value={vendor.id}>
																{vendor.companyName}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item label='Terméknév' name='prdSecondaryVendorName3'>
												<Input disabled={props.store.auth.user.userRole.accessLevel > 29} type='text' step='any' />
											</Form.Item>
										</Col>
									</Row>
								</Card>
								{props.store.auth.user.userRole.accessLevel > 29 ? null : (
									<Card title='Raktárkészlet' style={{ marginTop: 16 }}>
										<Table dataSource={product.warehouses} columns={tableColumns} pagination={{ defaultPageSize: 100 }} size='small' />
									</Card>
								)}
							</Col>
							{props.store.auth.user.userRole.accessLevel > 29 ? null : (
								<Col xs={24} lg={5}>
									<Card title='Beállítások'>
										<Form.Item>
											<Row justify='space-between' align='middle' style={{ width: '100%' }}>
												<Col xs={24} lg={12}>
													<Text>Státusz</Text>
												</Col>
												<Col xs={24} lg={12} style={{ textAlign: 'right' }}>
													<Switch checkedChildren='Aktív' unCheckedChildren='Inaktív' defaultChecked={product.prdStatus === 1 ? true : false} onChange={handleStatusSwitch} />
												</Col>
											</Row>
										</Form.Item>
										<Form.Item>
											<Row justify='space-between' align='middle' style={{ width: '100%' }}>
												<Col xs={24} lg={12}>
													<Text>Szállítóleveles?</Text>
												</Col>
												<Col xs={24} lg={12} style={{ textAlign: 'right' }}>
													<Switch checkedChildren='Igen' unCheckedChildren='Nem' defaultChecked={product.prdBillingType === 1 ? true : false} onChange={handleBillingTypeSwitch} />
												</Col>
											</Row>
										</Form.Item>
										<Form.Item>
											<Row justify='space-between' align='middle' style={{ width: '100%' }}>
												<Col xs={24} lg={12}>
													<Text>Termékdíj nélkül</Text>
												</Col>
												<Col xs={24} lg={12} style={{ textAlign: 'right' }}>
													<Switch
														checkedChildren='Igen'
														unCheckedChildren='Nem'
														defaultChecked={product.prdMinusProductPrice === 1 ? true : false}
														onChange={handleMinusProductPriceTypeSwitch}
													/>
												</Col>
											</Row>
										</Form.Item>
										<Form.Item name='prdIsWebshopProduct' valuePropName='checked'>
											<Checkbox>Webshop termék</Checkbox>
										</Form.Item>
									</Card>
									<Card title='Termékkategória' style={{ marginTop: '16px' }}>
										<Form.Item name='productCategoryId'>
											<Select
												showSearch
												defaultValue={product.productCategoryId}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listProductCategory.map((category) => {
													return (
														<Option key={category.id} value={category.id}>
															{category.name}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Card>
									<Card title='Kizárólagos vásárlók' style={{ marginTop: '16px' }}>
										<Form.Item name='prdExclusiveCustomerId'>
											<Select
												showSearch
												defaultValue={product.prdExclusiveCustomerId}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCustomer.map((customer) => {
													return (
														<Option key={customer.id} value={customer.id}>
															{customer.companyName}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item name='prdExclusiveCustomerId2'>
											<Select
												showSearch
												defaultValue={product.prdExclusiveCustomerId2}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCustomer.map((customer) => {
													return (
														<Option key={customer.id} value={customer.id}>
															{customer.companyName}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item name='prdExclusiveCustomerId3'>
											<Select
												showSearch
												defaultValue={product.prdExclusiveCustomerId3}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCustomer.map((customer) => {
													return (
														<Option key={customer.id} value={customer.id}>
															{customer.companyName}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item name='prdExclusiveCustomerId4'>
											<Select
												showSearch
												defaultValue={product.prdExclusiveCustomerId4}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCustomer.map((customer) => {
													return (
														<Option key={customer.id} value={customer.id}>
															{customer.companyName}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Form.Item name='prdExclusiveCustomerId5'>
											<Select
												showSearch
												defaultValue={product.prdExclusiveCustomerId5}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listCustomer.map((customer) => {
													return (
														<Option key={customer.id} value={customer.id}>
															{customer.companyName}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
										<Button
											block
											onClick={() =>
												productForm.setFieldsValue({
													prdExclusiveCustomerId: null,
													prdExclusiveCustomerId2: null,
													prdExclusiveCustomerId3: null,
													prdExclusiveCustomerId4: null,
													prdExclusiveCustomerId5: null,
												})
											}>
											Törlés
										</Button>
									</Card>
									<Card title='Ügyfélkategóriák' style={{ marginTop: '16px' }}>
										{customerCategory.map((item) => {
											let customerCategoryExist = typeof product.customerCategories === 'undefined' ? false : true;
											return (
												<>
													<Form.Item>
														<Row justify='space-between' align='middle' style={{ width: '100%' }}>
															<Col xs={24} lg={12}>
																<Text>{item.name}</Text>
															</Col>
															<Col xs={24} lg={12} style={{ textAlign: 'right' }}>
																<Switch
																	key={item.id}
																	name={item.id}
																	defaultChecked={customerCategoryExist ? (product.customerCategories.find((category) => category.id === item.id) ? true : false) : false}
																	onChange={handleCustomerCategorySwitch}
																/>
															</Col>
														</Row>
													</Form.Item>
												</>
											);
										})}
									</Card>
								</Col>
							)}
						</Row>
					</Form>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Product, pagePath));
