import { notification } from 'antd';

const openNotificationWithIcon = (type, title, description, duration) => {
  notification[type]({
    message: title,
    description: description,
    duration: duration,
    placement: 'bottomRight'
  });
};

export default openNotificationWithIcon;