import React, { useState, useEffect } from 'react';
import styles from './SearchBar.module.scss';
import { DebounceInput } from 'react-debounce-input';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import {nanoid} from 'nanoid';

import { Row, Col, Form, Select } from 'antd';
const { Option } = Select;

const SearchBar = (props) => {
	const [form] = Form.useForm();
	const [lists, setLists] = useState({});

	const colSpan = () => {
		switch (props.searchFields.length) {
			case 1:
				return 24 / 1;
			case 2:
				return 24 / 2;
			case 4:
				return 24 / 4;
			case 8:
				return 24 / 4;
			case 12:
				return 24 / 4;
			case 16:
				return 24 / 4;
			default:
				return 24 / 3;
		}
	};

	useEffect(() => {
		if (props.lists) {
			server
				.post(`/list/searchbar`, { lists: props.lists })
				.then((response) => {
					setLists(response.data);
				})
				.catch((error) => {
					errorHandler(error);
				});
		}
	}, []);

	const handleSearch = (e, key) => {
		let searchString =  typeof e.target.value === 'string' ? e.target.value.toLowerCase() : e.target.value;

		props.setLoading({ ...props.loading, data: true });

		if (searchString.length == 0) {
			props.action({
				limit: props.defaultPageSize,
				offset: '',
				search: {
					...props.queryParams.search,
					[key]: searchString,
				},
			});
		} else {
			props.action({
				limit: '',
				offset: '',
				search: {
					...props.queryParams.search,
					[key]: searchString,
				},
			});
		}
	};

	const renderContent = (field) => {
		switch (true) {
			case field.type === 'dropdown':
				return (
					<Select
						showSearch
						optionFilterProp='children'
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						placeholder={field.name}
						style={{ width: '100%' }}
						onChange={(value, e) => handleSearch({ target: { value: e.value } }, field.key)}>
						<Option key={0} value=''>
							{`${field.name}: összes`}
						</Option>
						{lists[field.list] &&
							lists[field.list].map((item) => {
								return (
									<Option key={nanoid()} value={item[field.value]}>
										{item[field.text]}
									</Option>
								);
							})}
					</Select>
				);
			default:
				return <DebounceInput debounceTimeout={500} placeholder={field.name} onChange={(e) => handleSearch(e, field.key)} className='ant-input' />;
		}
	};

	return (
		<Form key={'form1'} form={form}>
			<Row className={styles.SearchBar} gutter={[8, 8]}>
				{props.searchFields.map((field) => {
					return (
						<Col xs={24} lg={colSpan()} key={field.key}>
							<Form.Item name={field.key} noStyle>
								{/* 	<Input placeholder={field.name} onChange={(e) => handleSearch(e, field.key)} /> */}
								{renderContent(field)}
							</Form.Item>
						</Col>
					);
				})}
			</Row>
		</Form>
	);
};

export default SearchBar;
