import server from '../../utils/axios/api';
import serverAuth from '../../utils/axios/auth';
import errorHandler from '../../utils/error-handler';

/*
----- Action names
*/

export const INITIALIZE_APP = 'INITIALIZE_APP';
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export const SET_LOADING = 'SET_LOADING';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';

/*
----- Action creators
*/

// System

export const initialize_app = (params) => {
	return (dispatch) => {
		let systemSettings;
		let pageDetails;
		server
			.get('/settings')
			.then((response) => {
				systemSettings = response.data;
				server
				.get('/page-setting')
				.then((response) => {
					pageDetails = response.data
					dispatch(finalize_initialize_app(systemSettings, pageDetails));
				})
				.catch((error) => {
					errorHandler(error);
				});
				
			})
			.catch((error) => {});
	};
};

export const finalize_initialize_app = (systemSettings, pageDetails) => {
	return {
		type: INITIALIZE_APP,
		systemSettings: systemSettings,
		pageDetails: pageDetails
	};
};

// UI

export const set_loading = (params) => {
	return {
		type: SET_LOADING,
		params: params,
	};
};

// Sidebar

export const set_sidebar_state = (params) => {
	return {
		type: SET_SIDEBAR_STATE,
		params: params,
	};
};

// Auth

export const set_user = (params) => {
	return {
		type: SET_USER,
		params: params,
	};
};

export const login = (params, token) => {
	return {
		type: LOGIN,
		params: params,
		token: token
	};
};

export const logout = (params) => {
	localStorage.removeItem('token');
	return {
		type: LOGOUT,
		params: params,
	};
};