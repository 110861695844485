import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Products.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import notification from '../../utils/notification/notification';
import fileDownload from 'js-file-download';
import moment from 'moment';

import PageGuard from '../../components/PageGuard/PageGuard';
import SearchBar from '../../components/SearchBar/SearchBar';

import NewProductModal from '../../components/Modal/NewProductModal/NewProductModal';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Badge } from 'antd';
const { Text } = Typography;

const pagePath = 'products';
const defaultPageSize = 50;

const searchData = {
	searchFields: [
		{ key: 'prdName', name: 'Terméknév' },
		{ key: 'prdCode', name: 'Termékkód' },
		{ key: 'productCategoryId', name: 'Kategória', type: 'dropdown', list: 'productCategory', value: 'id', text: 'name' },
	],
	lists: ['productCategory'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const Products = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [products, setProducts] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchData.searchValues },
	});
	const [loading, setLoading] = useState({
		search: false,
		export: false
	});

	let history = useHistory();
	const params = useParams();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getProducts();
	}, [params.status, params.type, queryParams]);

	const getProducts = () => {
		server
			.get(
				`/product/all/${params.type}?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${JSON.stringify(queryParams.search)}&fields=${JSON.stringify(searchData.searchFields)}&status=${
					params.status
				}`
			)
			.then((response) => {
				setProducts(response.data.rows);
				setTotal(response.data.count);
				setLoading({ ...loading, data: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleChangeStatus = (id, status, name) => {
		server
			.patch('/product/' + id, {
				prdStatus: status == 1 ? 0 : 1,
			})
			.then((response) => {
				notification('success', 'Termék státusz sikeresen frissítve: ' + name, 'A termékek listájának újratöltése és az új státusz megjelnítése pár másodpercet igénybe vehet.');
				getProducts();
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			products.map((product) => {
				if (list.find((item) => item.value == product[column1][column2])) {
					return null;
				} else {
					return list.push({ text: product[column1][column2], value: product[column1][column2] });
				}
			});
		} else {
			products.map((product) => {
				if (list.find((item) => item.value == product[column1])) {
					return null;
				} else {
					return list.push({ text: product[column1], value: product[column1] });
				}
			});
		}
		return list;
	};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const handleClick = (id) => {
		window.open('/product/' + id);
	};

	const handleExportClick = (format) => {
		setLoading({ ...loading, export: true });
		switch (format) {
			default:
				server
					.get(`/worker/export/products/${format}`)
					.then((response) => {
						console.log(response);
						fileDownload(response.data, `products_${moment().format('YYYY-MM-DD_HH-mm-ss')}.${format}`);
						setLoading({ ...loading, export: false });
					})
					.catch((error) => {
						errorHandler(error);
					});
		}
	};

	const tableColumns = [
		{
			title: 'Termékkód',
			render: (record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)}>
					{record.prdCode}
				</Text>
			),
			sorter: (a, b) => {
				if (a.prdCode < b.prdCode) {
					return -1;
				}
				if (a.prdCode > b.prdCode) {
					return 1;
				}
			},
		},
		{
			title: 'Név',
			render: (record) => (
				<>
					<Text strong style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)}>
						{record.prdName}
					</Text>
					<br />
					<Text type='secondary' style={{ fontSize: '12px' }}>
						{record.vendor ? record.vendor.companyName : null}
					</Text>
				</>
			),
			sorter: (a, b) => {
				if (a.prdName < b.prdName) {
					return -1;
				}
				if (a.prdName > b.prdName) {
					return 1;
				}
			},
		},
		{
			title: 'Termékcsoport',
			align: 'left',
			render: (record) => <Text>{record.productCategory && record.productCategory.name}</Text>,
			sorter: (a, b) => {
				if (a.prdQtyUnit < b.prdQtyUnit) {
					return -1;
				}
				if (a.prdQtyUnit > b.prdQtyUnit) {
					return 1;
				}
			},
		},
		{
			title: 'Egység',
			align: 'center',
			render: (record) => <Text>{record.prdQtyUnit}</Text>,
			sorter: (a, b) => {
				if (a.prdQtyUnit < b.prdQtyUnit) {
					return -1;
				}
				if (a.prdQtyUnit > b.prdQtyUnit) {
					return 1;
				}
			},
		},
		{
			title: 'Nettó lista EÁ',
			align: 'center',
			render: (record) => <Text>{record.prdNetPrice === null ? (0).toFixed(2) : record.prdNetPrice.toFixed(2)} Ft</Text>,
			sorter: (a, b) => {
				if (a.prdNetPrice < b.prdNetPrice) {
					return -1;
				}
				if (a.prdNetPrice > b.prdNetPrice) {
					return 1;
				}
			},
		},
		{
			title: 'Utolsó besz. ár',
			align: 'center',
			render: (record) => {
				if (props.store.auth.user.userRole.accessLevel <= 25) {
					return <Text>{record.prdLastPrice === null ? (0).toFixed(2) : record.prdLastPrice.toFixed(2)} Ft</Text>;
				} else {
					return <Text>-</Text>;
				}
			},
			sorter: (a, b) => {
				if (a.prdLastPrice < b.prdLastPrice) {
					return -1;
				}
				if (a.prdLastPrice > b.prdLastPrice) {
					return 1;
				}
			},
			responsive: ['lg'],
		},
		{
			title: 'Státusz',
			align: 'center',
			render: (record) => (
				<Badge
					style={record.prdStatus === 1 ? { backgroundColor: '#00a838', cursor: 'pointer' } : { cursor: 'pointer' }}
					count={record.prdStatus === 1 ? 'Aktív' : 'Inaktív'}
					onClick={() => handleChangeStatus(record.id, record.prdStatus, record.prdName)}
				/>
			),
			sorter: (a, b) => {
				if (a.prdStatus < b.prdStatus) {
					return -1;
				}
				if (a.prdStatus > b.prdStatus) {
					return 1;
				}
			},
		},
	];

	return (
		<PageGuard path={pagePath} data={products}>
			<div className={styles.Products}>
				<NewProductModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getProducts={getProducts} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={`${pageDetails.title} (${params.status == 1 ? 'aktív' : 'inaktív'})`}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='11' onClick={() => handleExportClick('xls')} loading={loading.export} disabled={props.store.auth.user.userRole.key === 'sales'}>
							Export XLS
						</Button>,
						<Button key='12' onClick={() => handleExportClick('csv')} loading={loading.export} disabled={props.store.auth.user.userRole.key === 'sales'}>
							Export CSV
						</Button>,
						<Button key='1' type='primary' onClick={() => setModalVisible(true)}>
							Új termék
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<SearchBar
								defaultPageSize={defaultPageSize}
								queryParams={queryParams}
								action={setQueryParams}
								searchFields={searchData.searchFields}
								lists={searchData.lists}
								loading={loading}
								setLoading={setLoading}
							/>
						</Col>
						<Col span={24}>
							<Card>
								<Table
									dataSource={products}
									columns={tableColumns}
									size='small'
									pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize }}
									loading={loading.data}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Products, pagePath));
