import React from 'react';
import styles from './Auth.module.scss';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import serverAuth from '../../utils/axios/auth';
import errorHandler from '../../utils/error-handler';

import { Row, Col, Form, Input, Button, Checkbox } from 'antd';

import logo from '../../assets/img/logo.png';

const Auth = (props) => {
	let history = useHistory();

	const onFinish = (values) => {
		serverAuth
			.post('/login', values)
			.then((response) => {
				let token = response.data.token;
				localStorage.setItem('token', token);
				serverAuth
					.get('/user', {
						headers: {
							Authorization: 'Bearer ' + token,
						},
					})
					.then((response) => {
						props.login(response.data, token);
						history.push('/dashboard');
						props.initializeApp();
						window.location.reload();
					})
					.catch((error) => {});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div className={styles.Auth}>
			<Row align='middle'>
				<Col span={16} className={styles.left}></Col>
				<Col span={8} className={styles.right}>
					<Row justify='center'>
						<Col className={styles.logoContainer}>
							<img src={logo} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col className={styles.loginContainer}>
							<Form
								name='basic'
								initialValues={{
									remember: true,
								}}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}>
								<Form.Item
									name='username'
									rules={[
										{
											required: true,
											message: 'Kötelező mező',
										},
									]}>
									<Input placeholder='Felhasználónév' size='large' />
								</Form.Item>

								<Form.Item
									name='password'
									rules={[
										{
											required: true,
											message: 'Kötelező mező',
										},
									]}>
									<Input.Password placeholder='Jelszó' size='large' />
								</Form.Item>
								<Form.Item name='remember' valuePropName='checked'>
									<Checkbox>Emlékezz rám 2 hétig</Checkbox>
								</Form.Item>
								<Form.Item className={styles.submitButton}>
									<Button type='primary' htmlType='submit' size='large' block>
										Bejelentkezés
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (params, token) => {
			dispatch(actionCreators.login(params, token));
		},
		initializeApp: (params) => {
			dispatch(actionCreators.initialize_app(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Auth);
