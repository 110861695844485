import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './ProductTransferComponent.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import notification from '../../utils/notification/notification';

import { Button, Row, Col, Transfer, Table, Input, Select, Typography } from 'antd';
import difference from 'lodash/difference';
const { Option } = Select;
const { Title } = Typography;

const ProductTransferComponent = (props) => {
	const [warehouseItems, setWarehouseItems] = useState([]);
	const [listWarehouse, setListWarehouse] = useState([]);
	const [productListLeft, setProductListLeft] = useState([]);
	const [productListRight, setProductListRight] = useState([]);
	const [productListFinal, setProductListFinal] = useState([]);
	const [checkList, setCheckList] = useState([]);
	const [moveFrom, setMoveFrom] = useState(1);
	const [moveTo, setMoveTo] = useState();
	const [loading, setLoading] = useState({
		save: false,
	});
	const [leftTitle, setLeftTitle] = useState('Raktár');
	const [rightTitle, setRightTitle] = useState('Autó');

	let params = useParams();

	useEffect(() => {
		console.log(checkList);
	}, [checkList]);

	useEffect(() => {
		getWarehouseItems();
	}, [moveFrom]);

	useEffect(() => {
		getWarehouses();
	}, [params.id]);

	useEffect(() => {
		if (props.store.auth.user.userRole.key === 'sales') {
			setMoveTo(props.store.auth.user.warehouse.id);
		}
	}, [props.store.auth.user]);

	const getWarehouses = () => {
		server
			.get('/list/warehouse')
			.then((response) => {
				setListWarehouse(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getWarehouseItems = () => {
		server
			.get('/product/all/nonwebshop?status=1')
			.then((response) => {
				setWarehouseItems(response.data.rows.filter((product) => product.warehouses.length > 0));
				setProductListLeft(response.data.rows.filter((product) => product.warehouses.find((warehouse) => warehouse.id === moveFrom) && product.prdStatus === 1));
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
		<Transfer {...restProps} showSelectAll={false}>
			{({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
				const columns = direction === 'left' ? leftColumns : rightColumns;

				const rowSelection = {
					getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
					onSelectAll(selected, selectedRows) {
						const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
						const diffKeys = selected ? difference(treeSelectedKeys, listSelectedKeys) : difference(listSelectedKeys, treeSelectedKeys);
						onItemSelectAll(diffKeys, selected);
					},
					onSelect({ key }, selected) {
						onItemSelect(key, selected);
					},
					selectedRowKeys: listSelectedKeys,
				};

				return (
					<Table
						rowSelection={rowSelection}
						columns={columns}
						dataSource={filteredItems}
						pagination={{ pageSize: 100 }}
						size='small'
						style={{ pointerEvents: listDisabled ? 'none' : null }}
						onRow={({ key, disabled: itemDisabled }) => ({
							onClick: () => {
								if (itemDisabled || listDisabled) return;
								onItemSelect(key, !listSelectedKeys.includes(key));
							},
						})}
					/>
				);
			}}
		</Transfer>
	);

	const leftTableColumns = [
		{
			dataIndex: 'prdCode',
			title: 'Termékkód',
		},
		{
			dataIndex: 'prdName',
			title: 'Név',
		},
		{
			title: 'Készlet',
			align: 'center',
			render: (record) => record.warehouses.filter((warehouse) => warehouse.id === moveFrom).reduce((a, b) => a + parseFloat(b.warehouseItem.prdQty), 0),
		},
	];
	const rightTableColumns = [
		{
			dataIndex: 'prdCode',
			title: 'Termékkód',
		},
		{
			dataIndex: 'prdName',
			title: 'Név',
		},
		{
			title: 'Mennyiség',
			render: (record) => {
				return (
					<Input
						size='small'
						style={{ width: '100px', backgroundColor: checkList.includes(+record.id) ? '#B6FCD550' : '#df555850' }}
						defaultValue={productListFinal.find((item) => item.key === record.key).qty === 0 ? null : productListFinal.find((item) => item.key === record.key).qty}
						onPressEnter={(e) => handlePriceInput(record.key, e.currentTarget.value)}
						placeholder={productListFinal.find((item) => item.key === record.key).stockQty}
						type='number'
						step='any'
					/>
				);
			},
		},
	];

	const onChange = (nextTargetKeys) => {
		let modifiedInput = [];
		nextTargetKeys.map((item) => {
			let currentProduct = productListLeft.find((product) => product.key == item);
			let currentProductStockQty = currentProduct.warehouses.filter((warehouse) => warehouse.id === moveFrom).reduce((a, b) => a + parseFloat(b.warehouseItem.prdQty), 0);
			modifiedInput.push({ key: item, qty: 0, stockQty: currentProductStockQty });
		});
		setProductListRight(nextTargetKeys);
		setProductListFinal(modifiedInput);
	};

	const handlePriceInput = (key, newQty) => {
		let currentIndex = productListFinal.findIndex((item) => item.key === key);
		let newList = [...productListFinal];
		newList[currentIndex] = { ...newList[currentIndex], qty: parseFloat(newQty) };
		setProductListFinal(newList);
		setCheckList([...checkList, +key]);
	};

	const handleTransferSave = () => {
		setLoading({ save: true });
		server
			.post('/worker/product-transfer/process', { productListFinal, moveFrom, moveTo, user: props.store.auth.user })
			.then((response) => {
				setLoading({ save: false });
				props.updateWarehouse('');
				notification('success', 'Mozgatás végrehajtva');
				setMoveFrom(moveFrom);
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ save: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleChangeTransferDirection = () => {
		setLeftTitle(rightTitle);
		setRightTitle(leftTitle);
		setMoveFrom(moveTo);
		setMoveTo(moveFrom);
	};

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col span={props.store.auth.user.userRole.key === 'sales' ? 12 : 24} style={{ textAlign: 'right' }}>
					<Button type='primary' onClick={handleTransferSave} loading={loading.save} size='large'>
						Mozgatás
					</Button>
				</Col>
				{props.store.auth.user.userRole.key === 'sales' ? (
					<>
						<Col span={12}>
							<Title level={2} type={leftTitle === 'Raktár' ? 'success' : 'danger'}>
								{leftTitle}
							</Title>
						</Col>
						<Col span={12}>
							<Title level={2} type={leftTitle === 'Raktár' ? 'danger' : 'success'}>
								{rightTitle}
							</Title>
						</Col>
					</>
				) : (
					<>
						<Col span={12}>
							<Select
								showSearch
								placeholder='Honnan'
								style={{ width: '100%' }}
								optionFilterProp='children'
								onChange={(value) => setMoveFrom(value)}
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{listWarehouse.map((warehouse) => {
									return (
										<Option value={warehouse.id} key={warehouse.id}>
											{warehouse.wrhName}
										</Option>
									);
								})}
							</Select>
						</Col>
						<Col span={12}>
							<Select
								showSearch
								placeholder='Hova'
								style={{ width: '100%' }}
								optionFilterProp='children'
								onChange={(value) => setMoveTo(value)}
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{listWarehouse.map((warehouse) => {
									return (
										<Option value={warehouse.id} key={warehouse.id}>
											{warehouse.wrhName}
										</Option>
									);
								})}
							</Select>
						</Col>
					</>
				)}

				<Col span={24} hidden={moveFrom && moveTo ? false : true}>
					<TableTransfer
						dataSource={productListLeft}
						targetKeys={productListRight}
						disabled={false}
						showSearch={true}
						onChange={onChange}
						filterOption={(inputValue, item) =>
							item.prdCode.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 || item.prdName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
						}
						leftColumns={leftTableColumns}
						rightColumns={rightTableColumns}
						locale={{
							itemUnit: 'termék',
							itemsUnit: 'termék',
							searchPlaceholder: 'Keresés...',
							notFoundContent: 'Nincs találat',
							selectInvert: 'Kijelölés megfordítása',
							selectAll: 'Összes kijelölése',
						}}
					/>
				</Col>
			</Row>
			<Row justify='between'>
				{props.store.auth.user.userRole.key === 'sales' ? (
					<Col span={12}>
						<Button onClick={handleChangeTransferDirection} size='large' danger>
							Berakodás / kirakodás
						</Button>
					</Col>
				) : null}

				<Col span={props.store.auth.user.userRole.key === 'sales' ? 12 : 24} style={{ textAlign: 'right' }}>
					<Button type='primary' onClick={handleTransferSave} loading={loading.save} size='large'>
						Mozgatás
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default ProductTransferComponent;
