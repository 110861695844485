import React, { useState, useEffect } from 'react';
import styles from './NewWarehouseModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Input, Select } from 'antd';
const { Option } = Select;

const NewWarehouseModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.post('/warehouse', {...values, userId: props.store.auth.user.id})
			.then((response) => {
				form.resetFields();
				setLoading(false);
				props.toggle();
				props.getWarehouses();
				notification('success', 'Raktár sikeresen létrehozva.', response.data.ordNo);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewWarehouseModal}
			title='Új raktár létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item label='Név' name='wrhName' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Típus' name='wrhType' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						<Option key='1' value={1}>
							Épület
						</Option>
						<Option key='2' value={2}>
							Autó
						</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NewWarehouseModal;
