import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Forbidden from '../../pages/Auth/Forbidden/Forbidden';

const PageGuard = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [authorized, setAuthorized] = useState(false);

	useEffect(() => {
		setPageDetails(typeof props.store.system.pageDetails == 'undefined' ? {} : props.store.system.pageDetails.find((page) => page.path == props.path));
	}, [props.store.system.pageDetails]);

	// check permissions
	useEffect(() => {
		let checks = [];
		if (props.user.userRole.accessLevel > 20) {
			if (typeof pageDetails !== 'undefined') {
				// access level
				if (pageDetails.accessLevel < props.user.userRole.accessLevel) {
					checks.push(false);
				}
				// record owner
				if (pageDetails.isPrivate && pageDetails.type === 2 && props.user.userRole.accessLevel > 29) {
					if (typeof props.data.userId !== 'undefined' && props.data.userId !== props.user.id) {
						checks.push(false);
					}
				}
				// user page
				if (props.userPage) {
					if (typeof props.data.id !== 'undefined' && props.data.id !== props.user.id) {
						checks.push(false);
					}
				}
			}
		}
		setAuthorized(!checks.includes(false));
	}, [pageDetails, props.data, props.user]);

	useEffect(() => {
		renderPage();
	}, [authorized]);

	const renderPage = () => {
		if (authorized) {
			return <>{props.children}</>;
		} else {
			return <Forbidden />;
		}
	};

	return renderPage();
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
		user: state.general.auth.user,
	};
};

export default connect(mapStateToProps)(PageGuard);
