import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import hoc from '../../hoc/PageContainer';
import styles from './Users.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';

import NewUserModal from '../../components/Modal/NewUserModal/NewUserModal';

import { PageHeader, Button, Row, Col, Card, Typography, Table, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Text } = Typography;

const pagePath = 'users';

const Users = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [users, setUsers] = useState([]);

	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = () => {
		server
			.get('/user')
			.then((response) => {
				let filteredData = response.data.filter((item) => item.userRole.key !== 'root');
				setUsers(filteredData);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const tableColumns = [
		{
			width: '50px',
			render: (record) => <Avatar size={45} src={props.store.system.filesPath + '/users/' + record.photo} icon={<UserOutlined />} />,
		},
		{
			title: 'Név',
			render: (record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => history.push('/user/' + record.id)} strong>
					{record.fullName}
				</Text>
			),
			sorter: (a, b) => {
				if (a.fullName < b.fullName) {
					return -1;
				}
				if (a.fullName > b.fullName) {
					return 1;
				}
			},
		},
		{
			title: 'Email',
			render: (record) => <Text>{record.email}</Text>,
			sorter: (a, b) => {
				if (a.email < b.email) {
					return -1;
				}
				if (a.email > b.email) {
					return 1;
				}
			},
		},
		{
			title: 'Jogosultsági szint',
			render: (record) => <Text>{record.userRole.name}</Text>,
			sorter: (a, b) => {
				if (a.userRole.name < b.userRole.name) {
					return -1;
				}
				if (a.userRole.name > b.userRole.name) {
					return 1;
				}
			},
		},
	];

	return (
		<div className={styles.Users}>
			<NewUserModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getUsers={getUsers} />
			<PageHeader
				className={styles.pageHeader}
				onBack={() => window.history.back()}
				title={pageDetails.title}
				subTitle={pageDetails.subtitle}
				extra={[
					<Button key='1' type='primary' onClick={() => setModalVisible(true)}>
						Új felhasználó
					</Button>,
				]}
			/>
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card>
							<Table dataSource={users} columns={tableColumns} pagination={{ defaultPageSize: 100 }} size='small' />
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(hoc(Users, pagePath));
