import React, { useState, useEffect } from 'react';
import styles from './NewPriceListModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Select } from 'antd';
const { Option } = Select;

const NewPriceListModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listCustomer, setListCustomer] = useState([]);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		server
			.get('/list/customer')
			.then((response) => {
				let filteredCustomer;
				if (props.store.auth.user.userRole.accessLevel <= 29) {
					filteredCustomer = response.data.filter((item) => item.status == 1);
				} else {
					filteredCustomer = response.data.filter((item) => item.userId === props.store.auth.user.id && item.status == 1);
				}
				setListCustomer(filteredCustomer);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.get('/price-list')
			.then((response) => {
				let checkCustomerExist = response.data.find((item) => item.customerId === values.customerId);
				if (checkCustomerExist) {
					notification('error', 'A választott ügyfélhez már létezik árlista.');
					setLoading(false);
				} else {
					server
						.post('/price-list', { ...values })
						.then((response) => {
							form.resetFields();
							setLoading(false);
							props.toggle();
							props.getPriceLists();
							notification('success', 'Árlista sikeresen létrehozva.');
						})
						.catch((error) => {
							errorHandler(error);
						});
				}
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		notification('error', 'A választott ügyfélhez már létezik árlista.');
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewOrderModal}
			title='Új árlista létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item label='Ügyfél' name='customerId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{listCustomer.map((customer) => {
							return (
								<Option key={customer.id} value={customer.id}>
									{customer.companyName}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NewPriceListModal;
