import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import styles from './AdminDashboardSummary.module.scss';
import moment from 'moment';

import warehouseBuilding from '../../../assets/img/warehouse_building.png';
import warehouseCar from '../../../assets/img/warehouse_car.png';

import DailySummary from '../DailySummary/DailySummary';
import MonthlySummary from '../MonthlySummary/MonthlySummary';
import YearlySummary from '../YearlySummary/YearlySummary';
import TopProducts from '../TopProducts/TopProducts';
import MonthlyCoverage from '../MonthlyCoverage/MonthlyCoverage';
import YearlyCoverage from '../YearlyCoverage/YearlyCoverage';
import { Row, Col, Card, DatePicker, Select, Statistic, Avatar, Typography } from 'antd';
const { Option } = Select;
const { Title } = Typography;
const { Meta } = Card;

const AdminDashboardSummary = (props) => {
	return (
		<div className={styles.AdminDashboardSummary}>
			<div className={styles.pageBody}>
				{props.store.auth.user.userRole.accessLevel <= 10 && (
					<>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={12}>
								<MonthlySummary listUser={props.listUser} listOrder={props.listOrder} />
							</Col>
							<Col xs={24} lg={12}>
								<YearlySummary listUser={props.listUser} listOrder={props.listOrder} />
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={12}>
								<MonthlyCoverage listTransfers={props.listTransfers} />
							</Col>
							<Col xs={24} lg={12}>
								<YearlyCoverage listTransfers={props.listTransfers} />
							</Col>
							{/* <Col xs={24} lg={12}>
						<TopProducts listTopProducts={props.listTopProducts} listProducts={props.listProducts}/> 
					</Col> */}
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<Title level={5}>Raktárkészlet értékek</Title>
							</Col>
							{props.listWarehouses.map((warehouse) => {
								return (
									<>
										<Col span={warehouse.wrhType === 1 ? 12 : 8} key={warehouse.id}>
											<Card>
												<Meta
													avatar={<Avatar src={warehouse.wrhType === 1 ? warehouseBuilding : warehouseCar} size={64} />}
													title={warehouse.wrhName}
													description={`${Math.round(warehouse.products.reduce((a, b) => a + (b.prdNetAvgPrice * b.warehouseItem.prdQty), 0)).toLocaleString('hu')} Ft`}
												/>
											</Card>
										</Col>
									</>
								);
							})}
						</Row>
					</>
				)}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(AdminDashboardSummary);
