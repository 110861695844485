import React, { useState, useEffect } from 'react';
import styles from './NewVendorModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Input, Row, Col } from 'antd';

const NewVendorModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const handleFormSubmit = (values) => {
		setLoading(true);
		server
			.post('/vendor', {
				...values,
				userId: props.store.auth.user.id,
				status: 1
			})
			.then((response) => {
				form.resetFields();
				setLoading(false);
				props.toggle();
				props.getVendors();
				notification('success', 'Beszállító sikeresen hozzáadva');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal title='Új beszállító létrehozás' visible={visible} onOk={handleOk} onCancel={handleCancel} cancelText='Mégse' okText='Mentés'>
			<Form form={form} layout='vertical' confirmLoading={loading} onFinish={handleFormSubmit} onFinishFailed={onFinishFailed}>
				<Form.Item
					label='Cégnév'
					name='companyName'
					rules={[
						{
							required: true,
							message: 'Kötelező mező',
						},
					]}>
					<Input />
				</Form.Item>
				<Form.Item
					label='Cím'
					name='billingAddress'
					rules={[
						{
							required: true,
							message: 'Kötelező mező',
						},
					]}>
					<Input />
				</Form.Item>
				<Row justify='space-between'>
					<Col span={7}>
						<Form.Item
							label='Irányítószám'
							name='billingPostalCode'
							rules={[
								{
									required: true,
									message: 'Kötelező mező',
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={15}>
						<Form.Item
							label='Város'
							name='billingCity'
							rules={[
								{
									required: true,
									message: 'Kötelező mező',
								},
							]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default NewVendorModal;
