import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Vendor.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import zeroFill from 'zero-fill';

import PageGuard from '../../../components/PageGuard/PageGuard';

import Moment from 'react-moment';
import { PageHeader, Button, Row, Col, Card, Input, Form, Select, Modal, Tabs, Table, Typography } from 'antd';
import { ExclamationCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { Text } = Typography;

const pagePath = 'vendor';

const Vendor = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [vendor, setVendor] = useState({});
	const [form] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [listCountry, setListCountry] = useState([]);
	const [listVendorCategory, setListVendorCategory] = useState([]);
	const [confirmLoading, setConfirmLoading] = useState(true);

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		server
			.get('/list/country')
			.then((response) => {
				setListCountry(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.post('/list/vendor-category')
			.then((response) => {
				setListVendorCategory(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		getVendor();
	}, [params.id]);

	const getVendor = () => {
		server
			.get('/vendor/' + params.id)
			.then((response) => {
				setVendor(response.data);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleFormSubmit = (values) => {
		server
			.patch('/vendor/' + params.id, values)
			.then((response) => {
				getVendor();
				notification('success', 'Beszállító sikeresen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleDeleteVendor = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a beszállítót?',
			icon: <ExclamationCircleOutlined />,
			content: vendor.companyName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			onOk() {
				server
					.patch('/vendor/' + params.id, {
						status: 3,
					})
					.then((response) => {
						notification('success', 'Beszállító sikeresen törölve', vendor.companyName);
						history.push('/vendors');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const columnsPurchaseOrders = [
		{
			title: 'Azonosító',
			key: 'prchordNo',
			render: (record) => (
				<Text onClick={() => history.push(`/purchaseorder/${record.id}`)} strong>
					#{zeroFill(6, record.id)}
				</Text>
			),
		},
		{
			title: 'Dátum',
			dataIndex: 'prchordDate',
			key: 'prchordDate',
			render: (text) => {
				return (
					<Moment format='YYYY/MM/DD' local>
						{text}
					</Moment>
				);
			},
		},
		{
			title: 'Érték',
			dataIndex: 'prchordNetPrice',
			key: 'prchordNetPrice',
			render: (text) => text.toLocaleString('hu') + ' Ft',
		},
	];

	return (
		<PageGuard path={pagePath} data={vendor}>
			<div className={styles.Vendor}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={vendor.companyName}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='2' type='primary' onClick={handleDeleteVendor} disabled={props.store.auth.user.userRole.accessLevel > 20}>
							Törlés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Form
						key={vendor.id}
						form={form}
						layout='vertical'
						initialValues={{
							requiredMark,
							...vendor,
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={9}>
								<Card title='Alapadatok'>
									<Form.Item
										label='Cégnév'
										name='companyName'
										rules={[
											{
												required: true,
												message: 'Kötelező mező',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item label='1. Kapcsolattartó neve' name='contactPersonName'>
										<Input />
									</Form.Item>
									<Form.Item
										label='1. Kapcsolattartó email'
										name='email'
										rules={[
											{
												required: true,
												type: 'email',
												message: 'Nem megfelelő email formátum',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item label='1. Kapcsolattartó telefonszám' name='phone'>
										<Input defaultValue={vendor.phone} />
									</Form.Item>
									<Form.Item label='2. Kapcsolattartó neve' name='contactPersonName2'>
										<Input />
									</Form.Item>
									<Form.Item
										label='2. Kapcsolattartó email'
										name='contactPersonEmail2'
										rules={[
											{
												type: 'email',
												message: 'Nem megfelelő email formátum',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item label='2. Kapcsolattartó telefonszám' name='contactPersonPhone2'>
										<Input />
									</Form.Item>
									<Form.Item label='3. Kapcsolattartó neve' name='contactPersonName3'>
										<Input />
									</Form.Item>
									<Form.Item
										label='3. Kapcsolattartó email'
										name='contactPersonEmail3'
										rules={[
											{
												type: 'email',
												message: 'Nem megfelelő email formátum',
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item label='3. Kapcsolattartó telefonszám' name='contactPersonPhone3'>
										<Input />
									</Form.Item>
								</Card>
								<Card title='Beszerzési rendelések' style={{ marginTop: 16 }}>
									<Table columns={columnsPurchaseOrders} dataSource={vendor.purchaseOrders} size='middle' />
								</Card>
							</Col>
							<Col xs={24} lg={10}>
								<Card bodyStyle={{ paddingTop: 0 }}>
									<Tabs defaultActiveKey='1' size='large'>
										<TabPane
											tab={
												<span>
													<ShoppingCartOutlined />
													Számlázási adatok
												</span>
											}
											key='1'>
											<Form.Item label='Adószám' name='taxID'>
												<Input />
											</Form.Item>
											<Form.Item label='EU adószám' name='vatID'>
												<Input />
											</Form.Item>
											<Form.Item label='Cím' name='billingAddress'>
												<Input />
											</Form.Item>
											<Row justify='space-between'>
												<Col span={7}>
													<Form.Item label='Irányítószám' name='billingPostalCode'>
														<Input />
													</Form.Item>
												</Col>
												<Col span={15}>
													<Form.Item label='Város' name='billingCity'>
														<Input />
													</Form.Item>
												</Col>
											</Row>
											<Form.Item label='Ország' name='countryId'>
												<Select
													showSearch
													defaultValue={99}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listCountry.map((country) => {
														return (
															<Option key={country.id} value={country.id}>
																{country.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</TabPane>
									</Tabs>
								</Card>
								<Card style={{ marginTop: 16 }}>
									<Form.Item name='notes'>
										<TextArea placeholder='Megjegyzések' rows={5} />
									</Form.Item>
								</Card>
							</Col>
							<Col xs={24} lg={5}>
								<Card title='Beállítások'>
									<Form.Item label='Kategória' name='vendorCategoryId'>
										<Select showSearch optionFilterProp='value' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
											{listVendorCategory.map((category) => {
												return (
													<Option key={category.id} value={category.id}>
														{category.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item>
										<Button type='primary' htmlType='submit' size='large' block disabled={props.store.auth.user.userRole.accessLevel > 20}>
											Mentés
										</Button>
									</Form.Item>
								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Vendor, pagePath));
