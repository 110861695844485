import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as actionCreators from '../../store/actions/actions';
import hoc from '../../hoc/PageContainer';
import styles from './Dashboard.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';

import { PageHeader } from 'antd';

import AdminDashboardSummary from '../../components/Dashboard/AdminDashboardSummary/AdminDashboardSummary';
import AdminDashboardUsers from '../../components/Dashboard/AdminDashboardUsers/AdminDashboardUsers';
import SalesDashboard from '../../components/Dashboard/SalesDashboard/SalesDashboard';

const pagePath = 'dashboard';

const Dashboard = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [listUser, setListUser] = useState([]);
	const [listOrder, setListOrder] = useState([]);
	const [listTopProducts, setListTopProducts] = useState([]);
	const [listProducts, setListProducts] = useState([]);
	const [listTransfers, setListTransfers] = useState([]);
	const [listWarehouses, setListWarehouses] = useState([]);

	let location = useLocation();

	useEffect(() => {
		props.setLoading(false);
	}, []);

	useEffect(() => {
		server
			.get('/list/user/dashboard')
			.then((response) => {
				setListUser(
					response.data.filter((user) => user.userRole.key == 'sales' || user.userRole.key == 'office' || user.userRole.key == 'marketing' || user.userRole.key == 'administrator')
				);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/order/dashboard')
			.then((response) => {
				setListOrder(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/product/dashboard/top')
			.then((response) => {
				setListTopProducts(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/product')
			.then((response) => {
				setListProducts(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/warehouse/dashboard')
			.then((response) => {
				setListWarehouses(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/warehouse-transfer/dashboard')
			.then((response) => {
				setListTransfers(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	return (
		<>
			<PageHeader className={styles.pageHeader} onBack={() => window.history.back()} title={pageDetails.title} subTitle={pageDetails.subtitle} />
			{props.store.auth.user.userRole.accessLevel <= 29 ? (
				location.pathname === '/dashboard' || location.pathname === '/' ? (
					<AdminDashboardSummary
						store={props.store}
						listUser={listUser}
						listOrder={listOrder}
						listTopProducts={listTopProducts}
						listProducts={listProducts}
						listTransfers={listTransfers}
						listWarehouses={listWarehouses}
					/>
				) : location.pathname === '/dashboard/users' ? (
					<AdminDashboardUsers
						store={props.store}
						listUser={listUser}
						listOrder={listOrder}
						listTopProducts={listTopProducts}
						listProducts={listProducts}
						listTransfers={listTransfers}
					/>
				) : null
			) : props.store.auth.user.userRole.key === 'sales' ? (
				<SalesDashboard store={props.store} listUser={listUser} />
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		store: state.general,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(hoc(Dashboard, pagePath));
