import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import styles from './AdminDashboardUsers.module.scss';
import moment from 'moment';

import DailySummary from '../DailySummary/DailySummary';
import MonthlySummary from '../MonthlySummary/MonthlySummary';
import YearlySummary from '../YearlySummary/YearlySummary';
import TopProducts from '../TopProducts/TopProducts';
import MonthlyCoverage from '../MonthlyCoverage/MonthlyCoverage';
import { Row, Col, Card, DatePicker, Select, Statistic } from 'antd';
const { Option } = Select;

const AdminDashboardUsers = (props) => {
	return (
		<div className={styles.AdminDashboard}>
			<div className={styles.pageBody}>
				{props.store.auth.user.userRole.accessLevel <= 10 && (
					<>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={8}>
								<DailySummary userId={32} listUser={props.listUser} store={props.store}/> {/* ID */}
							</Col>
							<Col xs={24} lg={8}>
								<DailySummary userId={33} listUser={props.listUser} store={props.store}/>
							</Col>
							<Col xs={24} lg={8}>
								<DailySummary userId={34} listUser={props.listUser} store={props.store}/>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={24}>
								<DailySummary userId={30} listUser={props.listUser} store={props.store}/> {/* ID */}
							</Col>
							<Col xs={24} lg={24}>
								<DailySummary userId={37} listUser={props.listUser} store={props.store}/> {/* ID */}
							</Col>
							<Col xs={24} lg={24}>
								<DailySummary userId={35} listUser={props.listUser} store={props.store}/> {/* ID */}
							</Col>
						</Row>
					</>
				)}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(AdminDashboardUsers);
