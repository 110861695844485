import React, { useState, useEffect } from 'react';
import styles from './NewPurchaseOrderModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import { Modal, Form, Select } from 'antd';
const { Option } = Select;

const NewPurchaseOrderModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listVendor, setListVendor] = useState([]);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		server
			.get('/list/vendor')
			.then((response) => {
				let filteredVendor;
				if (props.store.auth.user.userRole.accessLevel <= 29) {
					filteredVendor = response.data;
				} else {
					filteredVendor = response.data.filter((item) => item.userId === props.store.auth.user.id);
				}
				setListVendor(filteredVendor);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.post('/purchase-order', { ...values, userId: props.store.auth.user.id })
			.then((response) => {
				form.resetFields();
				setLoading(false);
				props.toggle();
				props.getPurchaseOrders();
				notification('success', 'Beszerzési rendelés sikeresen létrehozva.', response.data.prchordNo);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewPurchaseOrderModal}
			title='Új beszerzési rendelés létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item label='Beszállító' name='vendorId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{listVendor.map((vendor) => {
							return (
								<Option key={vendor.id} value={vendor.id}>
									{vendor.companyName}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default NewPurchaseOrderModal;
