import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './User.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';

import PageGuard from '../../../components/PageGuard/PageGuard';

import { PageHeader, Button, Row, Col, Card, Input, Form, Select, Modal, Upload } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const { confirm } = Modal;

const pagePath = 'user';

const User = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [user, setUser] = useState({});
	const [form] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [listUserRole, setListUserRole] = useState([]);
	const [password, setPassword] = useState();
	const [loading, setLoading] = useState({
		save: false,
		file: false,
	});

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getUser();
	}, [params.id]);

	const getUser = () => {
		server
			.get('/user/' + params.id)
			.then((response) => {
				setUser(response.data);
				setPassword(response.data.password);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	useEffect(() => {
		server
			.get('/list/user-role')
			.then((response) => {
				let filteredData = response.data.filter((item) => item.key !== 'root');
				setListUserRole(filteredData);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const uploadButton = (
		<div>
			{loading.file ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Képfeltöltés</div>
		</div>
	);

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleFormSubmit = (values) => {
		server
			.patch('/user/' + params.id, values)
			.then((response) => {
				getUser();
				notification('success', 'Felhasználó sikeresen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleSaveUser = () => {
		form.submit();
	};

	const handleDeleteUser = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a felhasználót?',
			icon: <ExclamationCircleOutlined />,
			content: user.fullName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			onOk() {
				server
					.delete('/user/' + params.id)
					.then((response) => {
						notification('success', 'Felhasználó sikeresen törölve', user.fullName);
						history.push('/users');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleBeforeFileUpload = (file) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			notification('error', 'Csak JPG/PNG formátum feltöltése engedélyezett');
		}
		const isLt2M = file.size / 1024 / 1024 < 10;
		if (!isLt2M) {
			notification('error', 'A fájlméret nem lehet több, mint 10mb');
		}
		return isJpgOrPng && isLt2M;
	};

	const handleFileUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading({ ...loading, file: true });
			return;
		}
		if (info.file.status === 'done') {
			setLoading({ ...loading, file: false });
			getUser();
			notification('success', 'Kép sikeresen feltöltve');
		}
	};

	const handleDeletePhoto = () => {
		setLoading({ save: true });
		server
			.patch('/user/' + params.id, { photo: null })
			.then((response) => {
				setLoading({ save: false });
				notification('success', 'Kép sikeresen törölve');
				getUser();
			})
			.catch((error) => {
				setLoading({ save: false });
				errorHandler(error);
			});
	};

	let passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{12,}$/gm; // Minimum twelve characters, at least one uppercase letter, one lowercase letter and one number:

	return (
		<PageGuard path={pagePath} data={user} userPage={true}>
			<div className={styles.Vendor}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={`${user.fullName} (${user.username})`}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='2' type='primary' onClick={handleDeleteUser} danger>
							Törlés
						</Button>,
						<Button key='2' type='primary' onClick={handleSaveUser} loading={loading.save}>
							Mentés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Form
						key={user.id}
						form={form}
						layout='vertical'
						initialValues={{
							requiredMark,
							...user,
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={5}>
								<Card>
									{user.photo ? (
										<Row gutter={[16, 16]} justify='end'>
											<Col>
												<img src={props.store.system.filesPath + '/users/' + user.photo} alt='photo' style={{ width: '100%' }} />
											</Col>
											<Col style={{ textAlign: 'right' }}>
												<Button danger onClick={handleDeletePhoto}>
													Törlés
												</Button>
											</Col>
										</Row>
									) : null}
									<Upload
										name='file'
										listType='picture-card'
										showUploadList={false}
										action={process.env.REACT_APP_API_URL + '/api/upload/user/' + params.id}
										headers={{ Authorization: 'Bearer ' + localStorage.getItem('token') }}
										beforeUpload={handleBeforeFileUpload}
										onChange={handleFileUploadChange}>
										{user.photo ? null : uploadButton}
									</Upload>
								</Card>
							</Col>
							<Col xs={10}>
								<Card title='Alapadatok'>
									<Row gutter={[16, 0]}>
										<Col xs={12}>
											<Form.Item
												label='Vezetéknév'
												name='lastName'
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Input />
											</Form.Item>
										</Col>
										<Col xs={12}>
											<Form.Item
												label='Keresztnév'
												name='firstName'
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Input />
											</Form.Item>
										</Col>
										<Col xs={24}>
											<Form.Item
												label='Email cím'
												name='email'
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={9}>
								<Card title='Jogosultság és biztonság'>
									{props.store.auth.user.userRole.accessLevel > 19 ? null : (
										<Form.Item label='Jogosultsági szint' name='userRoleId' rules={[{ required: true, message: 'Kötelező mező' }]}>
											<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listUserRole.map((userRole) => {
													return (
														<Option key={userRole.id} value={userRole.id}>
															{`${userRole.accessLevel} - ${userRole.name}`}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									)}
									<Form.Item
										label='Új jelszó'
										name={password === user.password ? '' : 'password'}
										rules={[{ message: 'Min. 12 karakter, min. 1 nagybetű, min. 1 szám', pattern: passwordRegExp }]}>
										<Input type='password' onChange={(e) => setPassword(e.currentTarget.value)} />
									</Form.Item>
									<Form.Item
										label='Új jelszó megerősítés'
										name='passwordconfirm'
										rules={[
											{
												required: password === user.password ? false : true,
												message: 'Kötelező mező',
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('password') === value) {
														return Promise.resolve();
													}
													return Promise.reject('A két jelszó nem egyezik');
												},
											}),
										]}>
										<Input type='password' />
									</Form.Item>
								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(User, pagePath));
