import React, { useState, useEffect } from 'react';
import styles from './NewProductModal.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import { DebounceInput } from 'react-debounce-input';
import { nanoid } from 'nanoid';

import { Modal, Form, Input, Select } from 'antd';
const { Option } = Select;

const NewProductModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [warehouses, setWarehouses] = useState([]);
	const [productCategories, setProductCategories] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [isValidated, setIsValidated] = useState(true);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		server
			.get('/list/warehouse')
			.then((response) => {
				setWarehouses(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/product-category')
			.then((response) => {
				setProductCategories(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/vendor')
			.then((response) => {
				setVendors(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		form.resetFields();
		props.toggle();
	};

	const handleValidate = (e) => {
		console.log(e.target.value);
		if (e.target.value !== '') {
			server
				.get(`/worker/validate-product/${e.target.value}`)
				.then((response) => {
					switch (true) {
						case response.data === 'already_exists':
							setIsValidated(false);
							console.log('validated:', false, response.data);
							break;
						default:
							setIsValidated(true);
							console.log('validated:', true, response.data);
							break;
					}
				})
				.catch((error) => {
					errorHandler(error);
				});
		}
	};

	const onFinish = (values) => {
		setLoading(true);
		server
			.post('/product', values)
			.then((response) => {
				let productCode = response.data.prdCode;
				warehouses.map((warehouse, idx) => {
					server
						.post('/warehouse-item', {
							prdQty: 0,
							productId: response.data.id,
							warehouseId: warehouse.id,
						})
						.then((response) => {
							if (idx < warehouses.length) {
								notification('success', `${productCode} termék sikeresen létrehozva a(z) ${warehouse.wrhName} raktárban.`);
							}
							if ((idx = warehouses.length - 1)) {
								form.resetFields();
								setLoading(false);
								props.toggle();
								props.getProducts();
							}
						})
						.catch((error) => {
							errorHandler(error);
						});
				});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onFinishFailed = (errorInfo) => {
		setLoading(false);
	};

	return (
		<Modal
			className={styles.NewOrderModal}
			title='Új termék létrehozás'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			cancelText='Mégse'
			okButtonProps={{ disabled: (warehouses.length == 0 || !isValidated) && true }}
			okText='Mentés'>
			<Form layout='vertical' name='basic' form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
				<Form.Item label='Termék kód' name='prdCode' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<DebounceInput debounceTimeout={300} onChange={(e) => handleValidate(e)} className='ant-input' style={{ color: !isValidated && 'red' }} />
				</Form.Item>
				<Form.Item label='Termék név' name='prdName' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Elsődleges mennyiségi egység' name='prdQtyUnit' rules={[{ required: true, message: 'Kötelező mező' }]}>
				<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						<Option value={'db'}>db</Option>
						<Option value={'csomag'}>csomag</Option>
						<Option value={'karton'}>karton</Option>
						<Option value={'roll'}>roll</Option>
						<Option value={'kg'}>kg</Option>
						<Option value={'m2'}>m2</Option>
						<Option value={'m3'}>m3</Option>
					</Select>
				</Form.Item>
				<Form.Item label='Kategória' name='productCategoryId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{productCategories.map((category) => {
							return <Option value={category.id}>{category.name}</Option>;
						})}
					</Select>
				</Form.Item>
				<Form.Item label='Beszállító' name='vendorId' rules={[{ required: true, message: 'Kötelező mező' }]}>
					<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
						{vendors.map((vendor) => {
							return (
								<Option key={nanoid()} value={vendor.id}>
									{vendor.companyName}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label='Nettó lista egységár (Ft) - termékdíjjal' name='prdNetPrice'>
					<Input type='number' step='any' />
				</Form.Item>
				<Form.Item label='Utolsó beszerzési ár (Ft)' name='prdLastPrice'>
					<Input type='number' step='any' />
				</Form.Item>
{/* 				<Form.Item label='Súlyozott átlagár (Ft)' name='prdNetAvgPrice'>
					<Input type='number' step='any' />
				</Form.Item> */}
			</Form>
		</Modal>
	);
};

export default NewProductModal;
