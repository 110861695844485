import React from 'react';
import Helmet from 'react-helmet';

const Hoc = (Page, pagePath, pageDetails = {}) => (props) => {
	return (
		<>
			<Helmet>
				<title>{props.store.system.appName}</title>
			</Helmet>
			<Page
				{...props} // Default props of/from wrapped Page component
			/>
		</>
	);
};

export default Hoc;
