import React, { useEffect, useState } from 'react';
import styles from './MonthlyCoverage.module.scss';
import moment from 'moment';

import { Row, Col, Card, DatePicker, Select, Statistic } from 'antd';
const { Option } = Select;

const MonthlyCoverage = (props) => {
	const [activeDate, setActiveDate] = useState('');
	const [stats, setStats] = useState({});

	const dateFormat = 'YYYY-MM';

	useEffect(() => {
		setActiveDate(moment().format(dateFormat));
	}, [props.listTransfers]);

	useEffect(() => {
		let monthlyNetInvoiceSum = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i')
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoSum = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's')
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceCsabi = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 32) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoCsabi = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 32) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceBalazs = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 33) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoBalazs = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 33) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceAttila = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 34) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoAttila = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 34) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceIroda = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 30) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoIroda = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 30) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceAdrienn = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 37) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoAdrienn = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 37) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		let monthlyNetInvoiceRoli = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 'i' && transfer.userId == 35) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);
		let monthlyNetStornoRoli = props.listTransfers
			.filter((transfer) => moment(transfer.createdAt).format(dateFormat) == activeDate && transfer.transferCode.toLowerCase() == 's' && transfer.userId == 35) // ID: user
			.reduce((a, b) => a + (b.prdQty * b.prdNetPrice - b.prdQty * b.prdLastPrice), 0)
			.toFixed(0);

		setStats({
			monthlyNetSum: monthlyNetInvoiceSum - monthlyNetStornoSum,
			monthlyNetCsabi: monthlyNetInvoiceCsabi  - monthlyNetStornoCsabi,
			monthlyNetBalazs: monthlyNetInvoiceBalazs - monthlyNetStornoBalazs,
			monthlyNetAttila: monthlyNetInvoiceAttila - monthlyNetStornoAttila,
			monthlyNetIroda: monthlyNetInvoiceIroda - monthlyNetStornoIroda,
			monthlyNetAdrienn: monthlyNetInvoiceAdrienn - monthlyNetStornoAdrienn,
			monthlyNetRoli: monthlyNetInvoiceRoli - monthlyNetStornoRoli,
		});
	}, [activeDate, props.listTransfers]);

	const handleSelectDate = (data) => {
		setActiveDate(data.format(dateFormat));
	};

	return (
		<Card title='Havi fedezet' className={styles.MonthlyCoverage}>
			<Row gutter={[16, 16]}>
				<Col xs={24} lg={24}>
					<DatePicker defaultValue={moment()} format={dateFormat} picker='month' onChange={handleSelectDate} allowClear={false} style={{ width: '100%' }} size='large' />
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Csabi nettó'
						value={stats.monthlyNetCsabi}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Balázs nettó'
						value={stats.monthlyNetBalazs}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Attila nettó'
						value={stats.monthlyNetAttila}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Központi iroda Alexa nettó'
						value={stats.monthlyNetIroda}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Központi iroda Adrienn nettó'
						value={stats.monthlyNetAdrienn}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Roli nettó'
						value={stats.monthlyNetRoli}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
				<Col xs={24} lg={24}>
					<Statistic
						title='Összesen nettó'
						value={stats.monthlyNetSum}
						suffix={'Ft'}
						valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
						precision={0}
						formatter={(value) => parseFloat(value).toLocaleString('hu')}
						style={{ marginTop: '32px' }}
					/>
				</Col>
			</Row>
		</Card>
	);
};

export default MonthlyCoverage;
