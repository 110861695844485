import React from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import styles from './OrderLoader.module.scss';

const OrderLoader = (props) => {
	return (
		<div className={props.loading ? styles.OrderLoader : styles.OrderLoader + ' ' + styles.hidden}>
			<LoopCircleLoading color='#00a838' />
		</div>
	);
};

export default OrderLoader;
