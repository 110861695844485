import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory, useParams } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './Vendors.module.scss';
import errorHandler from '../../utils/error-handler';
import server from '../../utils/axios/api';

import PageGuard from '../../components/PageGuard/PageGuard';
import SearchBar from '../../components/SearchBar/SearchBar';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Space, Statistic, Form } from 'antd';
import NewVendorModal from '../../components/Modal/NewVendorModal/NewVendorModal';

const { Text } = Typography;

const pagePath = 'vendors';
const defaultPageSize = 50;

const searchData = {
	searchFields: [
		{ key: 'companyCode', name: 'Cégkód' },
		{ key: 'companyName', name: 'Cégnév' },
		{ key: 'contactPersonName', name: 'Kapcsolattartó' },
		{ key: 'email', name: 'Email cím' },
		{ key: 'phone', name: 'Telefon' },
		{ key: 'billingCity', name: 'Város' },
		{ key: 'vendorCategoryId', name: 'Kategória', type: 'dropdown', list: 'vendorCategory', value: 'id', text: 'name' },
	],
	lists: ['vendorCategory'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const Vendors = (props) => {
	const [state, setState] = useState({
		totalVendors: [],
	});
	const [pageDetails, setPageDetails] = useState({});
	const [vendors, setVendors] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchData.searchValues },
	});
	const [loading, setLoading] = useState({
		search: false,
	});

	let history = useHistory();
	const params = useParams();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getVendors();
	}, [queryParams]);

	const getVendors = () => {
		server
			.get(
				`/vendor?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${JSON.stringify(queryParams.search)}&fields=${JSON.stringify(searchData.searchFields)}&status=${
					params.status
				}`
			)
			.then((response) => {
				setVendors(response.data.rows);
				setTotal(response.data.count);
				setLoading({ ...loading, data: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			vendors.map((vendor) => {
				if (list.find((item) => item.value == vendor[column1][column2])) {
					return null;
				} else {
					return list.push({ text: vendor[column1][column2], value: vendor[column1][column2] });
				}
			});
		} else {
			vendors.map((vendor) => {
				if (list.find((item) => item.value == vendor[column1])) {
					return null;
				} else {
					return list.push({ text: vendor[column1], value: vendor[column1] });
				}
			});
		}
		return list;
	};

	const handleClick = (id) => {
			window.open('/vendor/' + id);
	};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const tableColumns = [
		{
			title: 'Cégnév',
			dataIndex: 'companyName',
			render: (text, record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)} strong>
					{record.companyName}
				</Text>
			),
			sorter: (a, b) => {
				if (a.companyName < b.companyName) {
					return -1;
				}
				if (a.companyName > b.companyName) {
					return 1;
				}
			},
		},
		{
			title: 'Kapcsolattartó',
			dataIndex: 'contactPersonName',
			sorter: (a, b) => {
				if (a.contactPersonName < b.contactPersonName) {
					return -1;
				}
				if (a.contactPersonName > b.contactPersonName) {
					return 1;
				}
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: (a, b) => {
				if (a.email < b.email) {
					return -1;
				}
				if (a.email > b.email) {
					return 1;
				}
			},
		},
		{
			title: 'Telefon',
			dataIndex: 'phone',
			sorter: (a, b) => {
				if (a.phone < b.phone) {
					return -1;
				}
				if (a.phone > b.phone) {
					return 1;
				}
			},
		},
		{
			title: 'Kategória',
			render: (record) => record.vendorCategory.name || '',
			sorter: (a, b) => {
				if (a.vendorCategory.name < b.vendorCategory.name) {
					return -1;
				}
				if (a.vendorCategory.name > b.vendorCategory.name) {
					return 1;
				}
			},
		},
	];

	return (
		<PageGuard path={pagePath} data={vendors}>
			<div className={styles.Vendors}>
				<NewVendorModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getVendors={getVendors} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={pageDetails.title}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button key='1' onClick={props.teszt}>
							Export CSV
						</Button>,
						<Button key='2' type='primary' onClick={() => setModalVisible(true)} disabled={props.store.auth.user.userRole.accessLevel > 20}>
							Új beszállító
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={19}>
							<Row gutter={[0, 16]}>
								<Col span={24}>
									<SearchBar
										defaultPageSize={defaultPageSize}
										queryParams={queryParams}
										action={setQueryParams}
										searchFields={searchData.searchFields}
										lists={searchData.lists}
										loading={loading}
										setLoading={setLoading}
									/>
								</Col>
								<Col span={24}>
									<Card>
										<Table
											dataSource={vendors}
											columns={tableColumns}
											size='middle'
											pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize }}
											loading={loading.data}
										/>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col span={5}>
							<Row>
								<Col span={24}>
									<Card title='Statisztika'>
										<Space direction='vertical' size='middle'>
											<Statistic title='Összes Beszállító' value={total} />
										</Space>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Vendors, pagePath));
