import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Customer.module.scss';
import server from '../../../utils/axios/api';
import billingo from '../../../utils/axios/billingo';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import zeroFill from 'zero-fill';
import moment from '../../../utils/moment';

import PageGuard from '../../../components/PageGuard/PageGuard';

import Moment from 'react-moment';
import { PageHeader, Button, Row, Col, Card, Input, Form, Select, Modal, Tabs, Table, Typography, List, Avatar, Space } from 'antd';
import { ExclamationCircleOutlined, CarOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { Text } = Typography;

const pagePath = 'customer';

const Customer = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [customer, setCustomer] = useState({});
	const [form] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [listCountry, setListCountry] = useState([]);
	const [listPaymentMethod, setListPaymentMethod] = useState([]);
	const [listCustomerCategory, setListCustomerCategory] = useState([]);
	const [listUser, setListUser] = useState([]);
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [activeComment, setActiveComment] = useState({
		id: null,
		body: null,
	});
	const [isLocked, setIsLocked] = useState(false);

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		if (props.store.auth.user.userRole.key === 'sales') {
			setIsLocked(true);
		}
	}, [props.store.auth.user.userRole.key]);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		server
			.get('/list/country')
			.then((response) => {
				setListCountry(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
		server
			.get('/list/customer-category')
			.then((response) => {
				setListCustomerCategory(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
		server
			.get('/list/payment-method')
			.then((response) => {
				setListPaymentMethod(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
		server
			.get('/list/user/include')
			.then((response) => {
				let filteredUser = response.data.filter((item) => item.userRole.key === 'sales' || (item.userRole.key !== 'root' && item.userRole.accessLevel <= 29));
				setListUser(filteredUser);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		getCustomer();
	}, [params.id]);

	const getCustomer = () => {
		server
			.get('/customer/' + params.id)
			.then((response) => {
				setCustomer(response.data);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleFormSubmit = (values) => {
		let email = values.email || '';
		server
			.patch('/customer/' + params.id, {
				...values,
				updatedBy: `${props.store.auth.user.lastName} ${props.store.auth.user.firstName}`,
			})
			.then((response) => {
				billingo
					.patch('/partners/' + customer.billingoCustomerId, {
						name: values.companyName,
						address: {
							country_code: listCountry.find((country) => country.id === values.countryId).code || '',
							post_code: values.billingPostalCode || '',
							city: values.billingCity || '',
							address: values.billingAddress || '',
						},
						taxcode: values.taxID || '',
						emails: [email],
					})
					.then((response) => {
						getCustomer();
						notification('success', 'Ügyfél sikeresen frissítve');
					})
					.catch((error) => {
						errorHandler(error);
					});
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleDeleteCustomer = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné az ügyfelet?',
			icon: <ExclamationCircleOutlined />,
			content: customer.companyName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			onOk() {
				server
					.patch('/customer/' + params.id, {
						status: 3,
					})
					.then((response) => {
						notification('success', 'Ügyfél sikeresen törölve', customer.companyName);
						history.push('/customers');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleToggleCustomer = () => {
		confirm({
			title: 'Biztos, hogy aktiválni/inaktiválni szeretné az ügyfelet?',
			icon: <ExclamationCircleOutlined />,
			content: customer.companyName,
			okText: 'Módosítás',
			cancelText: 'Mégse',
			onOk() {
				server
					.patch('/customer/' + params.id, { status: customer.status == 1 ? 0 : 1 })
					.then((response) => {
						getCustomer();
						notification('success', 'Ügyfél sikeresen módosítva', customer.companyName);
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleSaveComment = () => {
		if (!activeComment.id) {
			server
				.post('/comment', {
					userId: props.store.auth.user.id,
					customerId: customer.id,
					body: activeComment.body,
				})
				.then((response) => {
					getCustomer();
					setActiveComment({
						id: null,
						body: null,
					});
					notification('success', 'Megjegyzés sikeresen hozzáadva');
				})
				.catch((error) => {
					errorHandler(error);
				});
		} else {
			server
				.patch('/comment/' + activeComment.id, {
					userId: props.store.auth.user.id,
					body: activeComment.body,
				})
				.then((response) => {
					getCustomer();
					setActiveComment({
						id: null,
						body: null,
					});
					notification('success', 'Megjegyzés sikeresen módosítva');
				})
				.catch((error) => {
					errorHandler(error);
				});
		}
	};

	const handleDeleteCommentClick = (id) => {
		server
			.delete('/comment/' + id)
			.then((response) => {
				getCustomer();
				setActiveComment({
					id: null,
					body: null,
				});
				notification('success', 'Megjegyzés sikeresen törölve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleEditCommentClick = (id, body) => {
		setActiveComment({
			id,
			body,
		});
	};

	const columnsOrders = [
		{
			title: 'Azonosító',
			key: 'ordNo',
			render: (record) => (
				<Text onClick={() => history.push(`/order/${record.id}`)} strong style={{ cursor: 'pointer' }}>
					#{zeroFill(6, record.id)}
				</Text>
			),
		},
		{
			title: 'Dátum',
			dataIndex: 'ordDate',
			key: 'ordDate',
			render: (text) => {
				return (
					<Moment format='YYYY/MM/DD' local>
						{text}
					</Moment>
				);
			},
		},
		{
			title: 'Érték',
			dataIndex: 'ordNetPrice',
			key: 'ordNetPrice',
			render: (text) => text.toLocaleString('hu') + ' Ft',
		},
	];

	return (
		<PageGuard path={pagePath} data={customer}>
			<div className={styles.Customer}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={customer.companyName}
					subTitle={pageDetails.subtitle}
					extra={[
						<>
							<Button key='1' type='primary' onClick={handleDeleteCustomer} danger disabled={isLocked}>
								Törlés
							</Button>
							<Button key='2' type='primary' onClick={handleToggleCustomer} disabled={isLocked}>
								{customer.status == 1 ? 'Inaktiválás' : 'Aktiválás'}
							</Button>
						</>,
					]}
				/>
				<div className={styles.pageBody}>
					<Form
						key={customer.id}
						form={form}
						layout='vertical'
						initialValues={{
							requiredMark,
							...customer,
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={9}>
								<Card title='Alapadatok'>
									<Form.Item
										label='Cégnév'
										name='companyName'
										rules={[
											{
												required: true,
												message: 'Kötelező mező',
											},
										]}>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='Fantázianév' name='nickname'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='1. Kapcsolattartó neve' name='contactPersonName'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='1. Kapcsolattartó email' name='email'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='1. Kapcsolattartó telefonszám' name='phone'>
										<Input defaultValue={customer.phone} disabled={isLocked} />
									</Form.Item>
									<Form.Item label='2. Kapcsolattartó neve' name='contactPersonName2'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item
										label='2. Kapcsolattartó email'
										name='contactPersonEmail2'
										rules={[
											{
												type: 'email',
												message: 'Nem megfelelő email formátum',
											},
										]}>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='2. Kapcsolattartó telefonszám' name='contactPersonPhone2'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='3. Kapcsolattartó neve' name='contactPersonName3'>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item
										label='3. Kapcsolattartó email'
										name='contactPersonEmail3'
										rules={[
											{
												type: 'email',
												message: 'Nem megfelelő email formátum',
											},
										]}>
										<Input disabled={isLocked} />
									</Form.Item>
									<Form.Item label='3. Kapcsolattartó telefonszám' name='contactPersonPhone3'>
										<Input disabled={isLocked} />
									</Form.Item>
								</Card>
								<Card title='Megrendelések' style={{ marginTop: 16 }}>
									<Table columns={columnsOrders} dataSource={customer.orders} size='middle' />
								</Card>
							</Col>
							<Col xs={24} lg={10}>
								<Card bodyStyle={{ paddingTop: 0 }}>
									<Tabs defaultActiveKey='1' size='large'>
										<TabPane
											tab={
												<span>
													<ShoppingCartOutlined />
													Számlázási adatok
												</span>
											}
											key='1'>
											<Form.Item label='Adószám' name='taxID' rules={[{ required: true, len: 13, message: 'Az adószám hossza 13 karakter. Pl. 11111111-1-11' }]}>
												<Input disabled={isLocked} />
											</Form.Item>
											<Form.Item label='EU adószám' name='vatID'>
												<Input disabled={isLocked} />
											</Form.Item>
											<Form.Item label='Cím' name='billingAddress'>
												<Input disabled={isLocked} />
											</Form.Item>
											<Row justify='space-between'>
												<Col span={7}>
													<Form.Item label='Irányítószám' name='billingPostalCode'>
														<Input disabled={isLocked} />
													</Form.Item>
												</Col>
												<Col span={15}>
													<Form.Item label='Város' name='billingCity'>
														<Input disabled={isLocked} />
													</Form.Item>
												</Col>
											</Row>
											<Form.Item label='Ország' name='countryId'>
												<Select
													disabled={isLocked}
													showSearch
													defaultValue={99}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listCountry.map((country) => {
														return (
															<Option key={country.id} value={country.id}>
																{country.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</TabPane>
										<TabPane
											tab={
												<span>
													<CarOutlined />
													Szállítási adatok
												</span>
											}
											key='2'>
											<Form.Item label='Cím' name='shippingAddress'>
												<Input disabled={isLocked} />
											</Form.Item>
											<Row justify='space-between'>
												<Col span={7}>
													<Form.Item label='Irányítószám' name='shippingPostalCode'>
														<Input disabled={isLocked} />
													</Form.Item>
												</Col>
												<Col span={15}>
													<Form.Item label='Város' name='shippingCity'>
														<Input disabled={isLocked} />
													</Form.Item>
												</Col>
											</Row>
											<Form.Item label='Ország' name='shippingCountry'>
												<Select
													disabled={isLocked}
													showSearch
													defaultValue={99}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listCountry.map((country) => {
														return (
															<Option key={country.id} value={country.name}>
																{country.name}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</TabPane>
									</Tabs>
								</Card>
								<Card style={{ marginTop: 16 }}>
									<Row justify='end' gutter={[0, 16]}>
										<Col xs={24}>
											<TextArea
												placeholder='Megjegyzés'
												rows={5}
												value={activeComment.body}
												onChange={(e) => setActiveComment({ ...activeComment, body: e.target.value })}
												disabled={isLocked}
											/>
										</Col>
										<Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<Button onClick={handleSaveComment} disabled={isLocked}>
												Mentés
											</Button>
										</Col>
									</Row>
									{customer.comments && customer.comments.length > 0 && (
										<Row justify='end' gutter={[0, 16]} style={{ marginTop: '16px' }}>
											<Col xs={24}>
												<List
													itemLayout='horizontal'
													dataSource={customer.comments}
													renderItem={(item) => (
														<List.Item
															actions={[
																<a key='list-edit' onClick={() => handleEditCommentClick(item.id, item.body)}>
																	Szerkesztés
																</a>,
																<a key='list-delete' onClick={() => handleDeleteCommentClick(item.id)}>
																	Törlés
																</a>,
															]}>
															<List.Item.Meta
																avatar={item.user.photo ? <Avatar src={props.store.system.filesPath + '/users/' + item.user.photo} /> : <Avatar icon={<UserOutlined />} />}
																title={
																	<Space size='small'>
																		<Text>{item.user.fullName}</Text>
																		<Text type='secondary'>
																			(
																			<Moment format='YYYY-MM-DD HH:mm' local>
																				{item.updatedAt}
																			</Moment>
																			)
																		</Text>
																	</Space>
																}
																description={item.body}
															/>
														</List.Item>
													)}
												/>
											</Col>
										</Row>
									)}
								</Card>
							</Col>
							<Col xs={24} lg={5}>
								<Card title='Beállítások'>
									<Form.Item label='Kategória' name='customerCategoryId'>
										<Select
											disabled={isLocked}
											showSearch
											optionFilterProp='value'
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
											{listCustomerCategory.map((category) => {
												return (
													<Option key={category.id} value={category.id}>
														{category.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label='Értékesítő' name='userId' rules={[{ required: true, message: 'Kötelező mező' }]}>
										<Select
											disabled={isLocked}
											showSearch
											optionFilterProp='children'
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
											{listUser.map((user) => {
												return (
													<Option key={user.id} value={user.id}>
														{user.fullName}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label='Fizetési mód' name='paymentMethodId'>
										<Select
											disabled={isLocked}
											defaultValue={2}
											optionFilterProp='value'
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
											{listPaymentMethod.map((item) => {
												return (
													<Option key={item.id} value={item.id}>
														{item.name}
													</Option>
												);
											})}
										</Select>
									</Form.Item>
									<Form.Item label='Fizetési határidő (nap)' name='billingDueDays'>
										<Input type='number' disabled={isLocked} />
									</Form.Item>
									<Form.Item label='Billingo csoport ID' name='billingoGroupId'>
										<Input disabled={props.store.auth.user.userRole.key !== 'root'} />
									</Form.Item>
									<Form.Item>
										<Button type='primary' htmlType='submit' size='large' block disabled={isLocked}>
											Mentés
										</Button>
									</Form.Item>
									<Space direction='vertical' style={{ fontSize: '.8rem' }}>
										<Text type='secondary' strong>{`Hozzáadás:`}</Text>
										<Text type='secondary'>{`${moment(customer.createdAt).local().format('YYYY/MM/DD - HH:mm')} (${customer.createdBy})`}</Text>
									</Space>
									<Space direction='vertical' style={{ marginTop: '16px', fontSize: '.8rem' }}>
										<Text type='secondary' strong>{`Módosítás:`}</Text>
										<Text type='secondary'>{`${moment(customer.updatedAt).local().format('YYYY/MM/DD - HH:mm')} (${customer.updatedBy})`}</Text>
									</Space>
								</Card>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Customer, pagePath));
