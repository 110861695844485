import React, { useEffect, useState } from 'react';
import styles from './DailySummary.module.scss';
import moment from 'moment';

import { Row, Col, Card, DatePicker, Select, Statistic } from 'antd';
const { Option } = Select;

const DailySummary = (props) => {
	const [activeUser, setActiveUser] = useState({ id: 0 });
	const [activeDateDaily, setActiveDateDaily] = useState('');
	const [activeDateMonthly, setActiveDateMonthly] = useState('');
	const [activeDateYearly, setActiveDateYearly] = useState('');
	const [stats, setStats] = useState({
		daily: {},
		monthly: {},
		yearly: {},
	});

	const dateFormatDaily = 'YYYY-MM-DD';
	const dateFormatMonthly = 'YYYY-MM';
	const dateFormatYearly = 'YYYY';

	useEffect(() => {
		setActiveUser(props.listUser.find((user) => user.id == props.userId));
		setActiveDateDaily(moment().format(dateFormatDaily));
		setActiveDateMonthly(moment().format(dateFormatMonthly));
		setActiveDateYearly(moment().format(dateFormatYearly));
	}, [props.listUser]);

	useEffect(() => {
		if (activeUser && activeUser.orders) {
			let wireNetDaily = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatDaily) == activeDateDaily && order.ordType == 2 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let wireGrossDaily = (wireNetDaily * 1.27).toFixed(0);
			let cashNetDaily = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatDaily) == activeDateDaily && order.ordType == 1 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let cashGrossDaily = (cashNetDaily * 1.27).toFixed(0);
			let deliveryNetDaily = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatDaily) == activeDateDaily && order.ordType == 3 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);

			let wireNetMonthly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatMonthly) == activeDateMonthly && order.ordType == 2 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let wireGrossMonthly = (wireNetMonthly * 1.27).toFixed(0);
			let cashNetMonthly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatMonthly) == activeDateMonthly && order.ordType == 1 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let cashGrossMonthly = (cashNetMonthly * 1.27).toFixed(0);
			let deliveryNetMonthly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatMonthly) == activeDateMonthly && order.ordType == 3 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);

			let wireNetYearly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatYearly) == activeDateYearly && order.ordType == 2 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let wireGrossYearly = (wireNetYearly * 1.27).toFixed(0);
			let cashNetYearly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatYearly) == activeDateYearly && order.ordType == 1 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);
			let cashGrossYearly = (cashNetYearly * 1.27).toFixed(0);
			let deliveryNetYearly = activeUser.orders
				.filter((order) => moment(order.ordDate).format(dateFormatYearly) == activeDateYearly && order.ordType == 3 && order.orderStatus.key == 100)
				.reduce((a, b) => a + parseFloat(b.ordNetPrice), 0)
				.toFixed(0);

			setStats({
				daily: {
					wireNet: wireNetDaily,
					wireGross: wireGrossDaily,
					cashNet: cashNetDaily,
					cashGross: cashGrossDaily,
					deliveryNet: deliveryNetDaily,
				},
				monthly: {
					wireNet: wireNetMonthly,
					wireGross: wireGrossMonthly,
					cashNet: cashNetMonthly,
					cashGross: cashGrossMonthly,
					deliveryNet: deliveryNetMonthly,
				},
				yearly: {
					wireNet: wireNetYearly,
					wireGross: wireGrossYearly,
					cashNet: cashNetYearly,
					cashGross: cashGrossYearly,
					deliveryNet: deliveryNetYearly,
				},
			});
		}
	}, [activeUser, activeDateDaily, activeDateMonthly, activeDateYearly]);

	const handleSelectDateDaily = (data) => {
		setActiveDateDaily(data.format(dateFormatDaily));
	};

	const handleSelectDateMonthly = (data) => {
		setActiveDateMonthly(data.format(dateFormatMonthly));
	};

	const handleSelectDateYearly = (data) => {
		setActiveDateYearly(data.format(dateFormatYearly));
	};

	return (
		<>
			<Card title={activeUser && `${activeUser.fullName} napi`} className={styles.DailySummary}>
				<Row gutter={[16, 16]}>
					<Col xs={24} lg={24}>
						<DatePicker defaultValue={moment()} format={dateFormatDaily} onChange={handleSelectDateDaily} allowClear={false} style={{ width: '100%' }} size='large' />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Utalásos nettó' value={stats.daily.wireNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Utalásos bruttó' value={stats.daily.wireGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Készpénzes nettó' value={stats.daily.cashNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Készpénzes bruttó' value={stats.daily.cashGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={24}>
						<Statistic title='Szállítóleveles nettó' value={stats.daily.deliveryNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={24}>
						<Statistic
							title='Összesen kp.'
							value={+stats.daily.cashGross + +stats.daily.deliveryNet}
							suffix={'Ft'}
							valueStyle={{ color: '#ff4f22', fontWeight: 'bold', fontSize: '36px' }}
							precision={0}
							formatter={(value) => parseFloat(value).toLocaleString('hu')}
							style={{ marginTop: '32px' }}
						/>
					</Col>
					<Col xs={24} lg={24}>
						<Statistic
							title='Összesen nettó'
							value={+stats.daily.wireNet + +stats.daily.cashNet + +stats.daily.deliveryNet}
							suffix={'Ft'}
							valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
							precision={0}
							formatter={(value) => parseFloat(value).toLocaleString('hu')}
							style={{ marginTop: '32px' }}
						/>
					</Col>
				</Row>
			</Card>
			<Card title={activeUser && `${activeUser.fullName} havi`} className={styles.DailySummary} style={{ marginTop: '16px' }}>
				<Row gutter={[16, 16]}>
					<Col xs={24} lg={24}>
						<DatePicker
							defaultValue={moment()}
							format={dateFormatMonthly}
							picker='month'
							onChange={handleSelectDateMonthly}
							allowClear={false}
							style={{ width: '100%' }}
							size='large'
						/>
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Utalásos nettó' value={stats.monthly.wireNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Utalásos bruttó' value={stats.monthly.wireGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Készpénzes nettó' value={stats.monthly.cashNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={12}>
						<Statistic title='Készpénzes bruttó' value={stats.monthly.cashGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={24}>
						<Statistic title='Szállítóleveles nettó' value={stats.monthly.deliveryNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
					</Col>
					<Col xs={24} lg={24}>
						<Statistic
							title='Összesen kp.'
							value={+stats.monthly.cashGross + +stats.monthly.deliveryNet}
							suffix={'Ft'}
							valueStyle={{ color: '#ff4f22', fontWeight: 'bold', fontSize: '36px' }}
							precision={0}
							formatter={(value) => parseFloat(value).toLocaleString('hu')}
							style={{ marginTop: '32px' }}
						/>
					</Col>
					<Col xs={24} lg={24}>
						<Statistic
							title='Összesen nettó'
							value={+stats.monthly.wireNet + +stats.monthly.cashNet + +stats.monthly.deliveryNet}
							suffix={'Ft'}
							valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
							precision={0}
							formatter={(value) => parseFloat(value).toLocaleString('hu')}
							style={{ marginTop: '32px' }}
						/>
					</Col>
				</Row>
			</Card>

			{props.store && props.store.auth.user.userRole.accessLevel <= 29 && (
				<Card title={activeUser && `${activeUser.fullName} éves`} className={styles.DailySummary} style={{ marginTop: '16px' }}>
					<Row gutter={[16, 16]}>
						<Col xs={24} lg={24}>
							<DatePicker
								defaultValue={moment()}
								format={dateFormatYearly}
								picker='year'
								onChange={handleSelectDateYearly}
								allowClear={false}
								style={{ width: '100%' }}
								size='large'
							/>
						</Col>
						<Col xs={24} lg={12}>
							<Statistic title='Utalásos nettó' value={stats.yearly.wireNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
						</Col>
						<Col xs={24} lg={12}>
							<Statistic title='Utalásos bruttó' value={stats.yearly.wireGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
						</Col>
						<Col xs={24} lg={12}>
							<Statistic title='Készpénzes nettó' value={stats.yearly.cashNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
						</Col>
						<Col xs={24} lg={12}>
							<Statistic title='Készpénzes bruttó' value={stats.yearly.cashGross} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
						</Col>
						<Col xs={24} lg={24}>
							<Statistic title='Szállítóleveles nettó' value={stats.yearly.deliveryNet} suffix={'Ft'} precision={0} formatter={(value) => parseFloat(value).toLocaleString('hu')} />
						</Col>
						<Col xs={24} lg={24}>
							<Statistic
								title='Összesen kp.'
								value={+stats.yearly.cashGross + +stats.yearly.deliveryNet}
								suffix={'Ft'}
								valueStyle={{ color: '#ff4f22', fontWeight: 'bold', fontSize: '36px' }}
								precision={0}
								formatter={(value) => parseFloat(value).toLocaleString('hu')}
								style={{ marginTop: '32px' }}
							/>
						</Col>
						<Col xs={24} lg={24}>
							<Statistic
								title='Összesen nettó'
								value={+stats.yearly.wireNet + +stats.yearly.cashNet + +stats.yearly.deliveryNet}
								suffix={'Ft'}
								valueStyle={{ color: '#3f8600', fontWeight: 'bold', fontSize: '36px' }}
								precision={0}
								formatter={(value) => parseFloat(value).toLocaleString('hu')}
								style={{ marginTop: '32px' }}
							/>
						</Col>
					</Row>
				</Card>
			)}
		</>
	);
};

export default DailySummary;
