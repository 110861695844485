import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from './WarehouseComponent.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';

import { Row, Col, Table, Select, Typography } from 'antd';
const { Text } = Typography;

const WarehouseComponent = (props) => {
	const [warehouse, setWarehouse] = useState({});

	useEffect(() => {
		getWarehouse();
	}, [props.updateWarehouse]);

	const getWarehouse = () => {
		server
			.get('/warehouse/' + props.warehouseId)
			.then((response) => {
				setWarehouse(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const tableColumns = [
		{
			title: 'Termék kód',
			render: (record) => <Text>{record.prdCode}</Text>,
			sorter: (a, b) => {
				if (a.prdCode < b.prdCode) {
					return -1;
				}
				if (a.prdCode > b.prdCode) {
					return 1;
				}
			},
		},
		{
			title: 'Termék név',
			render: (record) => <Text>{record.prdName}</Text>,
			sorter: (a, b) => {
				if (a.prdName < b.prdName) {
					return -1;
				}
				if (a.prdName > b.prdName) {
					return 1;
				}
			},
		},
		{
			title: 'Mennyiség',
			render: (record) => (
				<Text>
					{record.warehouseItem.prdQty} {record.prdQtyUnit}
				</Text>
			),
			sorter: (a, b) => {
				if (a.warehouseItem.prdQty < b.warehouseItem.prdQty) {
					return -1;
				}
				if (a.warehouseItem.prdQty > b.warehouseItem.prdQty) {
					return 1;
				}
			},
		},
	];

	return (
		<div>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Table
						dataSource={warehouse.products && warehouse.products.filter((item) => item.warehouseItem.prdQty > 0)}
						columns={tableColumns}
						pagination={{ defaultPageSize: 100 }}
						size='small'
					/>
				</Col>
			</Row>
		</div>
	);
};

export default WarehouseComponent;
