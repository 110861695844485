import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import styles from './Forbidden.module.scss';

import { Result, Button, Row, Col, Card } from 'antd';

const Forbidden = (props) => {
	let history = useHistory();

	useEffect(() => {
		props.setLoading(false);
	}, []);

	return (
		<div className={styles.Forbidden}>
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]} align='middle' className='mt-100'>
					<Col span={24}>
						<Result
							status='403'
							title='Nem rendelkezik megfelelő jogosultsággal az oldal megtekintéséhez'
							subTitle='A probléma megoldásához keresse az adminisztrátort'
							extra={
								<Button type='primary' size='large' onClick={() => history.push('/dashboard')}>
									Vissza az irányítópulthoz
								</Button>
							}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Forbidden);
