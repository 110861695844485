import React, { useState, useEffect } from 'react';
import styles from './NewPartialReceiptModal.module.scss';
import Moment from 'react-moment';

import { Modal, Button, Form, Input, Select, Row, Col, Table, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;
const { Text } = Typography;

const NewPartialReceiptModal = (props) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [relatedReceipts, setRelatedReceipts] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);

	const [form] = Form.useForm();

	useEffect(() => {
		setVisible(props.visible);
	}, [props.visible]);

	useEffect(() => {
		setRelatedProducts(props.purchaseOrder.products);
	}, [props.purchaseOrder.products]);

	useEffect(() => {
		setRelatedReceipts(props.purchaseOrder.receipts);
	}, [props.purchaseOrder.receipts]);

	const handleOk = () => {
		form.resetFields();
		setSelectedProducts([]);
		props.handleReceipt({ data: selectedProducts }, 2);
		props.toggle();
	};

	const handleCancel = () => {
		form.resetFields();
		setSelectedProducts([]);
		props.toggle();
	};

	const handleAddItem = (values) => {
		setLoading(true);
		let currentSelectedProducts = selectedProducts;
		let newSelectedProduct = {
			productId: values.productId,
			prcholnQty: values.productQty,
			prdPrice: props.purchaseOrder.products.find((product) => product.id === values.productId).purchaseOrderLine.prcholnNetPrice,
			productName: props.purchaseOrder.products.find((product) => product.id === values.productId).prdName,
			productCode: props.purchaseOrder.products.find((product) => product.id === values.productId).prdCode,
			enablePriceUpdate: props.purchaseOrder.products.find((product) => product.id === values.productId).purchaseOrderLine.enablePriceUpdate,
		};
		setSelectedProducts([...currentSelectedProducts, newSelectedProduct]);
		setLoading(false);
	};

	const onAddItemFailed = (errorInfo) => {
		setLoading(false);
	};

	const handleDeleteItem = (id) => {
		let currentSelectedProducts = selectedProducts;
		let newSelectedProducts = currentSelectedProducts.filter((product) => product.productId != id);
		setSelectedProducts(newSelectedProducts);
	};

	const handleDeleteReceipt = (id) => {
		props.handleReceiptReset(id, 2);
	};

	const tableColumnsCurrent = [
		{
			title: 'Új kiválasztott termékek',
			render: (text, record) => (
				<Text>
					{record.productCode} - {record.productName}
				</Text>
			),
		},
		{
			title: 'Mennyiség',
			align: 'center',
			render: (text, record) => <Text>{record.prcholnQty}</Text>,
		},
		{
			align: 'center',
			render: (record, index) => {
				return (
					<Button type='danger' size='small' onClick={() => handleDeleteItem(record.productId)}>
						<DeleteOutlined />
					</Button>
				);
			},
		},
	];

	const tableColumnsReceipts = [
		{
			title: 'Korábbi beérkeztetések azonosítója',
			render: (text, record) => <Text>{record.id}</Text>,
		},
		{
			title: 'Dátum',
			render: (text, record) => (
				<Text>
					{' '}
					<Moment format='YYYY/MM/DD - HH:mm' local>
						{record.createdAt}
					</Moment>
				</Text>
			),
		},
		{
			align: 'center',
			render: (record, index) => {
				return (
					<Button type='danger' size='small' onClick={() => handleDeleteReceipt(record.id)}>
						<DeleteOutlined />
					</Button>
				);
			},
		},
	];

	return (
		<Modal
			className={styles.NewPartialReceiptModal}
			title='Részleges árubeérkeztetések'
			visible={visible}
			onOk={handleOk}
			confirmLoading={loading}
			onCancel={handleCancel}
			width={700}
			cancelText='Kilépés'
			okText='Mentés'
			okButtonProps={{ disabled: selectedProducts.length === 0 }}>
			<Form layout='vertical' name='basic' form={form} onFinish={handleAddItem} onFinishFailed={onAddItemFailed}>
				<Row gutter={[16]}>
					<Col xs={16}>
						<Form.Item label='Termék' name='productId' rules={[{ required: true, message: 'Kötelező mező' }]}>
							<Select showSearch optionFilterProp='children' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
								{relatedProducts.map((product) => {
									return (
										<Option key={product.id} value={product.id}>
											{product.prdCode + ' - ' + product.prdName}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col xs={4}>
						<Form.Item label='Mennyiség' name='productQty' rules={[{ required: true, message: 'Kötelező mező' }]}>
							<Input type="number"/>
						</Form.Item>
					</Col>
					<Col xs={4}>
						<Form.Item label=' '>
							<Button type='primary' htmlType='submit'>
								Hozzáadás
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			{selectedProducts.length > 0 && (
				<Row>
					<Col xs={24}>
						<Table dataSource={selectedProducts} columns={tableColumnsCurrent} pagination={{ defaultPageSize: 100 }} size='middle' />
					</Col>
				</Row>
			)}
			{typeof relatedReceipts === 'undefined' ? null : relatedReceipts.length > 0 ? (
				<Row>
					<Col xs={24}>
						<Table dataSource={relatedReceipts} columns={tableColumnsReceipts} pagination={{ defaultPageSize: 100 }} size='middle' />
					</Col>
				</Row>
			) : null}
		</Modal>
	);
};

export default NewPartialReceiptModal;
