import server from './axios/api';
import notification from './notification/notification';
import appData from '../../package.json'

const errorHandler = (error) => {
	//	notification('error', 'Hiba', error.customMessage || error.message);
	server
		.post('/error', {
			statusCode: error.statusCode,
			message: error.message,
			stack: error.stack,
			appVersion: appData.version
		})
		.then(() => {
			notification('error', 'Hiba', error.message);
			console.log(error);
		});
};

export default errorHandler;
