import React from 'react';
import styles from './PurchaseOrderTotal.module.scss';

import { Statistic, Row, Col, Card } from 'antd';

const PurchaseOrderTotal = (props) => {
	return (
		<>
			<Col className={styles.PurchaseOrderTotal} xs={24}>
				<Card>
					<Row gutter={[50, 16]} justify='space-between'>
						<Col>
							<Row gutter={[50, 16]} justify='start'>
								<Col>
									<Statistic suffix='kg' title='Összes súly' value={props.purchaseOrder.prchordSumWeight} formatter={(value) => value?.toLocaleString('hu')} />
								</Col>
								<Col>
									<Statistic
										suffix={
											<div>
												m<sup>3</sup>
											</div>
										}
										title='Összes térforat'
										value={props.purchaseOrder.prchordSumVolume}
										formatter={(value) => value?.toLocaleString('hu')}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row gutter={[50, 16]} justify='end'>
								<Col>
									<Statistic suffix='Ft' title='Összesen nettó' value={props.purchaseOrder.prchordNetPrice} formatter={(value) => Math.round(value)?.toLocaleString('hu')} />
								</Col>
								<Col>
									<Statistic suffix='Ft' title='ÁFA 27%' value={props.purchaseOrder.prchordNetPrice * 0.27} formatter={(value) => Math.round(value)?.toLocaleString('hu')} />
								</Col>
								<Col>
									<Statistic
										valueStyle={{ color: '#3f8600' }}
										suffix='Ft'
										title='Összesen bruttó'
										value={props.purchaseOrder.prchordNetPrice * 1.27}
										formatter={(value) => Math.round(value).toLocaleString('hu')}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			</Col>
		</>
	);
};

export default PurchaseOrderTotal;
