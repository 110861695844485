import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './Warehouse.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import Moment from 'react-moment';

import PageGuard from '../../../components/PageGuard/PageGuard';
import SearchBar from '../../../components/SearchBar/SearchBar';

import { PageHeader, Button, Row, Col, Card, Form, Input, Select, Modal, Typography, Table, Tabs, Skeleton } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const { confirm } = Modal;
const { Text } = Typography;
const { TabPane } = Tabs;

const pagePath = 'warehouse';
const defaultPageSize = 50;

const searchDataProducts = {
	searchFields: [
		{ key: 'prdCode', name: 'Termékkód' },
		{ key: 'prdName', name: 'Terméknév' },
		{ key: 'prdNetAvgPrice', name: 'Átlagár' },
		{ key: 'prdLastPrice', name: 'Utolsó beszerzési ár' },
	],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const searchDataWarehouseTransfers = {
	searchFields: [
		{ key: 'product.prdCode', name: 'Termékkód' },
		{ key: 'product.prdName', name: 'Terméknév' },
		{ key: 'user.firstName', name: 'Végrehajtó', type: 'dropdown', list: 'user', value: 'firstName', text: 'fullName' },
	],
	lists: ['user'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const Warehouse = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [warehouse, setWarehouse] = useState({});
	const [warehouseForm] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [currentType, setCurrentType] = useState(1);
	const [listUsers, setListUsers] = useState([]);
	const [productList, setProductList] = useState([]);
	const [transferList, setTransferList] = useState([]);
	const [queryParamsProducts, setQueryParamsProducts] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchDataProducts.searchValues },
	});
	const [queryParamsTransfers, setQueryParamsTransfers] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchDataWarehouseTransfers.searchValues },
	});
	const [loading, setLoading] = useState({
		searchProducts: false,
		searchWarehouseTransfers: false,
	});

	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		getWarehouseProducts();
	}, [queryParamsProducts]);

	useEffect(() => {
		getWarehouseTransfers();
	}, [queryParamsTransfers]);

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getWarehouse();
		getWarehouseProducts();
		getWarehouseTransfers();
	}, [params.id]);

	useEffect(() => {
		server
			.get('/list/user')
			.then((response) => {
				const filteredUsers = response.data.filter((item) => item.userRole.key === 'sales');
				setListUsers(filteredUsers);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const getWarehouse = () => {
		server
			.get('/warehouse/' + params.id)
			.then((response) => {
				setWarehouse(response.data);
				setCurrentType(response.data.wrhType);
				setProductList(response.data.products);
				setTransferList(response.data.warehouseTransfers);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getWarehouseProducts = () => {
		setLoading({ ...loading, searchProducts: true });
		server
			.get(
				`/warehouse/products/${params.id}?limit=${queryParamsProducts.limit}&offset=${queryParamsProducts.offset}&search=${JSON.stringify(
					queryParamsProducts.search
				)}&fields=${JSON.stringify(searchDataProducts.searchFields)}`
			)
			.then((response) => {
				setProductList(response.data?.products);
				setLoading({ ...loading, searchProducts: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getWarehouseTransfers = () => {
		setLoading({ ...loading, searchWarehouseTransfers: true });
		server
			.get(
				`/warehouse/transfers/${params.id}?limit=${queryParamsTransfers.limit}&offset=${queryParamsTransfers.offset}&search=${JSON.stringify(
					queryParamsTransfers.search
				)}&fields=${JSON.stringify(searchDataWarehouseTransfers.searchFields)}`
			)
			.then((response) => {
				setTransferList(response.data.warehouseTransfers);
				setLoading({ ...loading, searchProducts: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleSaveButtonClick = () => {
		warehouseForm.submit();
	};

	const handleDeleteButtonClick = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a raktárat?',
			icon: <ExclamationCircleOutlined />,
			content: warehouse.wrhName,
			okText: 'Törlés',
			cancelText: 'Mégse',
			onOk() {
				server
					.delete('/warehouse/' + params.id)
					.then((response) => {
						notification('success', 'Raktár sikeresen törölve', warehouse.wrhName);
						history.push('/warehouses');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleWarehouseFormSubmit = (values) => {
		server
			.patch('/warehouse/' + params.id, values)
			.then((response) => {
				getWarehouse();
				notification('success', 'Raktár sikeresen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleTypeChange = (e) => {
		setCurrentType(e);
	};

	const tableColumnsTransfers = [
		{
			title: 'Kód',
			align: 'center',
			render: (record) => <Text style={{ textTransform: 'uppercase' }}>{record.transferCode}</Text>,
		},
		{
			title: 'Termék kód',
			render: (record) => <Text>{record.product.prdCode}</Text>,
			sorter: (a, b) => {
				if (a.product.prdCode < b.product.prdCode) {
					return -1;
				}
				if (a.product.prdCode > b.product.prdCode) {
					return 1;
				}
			},
		},
		{
			title: 'Termék',
			render: (record) => <Text>{record.product.prdName}</Text>,
			sorter: (a, b) => {
				if (a.product.prdName < b.product.prdName) {
					return -1;
				}
				if (a.product.prdName > b.product.prdName) {
					return 1;
				}
			},
		},
		{
			title: 'Mennyiség',
			align: 'center',
			render: (record) => (
				<Text type={record.transferType === 1 ? 'success' : 'danger'}>
					{record.transferType === 1 ? record.prdQty : '-' + record.prdQty} {record.product.prdQtyUnit}
				</Text>
			),
		},
		{
			title: 'Átlagár',
			align: 'center',
			render: (record) => {
				if (props.store.auth.user.userRole.accessLevel <= 29) {
					return <Text>{record.product.prdNetAvgPrice === null ? 0 : record.product.prdNetAvgPrice.toLocaleString('hu')}</Text>;
				} else {
					return <Text>-</Text>;
				}
			},
			sorter: (a, b) => {
				if (a.product.prdNetAvgPrice < b.product.prdNetAvgPrice) {
					return -1;
				}
				if (a.product.prdNetAvgPrice > b.product.prdNetAvgPrice) {
					return 1;
				}
			},
		},
		{
			title: 'Összár',
			align: 'center',
			render: (record) => {
				if (props.store.auth.user.userRole.accessLevel <= 29) {
					return <Text>{record.product.prdNetAvgPrice === null ? 0 : (record.product.prdNetAvgPrice * record.prdQty).toLocaleString('hu')}</Text>;
				} else {
					return <Text>-</Text>;
				}
			},
			sorter: (a, b) => {
				if (a.product.prdNetAvgPrice < b.product.prdNetAvgPrice) {
					return -1;
				}
				if (a.product.prdNetAvgPrice > b.product.prdNetAvgPrice) {
					return 1;
				}
			},
		},
		{
			title: 'Végrehajtó',
			render: (record) => <Text>{record.user.fullName}</Text>,
		},
		{
			title: 'Időpont',
			render: (record) => (
				<Text type='secondary'>
					<Moment format='YYYY/MM/DD - HH:mm' local>
						{record.createdAt}
					</Moment>
				</Text>
			),
			sorter: (a, b) => {
				if (a.createdAt < b.createdAt) {
					return -1;
				}
				if (a.createdAt > b.createdAt) {
					return 1;
				}
			},
			sortOrder: 'DESC',
		},
	];

	const tableColumnsItems = [
		{
			title: 'Termék kód',
			render: (record) => <Text>{record.prdCode}</Text>,
			sorter: (a, b) => {
				if (a.prdCode < b.prdCode) {
					return -1;
				}
				if (a.prdCode > b.prdCode) {
					return 1;
				}
			},
		},
		{
			title: 'Termék',
			render: (record) => <Text>{record.prdName}</Text>,
			sorter: (a, b) => {
				if (a.prdName < b.prdName) {
					return -1;
				}
				if (a.prdName > b.prdName) {
					return 1;
				}
			},
		},
		{
			title: 'Készleten',
			align: 'center',
			render: (record) => (
				<Text>
					{record.warehouseItem.prdQty} {record.prdQtyUnit}
				</Text>
			),
		},
		{
			title: 'Beszerzés alatt',
			align: 'center',
			render: (record) => {
				let filteredPurchaseOrders = record.purchaseOrders.filter((purchaseOrder) => purchaseOrder.purchaseOrderStatus.key != 100 && purchaseOrder.warehouseId === warehouse.id);
				let currentAmount = filteredPurchaseOrders.reduce((a, b) => a + parseFloat(b.purchaseOrderLine.prcholnQty), 0);
				return currentAmount;
			},
		},
/* 		{
			title: props.store.auth.user.userRole.accessLevel > 20 ? 'Listaár' : 'Súlyozott átlagár',
			align: 'center',
			render: (record) => (
				<Text>
					{record.prdNetPrice === null || record.prdNetAvgPrice === null
						? (0).toFixed(2)
						: props.store.auth.user.userRole.accessLevel > 20
						? record.prdNetPrice.toFixed(2)
						: record.prdNetAvgPrice.toFixed(2)}{' '}
					Ft
				</Text>
			),
			sorter: (a, b) => {
				if (a.prdNetAvgPrice < b.prdNetAvgPrice) {
					return -1;
				}
				if (a.prdNetAvgPrice > b.prdNetAvgPrice) {
					return 1;
				}
			},
		}, */
		{
			title: 'Utolsó beszerzési ár',
			align: 'center',
			render: (record) => {
				if (props.store.auth.user.userRole.accessLevel <= 20) {
					return <Text>{record.prdLastPrice === null ? (0).toFixed(2) : record.prdLastPrice.toFixed(2)} Ft</Text>;
				} else {
					return <Text>-</Text>;
				}
			},
			sorter: (a, b) => {
				if (a.prdLastPrice < b.prdLastPrice) {
					return -1;
				}
				if (a.prdLastPrice > b.prdLastPrice) {
					return 1;
				}
			},
		},
	];

	return (
		<PageGuard path={pagePath} data={warehouse}>
			<div className={styles.Warehouse}>
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={warehouse.wrhName}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button
							key='2'
							type='primary'
							onClick={handleDeleteButtonClick}
							danger
							disabled={warehouse.warehouseTransfers ? warehouse.warehouseTransfers.length !== 0 && true : false}>
							Törlés
						</Button>,
						<Button key='1' type='primary' onClick={handleSaveButtonClick}>
							Mentés
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						{props.store.auth.user.userRole.key === 'sales' ? null : (
							<Col span={24}>
								<Form
									key={warehouse.id}
									form={warehouseForm}
									layout='vertical'
									initialValues={{
										requiredMark,
										...warehouse,
									}}
									onValuesChange={onRequiredTypeChange}
									requiredMark={requiredMark}
									onFinish={handleWarehouseFormSubmit}>
									<Card title='Alapadatok'>
										<Form.Item
											label='Név'
											name='wrhName'
											rules={[
												{
													required: true,
													message: 'Kötelező mező',
												},
											]}>
											<Input />
										</Form.Item>
										<Form.Item
											label='Kategória'
											name='wrhType'
											rules={[
												{
													required: true,
													message: 'Kötelező mező',
												},
											]}>
											<Select
												showSearch
												defaultValue={1}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												onChange={handleTypeChange}>
												<Option key='1' value={1}>
													Épület
												</Option>
												<Option key='2' value={2}>
													Autó
												</Option>
											</Select>
										</Form.Item>
										<Form.Item
											label='Adókategória'
											name='wrhTaxClass'
											rules={[
												{
													required: true,
													message: 'Kötelező mező',
												},
											]}>
											<Select
												showSearch
												defaultValue={1}
												optionFilterProp='value'
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												<Option key='1' value={1}>
													A kategória
												</Option>
												<Option key='2' value={2}>
													B kategória
												</Option>
											</Select>
										</Form.Item>
										<Form.Item
											label='Hozzárendelt értékesítő'
											hidden={currentType === 1 ? true : false}
											name='userId'
											rules={[
												{
													required: true,
													message: 'Kötelező mező',
												},
											]}>
											<Select showSearch optionFilterProp='value' filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												{listUsers.map((user) => {
													return (
														<>
															<Option key={user.id} value={user.id}>
																{user.fullName}
															</Option>
														</>
													);
												})}
											</Select>
										</Form.Item>
									</Card>
								</Form>
							</Col>
						)}

						<Col span={24}>
							<Card bodyStyle={{ paddingTop: 0 }}>
								<Tabs defaultActiveKey='1' size='large'>
									<TabPane tab={<span>Raktárkészlet</span>} key='1'>
										<SearchBar
											defaultPageSize={defaultPageSize}
											queryParams={queryParamsProducts}
											action={setQueryParamsProducts}
											searchFields={searchDataProducts.searchFields}
											lists={searchDataProducts.lists}
											loading={loading}
											setLoading={setLoading}
										/>
										<Table
											dataSource={productList}
											columns={tableColumnsItems}
											pagination={{ defaultPageSize: 100 }}
											size='small'
											loading={loading.searchProducts}
											style={{ marginTop: '16px' }}
										/>
									</TabPane>
									<TabPane tab={<span>Árumozgások</span>} key='2'>
										<SearchBar
											defaultPageSize={defaultPageSize}
											queryParams={queryParamsTransfers}
											action={setQueryParamsTransfers}
											searchFields={searchDataWarehouseTransfers.searchFields}
											lists={searchDataWarehouseTransfers.lists}
											loading={loading}
											setLoading={setLoading}
										/>
										<Table
											dataSource={transferList}
											columns={tableColumnsTransfers}
											pagination={{ defaultPageSize: 100 }}
											size='small'
											loading={loading.searchWarehouseTransfers}
											style={{ marginTop: '16px' }}
										/>
									</TabPane>
								</Tabs>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(Warehouse, pagePath));
