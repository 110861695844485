import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './PurchaseOrderPayments.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import Moment from 'react-moment';
import zeroFill from 'zero-fill';
import PageGuard from '../../components/PageGuard/PageGuard';
import moment from 'moment';
import notification from '../../utils/notification/notification';

import NewOrderModal from '../../components/Modal/NewOrderModal/NewOrderModal';
import SearchBar from '../../components/SearchBar/SearchBar';

import { PageHeader, Button, Row, Col, Card, Table, Typography, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const { confirm } = Modal;

const pagePath = 'purchaseorderpayments';
const defaultPageSize = 50;

const searchData = {
	searchFields: [
		{ key: 'vendor.companyName', name: 'Beszállító' },
		{ key: 'prchordDate', name: 'Megrendelés dátuma (pl: 2020-04-21)' },
		{ key: 'prchordDescription', name: 'Megjegyzés' },
		{ key: 'purchaseOrderStatusId', name: 'Státusz', type: 'dropdown', list: 'purchaseOrderStatus', value: 'id', text: 'name' },
	],
	lists: ['purchaseOrderStatus'],
	get searchValues() {
		return Object.assign(
			{},
			...this.searchFields.map((field) => {
				return {
					[field.key]: '',
				};
			})
		);
	},
};

const PurchaseOrderPayments = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [purchaseOrders, setPurchaseOrders] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [total, setTotal] = useState();
	const [queryParams, setQueryParams] = useState({
		limit: defaultPageSize,
		offset: '',
		search: { ...searchData.searchValues },
	});
	const [loading, setLoading] = useState({
		payment: false,
		search: false,
	});

	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getPurchaseOrders();
	}, [queryParams]);

	const getPurchaseOrders = () => {
		server
			.get(
				`/purchase-order/payments?limit=${queryParams.limit}&offset=${queryParams.offset}&search=${JSON.stringify(queryParams.search)}&fields=${JSON.stringify(
					searchData.searchFields
				)}`
			)
			.then((response) => {
				setPurchaseOrders(response.data.rows);
				setTotal(response.data.count);
				setLoading({ ...loading, data: false });
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			purchaseOrders.map((order) => {
				if (list.find((item) => item.value == order[column1][column2])) {
					return null;
				} else {
					return list.push({ text: order[column1][column2], value: order[column1][column2] });
				}
			});
		} else {
			purchaseOrders.map((order) => {
				if (list.find((item) => item.value == order[column1])) {
					return null;
				} else {
					return list.push({ text: order[column1], value: order[column1] });
				}
			});
		}
		return list;
	};

	const handlePaymentClick = (purchaseOrder) => {
		confirm({
			title: 'Biztos, hogy kiegyenlítették a számlát?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Igen',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				setLoading({ payment: true });
				server
					.patch('/purchase-order/payment/' + purchaseOrder.id, { prchordPaymentStatus: 1 })
					.then((response) => {
						setLoading({ payment: false });
						notification('success', 'Beszerzés sikeresen módostva', response.data.id);
						getPurchaseOrders();
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handlePagination = (page, pageSize) => {
		setQueryParams({
			...queryParams,
			limit: pageSize,
			offset: page === 1 ? '' : (page - 1) * pageSize,
		});
	};

	const handleClick = (id) => {
		window.open('/purchaseorder/' + id);
	};

	const tableColumns = [
		{
			title: 'Azonosító',
			render: (text, record) => (
				<Text style={{ cursor: 'pointer' }} onClick={() => handleClick(record.id)} strong>
					#{zeroFill(6, record.id)}
				</Text>
			),
			sorter: (a, b) => {
				if (a.prchordNo < b.prchordNo) {
					return -1;
				}
				if (a.prchordNo > b.prchordNo) {
					return 1;
				}
			},
			filters: listHandler('id'),
			onFilter: (value, record) => record.id.toString().indexOf(value.toString()) === 0,
		},
		{
			title: 'Ügyfél',
			render: (record) => record.vendor.companyName || '',
			sorter: (a, b) => {
				if (a.vendor.companyName < b.vendor.companyName) {
					return -1;
				}
				if (a.vendor.companyName > b.vendor.companyName) {
					return 1;
				}
			},
		},
		{
			title: 'Megrendelés dátuma',
			render: (record) =>
				(
					<Text>
						<Moment format='YYYY/MM/DD' local>
							{record.prchordDate}
						</Moment>
					</Text>
				) || '',
			sorter: (a, b) => {
				if (a.prchordDate < b.prchordDate) {
					return -1;
				}
				if (a.prchordDate > b.prchordDate) {
					return 1;
				}
			},
			responsive: ['lg'],
		},
		{
			title: 'Fizetési határidő',
			render: (record) =>
				(
					<Text
						type={
							record.prchordPaymentStatus
								? 'secondary'
								: moment(record.prchordPaymentDeadline).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD')
								? 'danger'
								: moment(record.prchordPaymentDeadline).format('YYYY/MM/DD') <= moment().add(3, 'days').format('YYYY/MM/DD')
								? 'warning'
								: 'secondary'
						}
						strong>
						{moment(record.prchordPaymentDeadline).format('YYYY/MM/DD')}
					</Text>
				) || '',
			sorter: (a, b) => {
				if (
					moment(a.prchordShipDate)
						.add(a.vendor.billingDueDays || 8, 'days')
						.format('YYYY/MM/DD') <
					moment(b.prchordShipDate)
						.add(b.vendor.billingDueDays || 8, 'days')
						.format('YYYY/MM/DD')
				) {
					return -1;
				}
				if (
					moment(a.prchordShipDate)
						.add(a.vendor.billingDueDays || 8, 'days')
						.format('YYYY/MM/DD') >
					moment(b.prchordShipDate)
						.add(b.vendor.billingDueDays || 8, 'days')
						.format('YYYY/MM/DD')
				) {
					return 1;
				}
			},
			responsive: ['xl'],
		},
		{
			title: 'Érték',
			render: (record) => <Text type='success'>{record.prchordNetPrice ? record.prchordNetPrice.toLocaleString('hu') : 0} Ft</Text>,
			sorter: (a, b) => {
				if (a.user.fullName < b.user.fullName) {
					return -1;
				}
				if (a.user.fullName > b.user.fullName) {
					return 1;
				}
			},
		},
		{
			title: 'Státusz',
			render: (record) => <Text>{record.purchaseOrderStatus.name}</Text>,
			sorter: (a, b) => {
				if (a.purchaseOrderStatus.name < b.purchaseOrderStatus.name) {
					return -1;
				}
				if (a.purchaseOrderStatus.name > b.purchaseOrderStatus.name) {
					return 1;
				}
			},
		},
		{
			title: '',
			render: (record) => (
				<Button
					key={record.id}
					type='primary'
					onClick={() => handlePaymentClick(record)}
					loading={loading.payment}
					size='large'
					style={{
						border: 'none',
						backgroundColor: record.ordPaymentStatus
							? 'green'
							: moment(record.prchordPaymentDeadline).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD')
							? 'red'
							: moment(record.prchordPaymentDeadline).format('YYYY/MM/DD') <= moment().add(3, 'days').format('YYYY/MM/DD')
							? 'orange'
							: 'darkgray',
					}}>
					Fizetve
				</Button>
			),
		},
	];

	return (
		<PageGuard path={pagePath} data={purchaseOrders}>
			<div className={styles.PurchaseOrderPayments}>
				<NewOrderModal visible={modalVisible} toggle={() => setModalVisible(!modalVisible)} store={props.store} getPurchaseOrders={getPurchaseOrders} />
				<PageHeader className={styles.pageHeader} onBack={() => window.history.back()} title={pageDetails.title} subTitle={pageDetails.subtitle} />
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<SearchBar
								defaultPageSize={defaultPageSize}
								queryParams={queryParams}
								action={setQueryParams}
								searchFields={searchData.searchFields}
								lists={searchData.lists}
								loading={loading}
								setLoading={setLoading}
							/>
						</Col>
						<Col span={24}>
							<Card>
								<Table
									dataSource={purchaseOrders}
									columns={tableColumns}
									size='large'
									pagination={{ onChange: (page, pageSize) => handlePagination(page, pageSize), total, defaultPageSize }}
									loading={loading.data}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(PurchaseOrderPayments, pagePath));
