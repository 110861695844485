import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/billingo',
});

instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
instance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance;
