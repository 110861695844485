import React, { useState, useEffect } from 'react';
import hoc from '../../../hoc/PageContainer';
import styles from './ProductTransfer.module.scss';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';

import { PageHeader, Row, Col, Card } from 'antd';

import ProductTransferComponent from '../../../components/ProductTransferComponent/ProductTransferComponent';
import WarehouseComponent from '../../../components/WarehouseComponent/WarehouseComponent';

const pagePath = 'producttransfer';

const ProductTransfer = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [updateWarehouse, setUpdateWarehouse] = useState();

	useEffect(() => {
		props.setLoading(false);
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	return (
		<div className={styles.ProductTransfer}>
			<PageHeader className={styles.pageHeader} onBack={() => window.history.back()} title={pageDetails.title} subTitle={pageDetails.subtitle} />
			<div className={styles.pageBody}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card>
							<ProductTransferComponent store={props.store} updateWarehouse={setUpdateWarehouse} />
						</Card>
					</Col>
					{props.store.auth.user.userRole.key === 'sales' ? (
						<Col span={24}>
							<Card title={`Autó árukészlet (${props.store.auth.user.warehouse.wrhName})`}>
								<WarehouseComponent store={props.store} warehouseId={props.store.auth.user.warehouse.id} updateWarehouse={updateWarehouse} />
							</Card>
						</Col>
					) : null}
				</Row>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(ProductTransfer, pagePath));
