import React from 'react';
import styles from './Sidebar.module.scss';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { useHistory, useLocation, Link } from 'react-router-dom';
import logoImg from '../../assets/img/logo_img.svg';
import logoTextWhite from '../../assets/img/logo_text_white.svg';
import logoTextBlack from '../../assets/img/logo_text_black.png';

import { Layout, Menu } from 'antd';
import {
	DesktopOutlined,
	TeamOutlined,
	DollarOutlined,
	LoginOutlined,
	PlusCircleOutlined,
	CheckCircleOutlined,
	AppstoreOutlined,
	UnorderedListOutlined,
	CopyOutlined,
	PieChartOutlined,
	SettingOutlined,
} from '@ant-design/icons';

import menuSource from '../../data/menu.json';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
	let history = useHistory();
	let location = useLocation();

	const menuData = menuSource.modules.filter((item) => item.accessLevel >= props.store.auth.user.userRole.accessLevel);

	let logoText =
		props.store.ui.theme === 'light' ? (
			<img src={logoTextBlack} className={styles.logoText} alt={props.store.system.appName} />
		) : (
			<img src={logoTextWhite} className={styles.logoText} alt={props.store.system.appName} />
		);

	const onCollapse = () => {
		props.setSidebarState({ collapsed: !props.store.sidebar.collapsed });
	};

	const menuItemClickHandler = ({ key }) => {
		history.push('/' + key);
	};

	const menuItems = menuData.map((item) => {
		const icon = () => {
			switch (item.icon) {
				case 'DesktopOutlined':
					return <DesktopOutlined />;
				case 'TeamOutlined':
					return <TeamOutlined />;
				case 'DollarOutlined':
					return <DollarOutlined />;
				case 'LoginOutlined':
					return <LoginOutlined />;
				case 'PlusCircleOutlined':
					return <PlusCircleOutlined />;
				case 'CheckCircleOutlined':
					return <CheckCircleOutlined />;
				case 'AppstoreOutlined':
					return <AppstoreOutlined />;
				case 'UnorderedListOutlined':
					return <UnorderedListOutlined />;
				case 'CopyOutlined':
					return <CopyOutlined />;
				case 'PieChartOutlined':
					return <PieChartOutlined />;
				case 'SettingOutlined':
					return <SettingOutlined />;
				default:
					return <DesktopOutlined />;
			}
		};

		if (item.hasChildren) {
			const menuDataFiltered = menuSource.items.filter((filteredItem) => filteredItem.parent === item.key);

			return (
				<SubMenu key={item.key} icon={icon()} title={item.title}>
					{menuDataFiltered.map((subItem) => {
						if (subItem.children) {
							return (
								<SubMenu key={subItem.key} title={subItem.title}>
									{subItem.children.map((childItem) => {
										return <Menu.Item key={childItem.key}>{childItem.title}</Menu.Item>;
									})}
								</SubMenu>
							);
						}

						return <Menu.Item key={subItem.key}>{subItem.title}</Menu.Item>;
					})}
				</SubMenu>
			);
		}

		return (
			<Menu.Item key={item.key} icon={icon()}>
				{item.title}
			</Menu.Item>
		);
	});

	return (
		<Sider className={styles.Sider} theme={props.store.ui.theme} width={240} breakpoint='lg' collapsible collapsed={props.store.sidebar.collapsed} onCollapse={onCollapse}>
			<div className={styles.logoContainer}>
				<div>
					<Link to='/'>
						<img src={logoImg} className={props.store.sidebar.collapsed ? styles.logoImgCollapsed : styles.logoImgFull} alt={props.store.system.appName} />
					</Link>
				</div>
				{!props.store.sidebar.collapsed && (
					<div>
						<Link to='/'>{logoText}</Link>
					</div>
				)}
			</div>
			<Menu
				className={styles.menu}
				theme={props.store.ui.theme}
				defaultSelectedKeys={[location.pathname === '/' ? location.pathname : location.pathname.substring(1)]}
				mode='inline'
				onClick={menuItemClickHandler}>
				{menuItems}
			</Menu>
		</Sider>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setSidebarState: (params) => {
			dispatch(actionCreators.set_sidebar_state(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(Sidebar);
