import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/actions';
import { Link } from 'react-router-dom';
import hoc from '../../hoc/PageContainer';
import styles from './PriceLists.module.scss';
import server from '../../utils/axios/api';
import errorHandler from '../../utils/error-handler';
import Moment from 'react-moment';

import PageGuard from '../../components/PageGuard/PageGuard';

import NewPriceListModal from '../../components/Modal/NewPriceListModal/NewPriceListModal';

import { PageHeader, Button, Row, Col, Card, Table, Typography } from 'antd';
const { Text } = Typography;

const pagePath = 'pricelists';

const PriceLists = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [priceLists, setPriceLists] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [listCustomers, setListCustomers] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getPriceLists();
	}, []);

	const getPriceLists = () => {
		server
			.get('/price-list')
			.then((response) => {
				setPriceLists(response.data);
				getCustomers();
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getCustomers = () => {
		server
			.get('/customer/all/nonwebshop?status=1')
			.then((response) => {
				setCustomers(response.data.rows);
				let modifiedResponse = response.data.rows.map((customer) => {
					return {
						text: customer.companyName,
						value: customer.id,
					};
				});
				setListCustomers(modifiedResponse);
				props.setLoading(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const listHandler = (column1, column2) => {
		let list = [];
		if (column2) {
			priceLists.map((order) => {
				if (list.find((item) => item.value == order[column1][column2])) {
					return null;
				} else {
					return list.push({ text: order[column1][column2], value: order[column1][column2] });
				}
			});
		} else {
			priceLists.map((order) => {
				if (list.find((item) => item.value == order[column1])) {
					return null;
				} else {
					return list.push({ text: order[column1], value: order[column1] });
				}
			});
		}
		return list;
	};

	const tableColumns = [
		{
			title: 'Ügyfél',
			render: (record, index) => {
				let currentCustomer = customers.find((customer) => customer.id === record.customerId);
				return (
					<Link to={'/pricelist/' + record._id} target="_blank" rel="noopener noreferrer">
						<Text style={{ cursor: 'pointer' }} strong>
							{typeof currentCustomer === 'undefined' ? null : currentCustomer.companyName}
						</Text>
					</Link>
				);
			},
			sorter: (a, b) => {
				let currentCustomerA = customers.find((customer) => customer.id === a.customerId);
				let currentCustomerB = customers.find((customer) => customer.id === b.customerId);
				if (currentCustomerA.companyName < currentCustomerB.companyName) {
					return -1;
				}
				if (currentCustomerA.companyName > currentCustomerB.companyName) {
					return 1;
				}
			},
			filters: listCustomers,
			onFilter: (value, record) => record.customerId.toString().indexOf(value.toString()) === 0,
		},
		{
			title: 'Adószám',
			render: (record, index) => {
				let currentCustomer = customers.find((customer) => customer.id === record.customerId);
				return (
					<Text>
						{typeof currentCustomer === 'undefined' ? null : currentCustomer.taxID}
					</Text>
				);
			},
			sorter: (a, b) => {
				let currentCustomerA = customers.find((customer) => customer.id === a.customerId);
				let currentCustomerB = customers.find((customer) => customer.id === b.customerId);
				if (currentCustomerA.taxID < currentCustomerB.taxID) {
					return -1;
				}
				if (currentCustomerA.taxID > currentCustomerB.taxID) {
					return 1;
				}
			}
		},
		{
			title: 'Város',
			render: (record, index) => {
				let currentCustomer = customers.find((customer) => customer.id === record.customerId);
				return (
					<Text>
						{typeof currentCustomer === 'undefined' ? null : currentCustomer.billingCity}
					</Text>
				);
			},
			sorter: (a, b) => {
				let currentCustomerA = customers.find((customer) => customer.id === a.customerId);
				let currentCustomerB = customers.find((customer) => customer.id === b.customerId);
				if (currentCustomerA.billingCity < currentCustomerB.billingCity) {
					return -1;
				}
				if (currentCustomerA.billingCity > currentCustomerB.billingCity) {
					return 1;
				}
			}
		},
		{
			title: 'Értékesítő',
			render: (record, index) => {
				let currentCustomer = customers.find((customer) => customer.id === record.customerId);
				return (
					<Text>
						{typeof currentCustomer === 'undefined' ? null : currentCustomer.user.fullName}
					</Text>
				);
			},
			sorter: (a, b) => {
				let currentCustomerA = customers.find((customer) => customer.id === a.customerId);
				let currentCustomerB = customers.find((customer) => customer.id === b.customerId);
				if (currentCustomerA.user.fullName < currentCustomerB.user.fullName) {
					return -1;
				}
				if (currentCustomerA.user.fullName > currentCustomerB.user.fullName) {
					return 1;
				}
			}
		},
		{
			title: 'Létrehozás dátuma',
			render: (record, index) => {
				return (
					<Moment format='YYYY/MM/DD' local>
						{record.createdAt}
					</Moment>
				);
			},
			sorter: (a, b) => {
				if (a.createdAt < b.createdAt) {
					return -1;
				}
				if (a.createdAt > b.createdAt) {
					return 1;
				}
			}
		},
	];

	return (
		<PageGuard path={pagePath} data={priceLists}>
			<div className={styles.PriceLists}>
				<NewPriceListModal toggle={() => setModalVisible(!modalVisible)} store={props.store} visible={modalVisible} getPriceLists={getPriceLists} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={pageDetails.title}
					subTitle={pageDetails.subtitle}
					extra={[
						<Button
							key='1'
							type='primary'
							onClick={() => {
								setModalVisible(true);
							}}>
							Új árlista
						</Button>,
					]}
				/>
				<div className={styles.pageBody}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card>
								<Table dataSource={priceLists} columns={tableColumns} pagination={{ defaultPageSize: 100 }} size='middle' />
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(PriceLists, pagePath));
