import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/actions';
import { useParams, useHistory } from 'react-router-dom';
import hoc from '../../../hoc/PageContainer';
import styles from './PurchaseOrder.module.scss';
import server from '../../../utils/axios/api';
import errorHandler from '../../../utils/error-handler';
import notification from '../../../utils/notification/notification';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/hu_HU';
import zeroFill from 'zero-fill';

import PageGuard from '../../../components/PageGuard/PageGuard';

import OrderLoader from '../../../components/OrderLoader/OrderLoader';
import PurchaseOrderLines from '../../../components/PurchaseOrders/PurchaseOrderLines/PurchaseOrderLines';
import PurchaseOrderTotal from '../../../components/PurchaseOrders/PurchaseOrderTotal/PurchaseOrderTotal';
import Receipts from '../../../components/PurchaseOrders/Receipts/Receipts';
import NewPartialReceiptModal from '../../../components/Modal/NewPartialReceiptModal/NewPartialReceiptModal';

import { PageHeader, Button, Row, Col, Card, Input, Form, Select, Modal, DatePicker, Typography, Tag, Upload } from 'antd';
import { ExclamationCircleOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
const { confirm } = Modal;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

const pagePath = 'purchaseorder';

const PurchaseOrder = (props) => {
	const [pageDetails, setPageDetails] = useState({});
	const [purchaseOrder, setPurchaseOrder] = useState({ vendor: {}, user: {}, products: [{ purchaseOrderLine: {} }] });
	const [receiptProducts, setReceiptProducts] = useState([]);
	const [purchaseOrderForm] = Form.useForm();
	const [requiredMark, setRequiredMarkType] = useState('required');
	const [listUser, setListUser] = useState([
		{ id: 1, name: 'teszt1' },
		{ id: 2, name: 'teszt2' },
	]);
	const [listVendor, setListVendor] = useState([]);
	const [listWarehouse, setListWarehouse] = useState([]);
	const [loading, setLoading] = useState({
		save: false,
		fullReceipt: false,
		fullReceiptReset: false,
		partialReceipt: false,
		partialReceiptReset: false,
		closeReceipt: false,
	});
	const [modalVisible, setModalVisible] = useState(false);
	const [orderLoader, setOrderLoader] = useState(false);

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		props.setLoading(true);
	}, []);

	useEffect(() => {
		setPageDetails(props.store.system.pageDetails ? props.store.system.pageDetails.find((page) => page.path === pagePath) : {});
	}, [props.store.system.pageDetails]);

	useEffect(() => {
		getPurchaseOrder();
	}, [params.id]);

	useEffect(() => {
		getReceiptProducts();
	}, [purchaseOrder]);

	useEffect(() => {
		server
			.get('/list/user/include')
			.then((response) => {
				let filteredUser = response.data.filter((item) => item.userRole.key === 'sales' || item.userRole.key === 'root');
				setListUser(filteredUser);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/vendor')
			.then((response) => {
				setListVendor(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	useEffect(() => {
		server
			.get('/list/warehouse')
			.then((response) => {
				let filteredWarehouse = response.data.filter((item) => item.wrhType === 1);
				setListWarehouse(filteredWarehouse);
			})
			.catch((error) => {
				errorHandler(error);
			});
	}, []);

	const getPurchaseOrder = () => {
		setOrderLoader(true);
		server
			.get('/purchase-order/' + params.id)
			.then((response) => {
				setPurchaseOrder({ ...response.data, prchordNo: zeroFill(6, response.data.id) });
				props.setLoading(false);
				setOrderLoader(false);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const getReceiptProducts = () => {
		server
			.get('/product/receipt/' + params.id)
			.then((response) => {
				setReceiptProducts(response.data);
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const handleSubmitButtonClick = () => {
		purchaseOrderForm.submit();
	};

	const handleOrderFormSubmit = (values) => {
		setLoading({ ...loading, save: true });
		server
			.patch('/purchase-order/' + params.id, values)
			.then((response) => {
				setLoading({ ...loading, save: false });
				getPurchaseOrder();
				notification('success', 'Megrendelés sikeresen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleDeleteOrder = () => {
		confirm({
			title: 'Biztos, hogy törölni szeretné a megrendelést?',
			icon: <ExclamationCircleOutlined />,
			content: purchaseOrder.prchordNo,
			okText: 'Törlés',
			cancelText: 'Mégse',
			okButtonProps: { danger: true },
			cancelButtonProps: { danger: true },
			onOk() {
				server
					.delete('/purchase-order/' + params.id)
					.then((response) => {
						notification('success', 'Megrendelés sikeresen törölve', purchaseOrder.prchordNo + ' - ' + purchaseOrder.vendor.companyName);
						history.push('/purchaseorders');
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleFullReceiptButtonClick = () => {
		confirm({
			title: 'Biztos, hogy a teljes megrendelést be szeretné érkeztetni?',
			icon: <ExclamationCircleOutlined />,
			content: 'A beérkeztetés helye: ' + listWarehouse.find((item) => item.id === purchaseOrder.warehouseId).wrhName,
			okText: 'Igen',
			cancelText: 'Mégse',
			okButtonProps: { danger: false },
			cancelButtonProps: { danger: false },
			onOk() {
				server
					.get('/purchase-order-line/related/' + params.id)
					.then((response) => {
						handleReceipt(response, 1);
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleFullReceiptResetButtonClick = () => {
		confirm({
			title: 'Biztos, vissza szeretné vonni?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Igen',
			cancelText: 'Mégse',
			okButtonProps: { danger: false },
			cancelButtonProps: { danger: false },
			onOk() {
				server
					.get('/receipt/purchase-order/' + params.id)
					.then((response) => {
						handleReceiptReset(response.data[0].id, 1);
					})
					.catch((error) => {
						errorHandler(error);
					});
			},
			onCancel() {},
		});
	};

	const handleCloseReceiptButtonClick = () => {
		setLoading({ ...loading, closeReceipt: true });
		server
			.patch('/purchase-order/' + params.id, { purchaseOrderStatusId: 4 }) // ID
			.then((response) => {
				setLoading({ ...loading, closeReceipt: false });
				getPurchaseOrder();
				notification('success', 'Megrendelés sikeresen lezárva');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handleReceipt = (response, type) => {
		console.log(response);
		setLoading({ ...loading, fullReceipt: type === 1 ? true : false, partialReceipt: type === 2 ? true : false });
		server
			.post('/worker/purchase-order/process', { response, type, purchaseOrderId: purchaseOrder.id, user: props.store.auth.user })
			.then((response) => {
				setLoading({ ...loading, fullReceipt: false, partialReceipt: false });
				getPurchaseOrder();
				notification('success', 'Árucikkek sikeresen beérkeztetve');
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, fullReceipt: false, partialReceipt: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleReceiptReset = (id, type) => {
		setLoading({ ...loading, fullReceiptReset: type === 1 ? true : false, partialReceiptReset: type === 2 ? true : false });
		server
			.post('/worker/purchase-order/reset', { receiptId: id, purchaseOrderId: purchaseOrder.id })
			.then((response) => {
				setLoading({ ...loading, fullReceipt: false, partialReceipt: false });
				getPurchaseOrder();
				notification('success', 'Árubeérkezés sikeresen visszavonva');
			})
			.catch((error) => {
				errorHandler(error);
				setLoading({ ...loading, fullReceipt: false, partialReceipt: false });
				notification('warning', 'Hiba! Próbálja újra!');
			});
	};

	const handleFileUploadChange = (info) => {
		if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (info.file.status === 'done') {
			notification('success', 'Fájl sikeresen feltöltve');
			getPurchaseOrder();
		} else if (info.file.status === 'error') {
			notification('error', 'Feltöltési hiba');
			getPurchaseOrder();
		}
	};

	const handleInvoiceDownloadButtonClick = () => {
		window.open(process.env.REACT_APP_API_URL + '/files/purchase-orders/' + purchaseOrder.prchordFile);
	};

	const handleInvoiceDeleteButtonClick = () => {
		server
			.patch('/purchase-order/' + params.id, { prchordFile: null })
			.then((response) => {
				getPurchaseOrder();
				notification('success', 'Dokumentum sikeresen törölve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handlePaymentDeadlineChange = (date) => {
		server
			.patch('/purchase-order/' + params.id, { prchordPaymentDeadline: date }) // ID
			.then((response) => {
				getPurchaseOrder();
				notification('success', 'Dátum sikersen frissítve');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	const handlePurchaseOrderPrint = () => {
		const url = '/worker/purchase-order/print';
		setLoading({ ...loading, purchaseOrderPrint: true });
		server
			.post(url, { purchaseOrderId: purchaseOrder.id })
			.then((response) => {
				setLoading({ ...loading, purchaseOrderPrint: false });
				window.open(process.env.REACT_APP_API_URL + '/files/purchase-orders/print/beszerzes_' + zeroFill(6, purchaseOrder.id) + '.pdf');
			})
			.catch((error) => {
				errorHandler(error);
			});
	};

	return (
		<PageGuard path={pagePath} data={purchaseOrder}>
			<div className={styles.PurchaseOrder}>
				<NewPartialReceiptModal
					visible={modalVisible}
					toggle={() => setModalVisible(!modalVisible)}
					store={props.store}
					purchaseOrder={purchaseOrder}
					getPurchaseOrder={getPurchaseOrder}
					handleReceipt={handleReceipt}
					handleReceiptReset={handleReceiptReset}
				/>
				<OrderLoader loading={orderLoader} />
				<PageHeader
					className={styles.pageHeader}
					onBack={() => window.history.back()}
					title={purchaseOrder.prchordNo}
					tags={<Tag color='blue'>{purchaseOrder.purchaseOrderStatus ? purchaseOrder.purchaseOrderStatus.name : ''}</Tag>}
					subTitle={pageDetails.subtitle}
					extra={
						props.store.auth.user.userRole.accessLevel < 25
							? [
									purchaseOrder.purchaseOrderStatusId != 4 ? ( // ID
										<Button key='button7' type='default' onClick={handleCloseReceiptButtonClick} loading={loading.closeReceipt}>
											Archiválás
										</Button>
									) : null,
									purchaseOrder.prchordReceiptType === 1 ? (
										<Button key='button6' type='default' onClick={handleFullReceiptResetButtonClick} loading={loading.fullReceiptReset}>
											Teljes árubeérkeztetés visszavonása
										</Button>
									) : null,
									purchaseOrder.prchordReceiptType === null ? (
										<Button key='button5' type='default' onClick={handleFullReceiptButtonClick} loading={loading.fullReceipt} disabled={purchaseOrder.warehouseId === null}>
											Teljes árubeérkeztetés
										</Button>
									) : null,
									purchaseOrder.prchordReceiptType === null ? (
										<Button key='button4' type='default' onClick={() => setModalVisible(true)} loading={loading.partialReceipt} disabled={purchaseOrder.warehouseId === null}>
											Részleges árubeérkeztetés
										</Button>
									) : null,
									purchaseOrder.prchordReceiptType === 2 ? (
										<Button key='button4' type='default' onClick={() => setModalVisible(true)} loading={loading.partialReceiptReset}>
											Részleges árubeérkeztetések
										</Button>
									) : null,
									<Button key='button61' type='primary' onClick={handlePurchaseOrderPrint} loading={loading.purchaseOrderPrint}>
										Megrendelőlap
									</Button>,
									<Button key='button2' type='primary' onClick={handleDeleteOrder} danger disabled={purchaseOrder.products.length > 0}>
										Törlés
									</Button>,
									<Button
										key='button1'
										type='primary'
										onClick={handleSubmitButtonClick}
										loading={loading.save}
										disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100}>
										Mentés
									</Button>,
							  ]
							: []
					}
				/>
				<div className={styles.pageBody} key={purchaseOrder.id}>
					<Form
						key={purchaseOrder.id}
						form={purchaseOrderForm}
						layout='vertical'
						initialValues={{
							requiredMark,
							/* ...purchaseOrder, */
						}}
						onValuesChange={onRequiredTypeChange}
						requiredMark={requiredMark}
						onFinish={handleOrderFormSubmit}>
						<Row gutter={[16, 16]}>
							<Col xs={24} lg={12}>
								<Card style={{ height: '100%' }}>
									<Row justify='space-between' gutter={16}>
										<Col xs={24} lg={4}>
											<Form.Item
												label='Azonosító'
												name=''
												initialValue={purchaseOrder.prchordNo}
												rules={[
													{
														required: true,
														message: 'Kötelező mező',
													},
												]}>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col xs={24} lg={20}>
											<Form.Item label='Beszállító' name='vendorId'>
												<Select
													disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100}
													showSearch
													defaultValue={purchaseOrder.vendorId}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listVendor.map((vendor) => {
														return (
															<Option key={vendor.id} value={vendor.id}>
																{vendor.companyName}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={7}>
											<Form.Item label='Megrendelés dátuma' name='prchordDate'>
												<DatePicker
													disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100}
													locale={locale}
													defaultValue={typeof purchaseOrder.prchordDate === 'undefined' ? moment() : moment(purchaseOrder.prchordDate, 'YYYY/MM/DD - HH:mm')}
													style={{ width: '100%' }}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={7}>
											<Form.Item label='Szállítási dátum' name='prchordShipDate'>
												<DatePicker
													disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100}
													locale={locale}
													defaultValue={typeof purchaseOrder.prchordShipDate === 'undefined' ? moment() : moment(purchaseOrder.prchordShipDate)}
													style={{ width: '100%' }}
												/>
											</Form.Item>
										</Col>
										<Col xs={24} lg={10}>
											<Form.Item label='Raktár' name='warehouseId' rules={[{ required: true, message: 'Kötelező mező' }]} initialValue={purchaseOrder.warehouseId}>
												<Select
													disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100}
													showSearch
													defaultValue={purchaseOrder.warehouseId}
													optionFilterProp='value'
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
													{listWarehouse.map((warehouse) => {
														return (
															<Option key={warehouse.id} value={warehouse.id}>
																{warehouse.wrhName}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>
										<Col xs={24} lg={15}>
											<Form.Item label='Beszállítói számla'>
												{purchaseOrder.prchordFile ? (
													<>
														<Button icon={<DownloadOutlined />} onClick={handleInvoiceDownloadButtonClick}>
															Beszállítói számla letöltése
														</Button>
														<Button onClick={handleInvoiceDeleteButtonClick} style={{ marginLeft: '16px' }}>
															Törlés
														</Button>
													</>
												) : (
													<Upload
														name='file'
														action={process.env.REACT_APP_API_URL + '/api/upload/purchaseOrder/' + params.id}
														headers={{ Authorization: 'Bearer ' + localStorage.getItem('token') }}
														onChange={handleFileUploadChange}>
														<Button icon={<UploadOutlined />}>Beszállítói számla feltöltése</Button>
													</Upload>
												)}
											</Form.Item>
										</Col>
										<Col xs={24} lg={9}>
											<Form.Item label='Fizetési határidő' name='prchordPaymentDeadline'>
												<DatePicker
													locale={locale}
													defaultValue={
														purchaseOrder.prchordPaymentDeadline ? moment(purchaseOrder.prchordPaymentDeadline, 'YYYY/MM/DD - HH:mm') : moment(new Date(), 'YYYY/MM/DD - HH:mm')
													}
													style={{ width: '100%' }}
													onChange={(date, dateString) => handlePaymentDeadlineChange(date)}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} lg={4}>
								<Card style={{ height: '100%' }}>
									<Form.Item label='Megjegyzés' name='prchordDescription' initialValue={purchaseOrder.prchordDescription}>
										<TextArea rows={6} disabled={purchaseOrder.purchaseOrderStatus && purchaseOrder.purchaseOrderStatus.key === 100} />
									</Form.Item>
								</Card>
							</Col>
							<Col xs={24} lg={8}>
								<Card className={styles.vendorCard}>
									<Title level={3} className={styles.vendorText}>
										{purchaseOrder.vendor.companyName}
									</Title>
									<Title level={5} className={styles.vendorText + ' mt-0'}>
										{purchaseOrder.vendor.billingAddress}
									</Title>
									<Title level={5} className={styles.vendorText + ' mt-0'}>
										{purchaseOrder.vendor.billingPostalCode} - {purchaseOrder.vendor.billingCity}
									</Title>
									<Text strong className={styles.vendorText}>
										Adószám:{' '}
									</Text>
									<Text className={styles.vendorText}>
										{purchaseOrder.vendor.taxID} {purchaseOrder.vendor.vatID ? ` (${purchaseOrder.vendor.vatID})` : null}
									</Text>
									<Paragraph className={styles.vendorText + ' mb-0'}>{purchaseOrder.vendor.phone}</Paragraph>
									<Paragraph className={styles.vendorText + ' mb-0'}>{purchaseOrder.vendor.email}</Paragraph>
								</Card>
							</Col>
						</Row>
					</Form>
					<Row>
						<PurchaseOrderLines purchaseOrder={purchaseOrder} getPurchaseOrder={getPurchaseOrder} />
					</Row>
					<Row style={{ marginTop: '16px' }}>
						<PurchaseOrderTotal purchaseOrder={purchaseOrder} getPurchaseOrder={getPurchaseOrder} />
					</Row>
					{purchaseOrder.prchordReceiptType !== null ? (
						<Row style={{ marginTop: '16px' }}>
							<Receipts receiptProducts={receiptProducts} />
						</Row>
					) : null}
				</div>
			</div>
		</PageGuard>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLoading: (params) => {
			dispatch(actionCreators.set_loading(params));
		},
	};
};

export default connect(null, mapDispatchToProps)(hoc(PurchaseOrder, pagePath));
